import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import TicketInformation from "./TicketInformation";
import TimeLimit from "./TimeLimit";
import { TicketProps } from "../../types/ticket.type";

const DetailTicket = (props: { myTicket?: TicketProps }) => {
  const slaLevel = props.myTicket?.tasks?.find((item) => item?.jSLA?.level)
    ?.jSLA?.level;
  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBlock: 20,
              gap: 10,
            }}
          >
            <div>
              <ClockCircleOutlined style={{ fontSize: "24px" }} />
            </div>
            <div>
              <TitleBold>Time Limit</TitleBold>
            </div>
          </div>
          <TimeLimit
            responseTimeLimit={props.myTicket?.dLimitResponse}
            resolveTimeLimit={props.myTicket?.dLimitResolve}
          />
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBlock: 20,
              gap: 10,
            }}
          >
            <div>
              <InfoCircleOutlined style={{ fontSize: "24px" }} />
            </div>
            <div>
              <TitleBold>Ticket Information</TitleBold>
            </div>
          </div>
          <TicketInformation
            ticketId={props.myTicket?.ipTicket}
            createdBy={props.myTicket?.iUserOwner}
            createdByUser={props.myTicket?.userOwner.vFullName}
            slaLevel={
              props.myTicket?.jSLA?.level ||
              props.myTicket?.tasks?.[0]?.jSLA?.level ||
              slaLevel
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailTicket;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
