import { Divider, Spin } from "antd";
import { BsShieldExclamation } from "react-icons/bs";
import styled from "styled-components";
import { apiGetPrivacyPolicy } from "../../api/appConfig";
import { useEffect, useState } from "react";
import StringToMarkup from "./StringToMarkup";
import "./More.css";

const PrivacyPolicy = () => {
  const [value, setValue] = useState("");
  const fetchValue = async () => {
    const res = await apiGetPrivacyPolicy();
    setValue(res);
  };

  useEffect(() => {
    fetchValue();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <BsShieldExclamation style={{ fontSize: 24 }} />
        <TitleBold>Privacy Policy</TitleBold>
      </div>
      <Divider />
      {value ? <StringToMarkup text={value} /> : <Spin />}
    </div>
  );
};

export default PrivacyPolicy;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: justify;
  line-height: 2;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 15px;
`;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
