import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Select, message } from "antd";
import styled from "styled-components";
import MyTaskCard from "../../components/MyTask/MyTaskCard";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  ReloadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { theme } from "../../assets/theme";
import { Circle } from "react-feather";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import useFetchList from "../../hooks/useFetchList";
import { useAuthUser } from "react-auth-kit";
import { TaskProps } from "../../types/task.type";
import { useNavigate } from "react-router-dom";
import HeaderTitleWithBadge from "../../components/MyTask/HeaderTitleWithBadge";
import { apiGetResolveTimeLimit } from "../../api/ticket";
import { isDoneStatus } from "../../helpers/isDoneStatus";
import { apiSyncMyTask } from "../../api/task";
import { apiSyncMyOrganization } from "../../api/organization";
import useExecutionTime from "../../hooks/useExecutionTime";
import { Timer } from "timer-node";
import { useIntersection } from "../../hooks/useIntersection";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpRequest } from "../../helpers/api";

const MyTask = () => {
  const triggerRef = useRef(null);
  const isVisible = useIntersection(triggerRef, "0px");
  const navigate = useNavigate();
  const auth = useAuthUser();
  const timer = new Timer();
  timer.start();
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  const org = urlParams.get("organization");
  const [taskStatus, setTaskStatus] = useState<number | null>(
    status ? parseInt(status) : null
  );
  const { stopTimer } = useExecutionTime();
  const [statusQuery, setStatusQuery] = useState<number | null>(null);
  const [totalData, setTotalData] = React.useState<number>(0);
  const [indexEnd, setIndexEnd] = React.useState<number>(0);
  const [once, setOnce] = useState<boolean>(true);
  const [orgQuery, setOrgQuery] = useState<number | null>(null);
  const [slaLevel, setSlaLevel] = useState([
    "CRITICAL",
    "HIGH",
    "NORMAL",
    "LOW",
  ]);
  const [sortedBy, setSortedBy] = useState(6);
  const [iOrganization, setIOrganization] = useState<number | null>(
    org === "0" ? 0 : org ? parseInt(org) : null
  );
  const [resolveTimeLimit, setResolveTimeLimit] = useState<{
    [key: number]: Date | string | undefined;
  }>({});

  const [myTasks, setMyTasks] = useState<TaskProps[]>([]);
  const [filteredDisplay, setFilteredDisplay] = useState<{
    waitingApproval: TaskProps[];
    inProgress: TaskProps[];
    missedSchedule: TaskProps[];
  }>({
    waitingApproval: [],
    inProgress: [],
    missedSchedule: [],
  });
  const [displayTasks, setDisplayTasks] = useState<TaskProps[]>([]);
  const [indexDisplay, setIndexDisplay] = useState<number>(0);
  const [taskCount, setTaskCount] = useState<{
    needApproval: number;
    inProgress: number;
    inReview: number;
    returned: number;
    done: number;
    missedSchedule: number;
  }>({
    needApproval: 0,
    inProgress: 0,
    inReview: 0,
    returned: 0,
    done: 0,
    missedSchedule: 0,
  });

  const { data: userOrganizations, fetchList: fetchOrganization } =
    useFetchList<OrganizationUserProps>({
      endpoint: "organization-users",
      pageData: "my-organization-users",
      iUser: auth()?.iUser,
      initialQuery: {
        iUser: auth()?.iUser,
      },
    });

  const {
    fetchList: fetchTask,
    data: myTasksData,
    setQuery: taskQuery,
  } = useFetchList<TaskProps>({
    endpoint: "tasks/custom/optimize/my-tasks",
    pageData: "my-tasks",
    initialQuery: {
      iResult: taskStatus,
      jSLA: slaLevel,
      sortBy: sortedBy,
      iOrganization: iOrganization,
    },
  });

  const checkParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    const org = urlParams.get("organization");
    setStatusQuery(parseInt(status ? status : ""));
    setOrgQuery(org ? parseInt(org) : null);
    if (status) {
      setTaskStatus(parseInt(status));
    }
    if (org) {
      setIOrganization(parseInt(org));
    }
  };
  const renderMore = () => {
    setIndexEnd(indexEnd + 6);
  };

  const fetchTaskCount = async () => {
    const res = await httpRequest.get<{
      payload: {
        count: {
          iResult: number;
          count: string;
        }[];
        results: TaskProps[];
      };
    }>(`tasks/custom/optimize/my-tasks`);
    if (res) {
      const counts = res.data.payload.count;

      const needApproval =
        res.data.payload.results.filter(
          (item: TaskProps) => !item.isMissSchedule && item.iResult === 1
        )?.length || 0;
      const inProgress =
        res.data.payload.results.filter(
          (item: TaskProps) => !item.isMissSchedule && item.iResult === 2
        )?.length || 0;
      const inReview =
        counts
          .filter(
            (item) =>
              item.iResult === 621 ||
              item.iResult === 622 ||
              item.iResult === 69
          )
          .reduce((acc, item) => acc + parseInt(item.count), 0) || 0;
      const returned =
        counts
          .filter((item) => item.iResult === 7)
          .reduce((acc, item) => acc + parseInt(item.count), 0) || 0;
      const done =
        counts
          .filter((item) => item.iResult === 9)
          .reduce((acc, item) => acc + parseInt(item.count), 0) || 0;
      const missedSchedule =
        res.data.payload.results.filter(
          (item: TaskProps) =>
            !isDoneStatus(item.iResult) && item.isMissSchedule
        )?.length || 0;
      setTotalData(
        needApproval + inProgress + inReview + returned + done + missedSchedule
      );
      const taskCount = {
        needApproval,
        inProgress,
        inReview,
        returned,
        done,
        missedSchedule,
      };
      setTaskCount(taskCount);
    }
  };

  const fetchFilteredTask = async () => {
    const res = await httpRequest.get(
      `tasks/custom/status/my-tasks?offset=${indexEnd}&limit=6&sortBy=6`
    );
    if (res) {
      setFilteredDisplay({
        waitingApproval: filteredDisplay.waitingApproval.concat(
          res.data.payload.results.waitingApproval
        ),
        inProgress: filteredDisplay.inProgress.concat(
          res.data.payload.results.inProggres
        ),
        missedSchedule: filteredDisplay.missedSchedule.concat(
          res.data.payload.results.missSchedule.filter(
            (item: TaskProps) => !isDoneStatus(item.iResult)
          )
        ),
      });
    }
  };
  useEffect(() => {
    fetchFilteredTask();
  }, [indexEnd]);

  useEffect(() => {
    fetchFilteredTask();
  }, []);

  useEffect(() => {
    setIndexDisplay(
      filteredDisplay.waitingApproval
        .concat(filteredDisplay.inProgress)
        .concat(filteredDisplay.missedSchedule).length
    );
  }, [filteredDisplay]);

  useEffect(() => {
    if (isVisible) {
      renderMore();
    }
  }, [isVisible, renderMore, triggerRef]);
  useEffect(() => {
    let oldData = displayTasks;
    let newData = myTasksData;
    let result = oldData.concat(newData);
    setDisplayTasks(result);
  }, [myTasksData]);
  useEffect(() => {}, []);

  const fetchResolveTimeLimit = async () => {
    const res = await apiGetResolveTimeLimit();
    if (res) {
      setResolveTimeLimit(res);
    }
  };
  useEffect(() => {
    if (myTasksData.length !== 0 && userOrganizations.length !== 0 && once) {
      stopTimer();
      setOnce(false);
    }
  }, [myTasksData, userOrganizations]);

  useEffect(() => {
    fetchTaskCount();
    fetchOrganization();
    fetchTask();
  }, []);

  useEffect(() => {
    if (statusQuery === null || orgQuery === null) {
      checkParams();
    }
    taskQuery((oldval) => ({
      ...oldval,
      limit: totalData,
      // offset: totalData,
      iResult: taskStatus,
      jSLA: slaLevel,
      sortBy: sortedBy,
    }));
    fetchTaskCount();
    fetchTask();
    fetchOrganization();
    // eslint-disable-next-line
  }, [
    slaLevel,
    sortedBy,
    taskStatus,
    iOrganization,
    statusQuery,
    orgQuery,
    indexEnd,
    totalData,
  ]);

  useEffect(() => {
    checkParams();
    fetchResolveTimeLimit();
  }, []);
  useEffect(() => {
    setFilteredDisplay({
      inProgress: sortingFilter(filteredDisplay.inProgress, sortedBy),
      missedSchedule: sortingFilter(filteredDisplay.missedSchedule, sortedBy),
      waitingApproval: sortingFilter(filteredDisplay.waitingApproval, sortedBy),
    });
    setMyTasks(sortingFilter(myTasks, sortedBy));
  }, [sortedBy]);
  useEffect(() => {
    setFilteredDisplay({
      inProgress: organizationFilter(
        filteredDisplay.inProgress,
        iOrganization || 0
      ),
      missedSchedule: organizationFilter(
        filteredDisplay.missedSchedule,
        iOrganization || 0
      ),
      waitingApproval: organizationFilter(
        filteredDisplay.waitingApproval,
        iOrganization || 0
      ),
    });
    setMyTasks(organizationFilter(myTasks, iOrganization || 0));
  }, [iOrganization]);
  useEffect(() => {
    setFilteredDisplay({
      inProgress: slaFilter(filteredDisplay.inProgress, slaLevel),
      missedSchedule: slaFilter(filteredDisplay.missedSchedule, slaLevel),
      waitingApproval: slaFilter(filteredDisplay.waitingApproval, slaLevel),
    });
    setMyTasks(slaFilter(myTasks, slaLevel));
  }, [slaLevel]);

  const organizationFilter = (data: TaskProps[], iOrganization: number) => {
    if (iOrganization === 0 || iOrganization === null) {
      return data.filter(
        (item) => !item.iOrganizationTarget || item.iOrganizationTarget === 0
      );
    } else {
      return data.filter((item) => item.iOrganizationTarget === iOrganization);
    }
  };

  const slaFilter = (data: TaskProps[], slaLevel: string[]) => {
    return data.filter((item) =>
      slaLevel.includes(item.jSLA?.level || "NORMAL")
    );
  };

  const sortingFilter = (data: TaskProps[], sortBy: number) => {
    switch (sortBy) {
      case 1:
        return data.sort((a, b) => {
          const dateA = new Date(
            a.dLimitResolve || resolveTimeLimit[a.iTicket]!
          ).getTime();
          const dateB = new Date(
            b.dLimitResolve || resolveTimeLimit[b.iTicket]!
          ).getTime();
          return dateA - dateB;
        });
      case 2:
        return data.sort((a, b) => {
          const dateA = new Date(
            a.dLimitResolve || resolveTimeLimit[a.iTicket]!
          ).getTime();
          const dateB = new Date(
            b.dLimitResolve || resolveTimeLimit[b.iTicket]!
          ).getTime();
          return dateB - dateA;
        });
      case 3:
        return data.sort((a, b) => {
          const dateA = new Date(a.dCreatedServer).getTime();
          const dateB = new Date(b.dCreatedServer).getTime();
          return dateA - dateB;
        });
      case 4:
        return data.sort((a, b) => {
          const dateA = new Date(a.dCreatedServer).getTime();
          const dateB = new Date(b.dCreatedServer).getTime();
          return dateB - dateA;
        });
      case 5:
        return data.sort((a, b) => {
          const dateA = new Date(a.dUpdatedServer).getTime();
          const dateB = new Date(b.dUpdatedServer).getTime();
          return dateA - dateB;
        });
      case 6:
        return data.sort((a, b) => {
          const dateA = new Date(a.dUpdatedServer).getTime();
          const dateB = new Date(b.dUpdatedServer).getTime();
          return dateB - dateA;
        });
      default:
        return data;
    }
  };

  useEffect(() => {
    if (iOrganization === 0) {
      setMyTasks(displayTasks.filter((item) => !item.iOrganizationTarget));
    } else if (iOrganization === null) {
      setMyTasks(myTasksData);
    } else {
      setMyTasks(
        displayTasks.filter(
          (item) => item.iOrganizationTarget === iOrganization
        )
      );
    }
    if (sortedBy === 1) {
      setMyTasks(
        displayTasks.sort((a, b) => {
          const dateA = new Date(
            a.dLimitResolve || resolveTimeLimit[a.iTicket]!
          ).getTime();
          const dateB = new Date(
            b.dLimitResolve || resolveTimeLimit[b.iTicket]!
          ).getTime();
          return dateA - dateB;
        })
      );
    } else if (sortedBy === 2) {
      setMyTasks(
        displayTasks.sort((a, b) => {
          const dateA = new Date(
            a.dLimitResolve || resolveTimeLimit[a.iTicket]!
          ).getTime();
          const dateB = new Date(
            b.dLimitResolve || resolveTimeLimit[b.iTicket]!
          ).getTime();
          return dateB - dateA;
        })
      );
    }
  }, [
    myTasksData,
    iOrganization,
    sortedBy,
    resolveTimeLimit,
    userOrganizations,
    displayTasks,
  ]);
  return (
    <React.Fragment>
      <div style={{ paddingInline: 20 }}>
        <Row style={{ gap: 20, marginBlock: 40 }}>
          <Col span={4}>
            <Text style={{ color: "gray" }}>Task Status</Text>
            <Select
              style={{ marginTop: 10 }}
              defaultValue={taskStatus}
              allowClear
              placeholder="Status"
              onChange={(val) => {
                setTaskStatus(val);
              }}
            >
              <Select.Option value={null}>All</Select.Option>
              <Select.Option value={1}>Need Approval</Select.Option>
              <Select.Option value={2}>In Progress</Select.Option>
              <Select.Option value={6}>In Review</Select.Option>
              <Select.Option value={7}>Returned</Select.Option>
              <Select.Option value={9}>Done</Select.Option>
              <Select.Option value={10}>Missed Schedule</Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <Text style={{ color: "gray" }}>SLA level</Text>
            <Select
              mode="tags"
              style={{ marginTop: 10 }}
              defaultValue={slaLevel}
              allowClear
              placeholder="Status"
              onChange={(value) =>
                value.length > 0
                  ? setSlaLevel(value)
                  : setSlaLevel(["CRITICAL", "HIGH", "NORMAL", "LOW"])
              }
            >
              <Select.Option value="CRITICAL">
                <WarningOutlined style={{ color: "red", marginRight: 8 }} />
                Critical
              </Select.Option>
              <Select.Option value="HIGH">
                <ExclamationCircleOutlined
                  style={{ color: theme.colors.warning, marginRight: 8 }}
                />
                High
              </Select.Option>
              <Select.Option value="NORMAL">
                <Circle
                  color={theme.colors.warning}
                  fill={theme.colors.warning}
                  size={10}
                  style={{ marginRight: 8 }}
                />
                Normal
              </Select.Option>
              <Select.Option value="LOW">
                <Circle
                  color={theme.colors.green500}
                  fill={theme.colors.green500}
                  size={10}
                  style={{ marginRight: 8 }}
                />
                Low
              </Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <Text style={{ color: "gray" }}>Organization</Text>
            <Select
              style={{ marginTop: 10 }}
              defaultValue={iOrganization}
              allowClear
              placeholder="Status"
              onChange={(e) =>
                e === null ? setIOrganization(null) : setIOrganization(e)
              }
            >
              <Select.Option value={null}>All</Select.Option>
              <Select.Option value={0}>Personal</Select.Option>
              {userOrganizations.map((item) => (
                <Select.Option value={item.organization.iOrganization}>
                  {item.organization.vName}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Text style={{ color: "gray" }}>Sorted by</Text>
            <Select
              style={{ marginTop: 10 }}
              defaultValue={sortedBy}
              allowClear
              placeholder="Sorted by"
              onChange={(val) => setSortedBy(val)}
            >
              <Select.Option value={1}>{"Due Date (earliest)"}</Select.Option>
              <Select.Option value={2}>{"Due Date (latest)"}</Select.Option>
              <Select.Option value={3}>{"Created At (earliest)"}</Select.Option>
              <Select.Option value={4}>{"Created At (latest)"}</Select.Option>
              <Select.Option value={5}>
                {"Last Modified (earliest)"}
              </Select.Option>
              <Select.Option value={6}>
                {"Last Modified (latest)"}
              </Select.Option>
            </Select>
          </Col>
        </Row>
        {taskStatus === 9 ? (
          <div>
            <HeaderTitleWithBadge title="Done" badgeCount={taskCount.done} />
            <Row style={{ gap: 20 }}>
              {myTasks
                .filter((item) => isDoneStatus(item.iResult))
                .map((item) => (
                  <Col span={7}>
                    <MyTaskCard
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : taskStatus === 1 ? (
          <div>
            <HeaderTitleWithBadge
              title="Need Approval"
              badgeCount={taskCount.needApproval}
            />
            <Row style={{ gap: 20 }}>
              {myTasks
                .filter((item) => item.iResult === 1 && !item.isMissSchedule)
                .map((item) => (
                  <Col span={7}>
                    <MyTaskCard
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.metaUser?.vNameUserCreate}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : taskStatus === 2 ? (
          <div>
            <HeaderTitleWithBadge
              title="In Progress"
              badgeCount={taskCount.inProgress}
            />
            <Row style={{ gap: 20 }}>
              {myTasks
                .filter((item) => item.iResult === 2 && !item.isMissSchedule)
                .map((item) => (
                  <Col span={7}>
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : taskStatus === 6 ? (
          <div>
            <HeaderTitleWithBadge
              title="In Review"
              badgeCount={taskCount.inReview}
            />
            <Row style={{ gap: 20 }}>
              {myTasks
                .filter(
                  (item) =>
                    item.iResult === 621 ||
                    item.iResult === 622 ||
                    item.iResult === 69
                )
                .map((item) => (
                  <Col span={7}>
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : taskStatus === 7 ? (
          <div>
            <HeaderTitleWithBadge
              title="Returned"
              badgeCount={taskCount.returned}
            />
            <Row style={{ gap: 20 }}>
              {myTasks
                .filter((item) => item.iResult === 7)
                .map((item) => (
                  <Col span={7}>
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : taskStatus === 10 ? (
          <div>
            <HeaderTitleWithBadge
              title="Missed Schedule"
              badgeCount={taskCount.missedSchedule}
            />
            <Row style={{ gap: 20 }}>
              {myTasks
                .filter(
                  (item) =>
                    (item.iResult === 10 || item.isMissSchedule) &&
                    !isDoneStatus(item.iResult)
                )
                .map((item) => (
                  <Col span={7}>
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={indexEnd}
            next={renderMore}
            hasMore={
              indexEnd < totalData &&
              (taskCount.needApproval > 0 ||
                taskCount.inProgress > 0 ||
                taskCount.missedSchedule > 0)
            }
            height={700}
            loader={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                <LoadingOutlined style={{ fontSize: 24, color: "#1890ff" }} />
              </div>
            }
            endMessage={<></>}
          >
            <Row style={{ gap: 20 }}>
              <Col span={7}>
                <HeaderTitleWithBadge
                  title="Need Approval"
                  badgeCount={taskCount.needApproval}
                />
                {filteredDisplay.waitingApproval
                  // .filter((item) => item.iResult === 1 && !item.isMissSchedule)
                  .map((item) => (
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  ))}
              </Col>
              <Col span={7}>
                <HeaderTitleWithBadge
                  title="Missed Schedule"
                  badgeCount={taskCount.missedSchedule}
                />
                {filteredDisplay.missedSchedule
                  // .filter(
                  //   (item) =>
                  //     (item.iResult === 10 || item.isMissSchedule) &&
                  //     !isDoneStatus(item.iResult)
                  // )
                  .map((item) => (
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  ))}
              </Col>
              <Col span={7}>
                <HeaderTitleWithBadge
                  title="In Progress"
                  badgeCount={taskCount.inProgress}
                />
                {filteredDisplay.inProgress
                  // .filter((item) => item.iResult === 2 && !item.isMissSchedule)
                  .map((item) => (
                    <MyTaskCard
                      organizationName={
                        item.metaUser?.vNameOrganizationTarget?.vName
                          ? item.metaUser?.vNameOrganizationTarget?.vName
                          : null
                      }
                      iOrganization={item.iOrganizationTarget}
                      status={item.iResult}
                      slaLevel={item.jSLA?.level}
                      taskName={item.vName}
                      dueDate={
                        item.dLimitResolve || resolveTimeLimit[item.iTicket]
                      }
                      attachmentTotal={item.jAttachment?.files?.length}
                      userCreatedBy={item.userCreate.vFullName}
                      iUserCreate={item.iUserCreate}
                      onClick={() => {
                        navigate(`/my-task/${item.ipTask}`);
                      }}
                    />
                  ))}
              </Col>
            </Row>
          </InfiniteScroll>
        )}
      </div>
    </React.Fragment>
  );
};

export default MyTask;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
