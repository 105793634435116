import { Button, Modal } from "antd";

type Props = {
  isOpen: boolean;
  title: string;
  onOk: () => void;
  onCancel: () => void;
  message: string;
  danger?: boolean;
};

const ModalConfirm = (props: Props) => {
  return (
    <Modal
      title={props.title}
      open={props.isOpen}
      onCancel={props.onCancel}
      width={350}
      footer={false}
    >
      <div style={{ textAlign: "center" }}>{props.message}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 5,
          marginTop: 10,
        }}
      >
        <Button
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            padding: 20,
            alignItems: "center",
          }}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        {props.danger ? (
          <Button
            type="primary"
            danger
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              color: "white",
              padding: 20,
              alignItems: "center",
            }}
            onClick={props.onOk}
          >
            Yes
          </Button>
        ) : (
          <Button
            type="primary"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              padding: 20,
              alignItems: "center",
            }}
            onClick={props.onOk}
          >
            Yes
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ModalConfirm;
