import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { theme } from "../../assets/theme";
import { Circle } from "react-feather";
import styled from "styled-components";

const SLALevel = (props: { level?: string }) => {
  if (props.level === "CRITICAL") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <WarningOutlined style={{ fontSize: "18px", color: "red" }} />
        <TextBold style={{ color: "red" }}>Critical</TextBold>
      </div>
    );
  } else if (props.level === "HIGH") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <ExclamationCircleOutlined
          style={{ fontSize: "18px", color: theme.colors.warning }}
        />
        <TextBold style={{ color: theme.colors.warning }}>High</TextBold>
      </div>
    );
  } else if (props.level === "NORMAL") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Circle
          fill={theme.colors.warning}
          size={16}
          stroke={theme.colors.warning}
        />
        <TextBold style={{ color: theme.colors.warning }}>Normal</TextBold>
      </div>
    );
  } else if (props.level === "LOW") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Circle
          fill={theme.colors.green500}
          stroke={theme.colors.green500}
          size={16}
        />
        <TextBold style={{ color: theme.colors.green500 }}>Low</TextBold>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Circle fill="grey" stroke="grey" size={16} />
        <TextBold style={{ color: "grey" }}>Not Set</TextBold>
      </div>
    );
  }
};

export default SLALevel;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
