import { Link } from "react-router-dom";
import { Form, Input, Card, Button, Divider } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import AuthLayout from "../layout/AuthLayout";
import AuthHeaderCard, {
  AuthSubTitleCard,
} from "../../components/AuthHeaderCard";
import AppVersion from "../../components/AppVersion";
import useAuthApp from "../../hooks/useAuthApp";
import { useEffect, useState } from "react";
import { ClientJS } from "clientjs";
import Otp from "./Otp";

const Login = () => {
  const { isAuthLoading, doOtpLogin, doLogin, doResendOtp } = useAuthApp();
  const [isUsePhone, setIsUsePhone] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [tempSession, setTempSession] = useState("");
  const [vSession, setVSession] = useState("");
  const [clientFingerprint, setClientFingerprint] = useState("");

  useEffect(() => {
    const client = new ClientJS();
    setClientFingerprint(client.getFingerprint());
  }, [clientFingerprint]);

  const resOtp = async (data: {
    vEmail?: string;
    vPassword?: string;
    vMobile?: string;
    vWebGUID?: string;
  }) => {
    data.vWebGUID = clientFingerprint;
    const res = await doOtpLogin(data);
    if (res && data.vEmail) {
      setEmail(data.vEmail);
      setOtp(res.OTP);
      setShowOtp(true);
      setVSession(res.vSession);
      setTempSession(res.tempSession);
    } else if (res && data.vMobile) {
      setMobile(data.vMobile);
      setOtp(res.OTP);
      setShowOtp(true);
      setVSession(res.vSession);
      setTempSession(res.tempSession);
    }
  };

  const handleResendOtp = async () => {
    const res = await doResendOtp({
      vEmail: email,
      vMobile: mobile,
      tempSession: tempSession,
    });
    if (res) {
      setOtp(res.OTP);
      setVSession(res.vSession);
    }
  };

  const toggleUsePhone = () => {
    setIsUsePhone(!isUsePhone);
  };

  useEffect(() => {
    setEmail("");
    setMobile("");
    setOtp("");
  }, [isUsePhone]);

  return showOtp ? (
    <Otp
      vEmail={email}
      otp={otp}
      vMobile={mobile}
      vSession={vSession}
      onSubmit={doLogin}
      isAuthLoading={isAuthLoading}
      onResendOtp={handleResendOtp}
    />
  ) : (
    <AuthLayout formPosition="left">
      <Card style={{ width: 500 }} bordered={false}>
        <AuthHeaderCard
          title="Hi,\nWelcome Back"
          subtitle={
            "You can continue your personal and management task with your team."
          }
        />
        {isUsePhone ? (
          <Form
            layout="vertical"
            name="basic"
            onFinish={resOtp}
            autoComplete="off"
            initialValues={{
              vMobile: "",
              vEmail: "",
              vPassword: "",
              vMobileGUID: "",
            }}
          >
            <Form.Item name="vMobile" label="Phone Number">
              <Input
                type="phone"
                addonBefore={"+62"}
                placeholder="Enter phone number"
              />
            </Form.Item>

            <Form.Item name="vMobileGUID" hidden />
            <Form.Item name="vEmail" hidden />

            <Form.Item name="vPassword" label="Password">
              <Input.Password
                prefix={<LockOutlined style={{ color: "#A5B2BD" }} />}
                placeholder="Enter your password"
              />
            </Form.Item>

            <Link
              style={{
                display: "block",
                textAlign: "center",
              }}
              to="/forgot-password"
            >
              Forgot Password
            </Link>

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  loading={isAuthLoading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "70%",
                    marginTop: 20,
                    paddingBlock: 20,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Login
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : (
          <Form
            layout="vertical"
            name="basic"
            onFinish={resOtp}
            autoComplete="off"
            initialValues={{
              vEmail: "",
              vPassword: "",
            }}
          >
            <Form.Item name="vEmail" label="Email Address">
              <Input
                type="email"
                prefix={<MailOutlined style={{ color: "#A5B2BD" }} />}
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item name="vPassword" label="Password">
              <Input.Password
                prefix={<LockOutlined style={{ color: "#A5B2BD" }} />}
                placeholder="Enter your password"
              />
            </Form.Item>

            <Link
              style={{
                display: "block",
                textAlign: "center",
              }}
              to="/forgot-password"
            >
              Forgot Password
            </Link>

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  loading={isAuthLoading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "70%",
                    marginTop: 20,
                    paddingBlock: 20,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Login
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        <Divider style={{ color: "grey", fontWeight: "normal" }}>OR</Divider>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            loading={isAuthLoading}
            type="default"
            htmlType="submit"
            style={{
              width: "70%",
              fontWeight: "bold",
              paddingBlock: 20,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={toggleUsePhone}
          >
            {`Login with ${isUsePhone ? "email" : "phone number"}`}
          </Button>
        </div>
        <div
          style={{
            display: "block",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          <AuthSubTitleCard>
            {"Don't have an account? "}
            <Link to="/register">Register</Link>
          </AuthSubTitleCard>
        </div>
        <AppVersion />
      </Card>
    </AuthLayout>
  );
};

export default Login;
