import { Button } from "antd";
import styled from "styled-components";

const DownloadApp = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "white",
        height: "100vh",
        margin: "auto",
        paddingInline: 40,
        gap: 20,
        justifyContent: "center",
      }}
    >
      <img
        src="/logo.png"
        alt="Logo"
        style={{
          width: "50px",
          height: "50px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: "10%",
          marginInline: "auto",
        }}
      />
      <TextBold>Continue in App</TextBold>
      <Text>
        {`${process.env.REACT_APP_WEBSITE_NAME} App more responsive than web. Download ${process.env.REACT_APP_WEBSITE_NAME} App and login for a better experience.`}
      </Text>
      <Button
        style={{
          width: "100%",
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        type="primary"
        href="https://apps.apple.com/id/app/task-management"
      >
        View In App
      </Button>
    </div>
  );
};

export default DownloadApp;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 20px;
`;
