import { message } from "antd";
import { httpRequest } from "../helpers/api";
import { BaseResponseProps, getErrorMessage } from "@qlibs/react-components";
import { OrganizationUserProps } from "../types/organizationUser.type";
import { add } from "date-fns";
import { addOrganizationUsers } from "../hooks/useOfflineData";
import { useAuthUser } from "react-auth-kit";
import UseActionQueue from "../hooks/useActionQueue";


export async function apiGetOrganization(iOrganization: number) {
  try {
    let org = "Personal";
    const res = await httpRequest.get<BaseResponseProps<{ results: any[] }>>(
      process.env.REACT_APP_BASE_URL + "/organizations"
    );
    if (res) {
      // eslint-disable-next-line array-callback-return
      res.data.payload.results.map((item) => {
        if (item.iOrganization === iOrganization) {
          org = item.vName;
          return item.vName;
        }
      });
    }
    return org;
  } catch (err) {
    return "Personal";
  }
}

export async function apiQueryGetOrganization(query: string) {
  try {
    const res = await httpRequest.get<BaseResponseProps<{ results: any[] }>>(
      process.env.REACT_APP_BASE_URL + "/organizations?keyword=" + query
    );
    return res.data.payload.results;
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiGetOrganizationNameByUserId(iUser: number) {
  try {
    let orgName = "";
    const res = await httpRequest.get<
      BaseResponseProps<{ results: OrganizationUserProps[] }>
    >(process.env.REACT_APP_BASE_URL + "/organization-users?iUser=" + iUser);
    if (res) {
      orgName = res.data.payload?.results?.[0]?.organization?.vName;
    }
    return orgName;
  } catch (err) {
    return "";
  }
}

export async function apiSyncMyOrganization(user: number){
  try {
    const res = await httpRequest.get<BaseResponseProps<{ results: OrganizationUserProps[] }>>(
      process.env.REACT_APP_BASE_URL + `/organization-users?iUser=${user}`
    );
    console.log("apiSyncOrg", res);
    if (res) {
      res.data.payload.results.forEach((element: OrganizationUserProps) => {
        addOrganizationUsers(element);
      })
    }
  } catch (err){
    const { pushAction } = UseActionQueue();
    pushAction("GET", "/tasks/custom/my-tasks", {});
  }
}