import { useEffect, useState } from "react";
import { apiGetAttachmentUrl } from "../../api/task";
import PDFViewer from "../../components/Attachment/PDFViewer";

const AttachmentScreen = () => {
  const loc = window.location.href;
  const ipAttachment = loc.split("/").slice(-2, -1)[0];
  const iTask = Number(loc.split("/").slice(-4, -1)[0]);
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");

  const fetchUrl = async () => {
    const res = await apiGetAttachmentUrl({
      iTask: iTask,
      ipAttachment: ipAttachment,
    });
    if (res) {
      setUrl(res);
    }
  };

  useEffect(() => {
    fetchUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iTask, ipAttachment]);

  useEffect(() => {
    setType(url.split(".").pop()?.toLowerCase() || "");
  }, [url]);

  if (!url) {
    return null;
  } else {
    return type === "pdf" ? (
      <PDFViewer url={url} />
    ) : (
      <embed src={url} width="500" />
    );
  }
};

export default AttachmentScreen;
