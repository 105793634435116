import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "../screens/layout/AppLayout";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import MyTaskReturn from "../screens/myTask/Return";
import Login from "../screens/auth/NewLogin";
import Register from "../screens/auth/Register";
import ResetPassword from "../screens/auth/ResetPassword";
import ForgotPassword from "../screens/auth/ForgotPassword";
import NotFound from "../NotFound";
import Dashboard from "../screens/dashboard";
import DashboardDetail from "../screens/dashboard/Detail";
import ChangeMyPassword from "../screens/profile/ChangeMyPassword";
import Notification from "../screens/notification";
import MyTask from "../screens/myTask";
import MyTaskDetail from "../screens/myTask/Detail";
import ForwardTask from "../screens/myTask/forward";
import CreateNewSubTask from "../screens/myTask/Edit";
import ReassignTask from "../screens/myTask/Reassign";
import AttachmentView from "../screens/attachment";
import MyTicket from "../screens/myTicket";
import CreateTicket from "../screens/myTicket/create";
import MyTicketDetail from "../screens/myTicket/Detail";
import AttachTicket from "../screens/myTicket/Attach";
import SubmitTask from "../screens/myTask/Submit";
import Relay from "../screens/relay";
import Contact from "../screens/contact";
import SearchContact from "../screens/contact/Edit";
import SearchContactByOrganization from "../screens/contact/SearchByOrganization";
import EditTicket from "../screens/myTicket/Edit";
import More from "../screens/more/Profile";
import Account from "../screens/more/Account";
import FeedBack from "../screens/more/FeedBack";
import PrivacyPollicy from "../screens/more/PrivacyPollicy";
import TnC from "../screens/more/TnC";
import AboutUss from "../screens/more/AboutUs";
import { isMobile } from "react-device-detect";
import DownloadApp from "../components/DownloadApp";
import PrivacyAndPolicy from "../screens/privacyPolicy/PrivacyAndPolicy";
import TermAndConditions from "../screens/privacyPolicy/TermsAndConditions";
import AboutUs from "../screens/privacyPolicy/AboutUs";

const RootNavigator: React.FC = () => {
  return isMobile ? (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<DownloadApp />} />
        <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
        <Route path="/terms-and-conditions" element={<TermAndConditions />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:sessionId"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />

        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />

        <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
        <Route path="/terms-and-conditions" element={<TermAndConditions />} />
        <Route path="/about-us" element={<AboutUs />} />

        <Route path="*" element={<NotFound />} />
        <Route
          path="my-task/:iTask/attachment/:id/view"
          element={
            <PrivateRoute loginPath="/login">
              <AttachmentView />
            </PrivateRoute>
          }
        />

        <Route element={<AppLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute loginPath="/login">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/:organization"
            element={
              <PrivateRoute loginPath="/login">
                <DashboardDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangeMyPassword />
              </PrivateRoute>
            }
          />

          <Route
            path="/notification"
            element={
              <PrivateRoute loginPath="/login">
                <Notification />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-task"
            element={
              <PrivateRoute loginPath="/login">
                <MyTask />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-task/:iTask"
            element={
              <PrivateRoute loginPath="/login">
                <MyTaskDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-task/:iTask/return"
            element={
              <PrivateRoute loginPath="/login">
                <MyTaskReturn />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-task/forward/:iTask"
            element={
              <PrivateRoute loginPath="/login">
                <ForwardTask />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-task/submit/:iTask"
            element={
              <PrivateRoute loginPath="/login">
                <SubmitTask />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-task/attach-ticket/:iTask"
            element={
              <PrivateRoute loginPath="/login">
                <AttachTicket />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-task/:iTask/create-new-sub-task"
            element={
              <PrivateRoute loginPath="/login">
                <CreateNewSubTask />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-task/:iTask/edit"
            element={
              <PrivateRoute loginPath="/login">
                <CreateNewSubTask />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-task/:iTask/reassign"
            element={
              <PrivateRoute loginPath="/login">
                <ReassignTask />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-ticket"
            element={
              <PrivateRoute loginPath="/login">
                <MyTicket />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-ticket/:iTicket"
            element={
              <PrivateRoute loginPath="/login">
                <MyTicketDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-ticket/create"
            element={
              <PrivateRoute loginPath="/login">
                <CreateTicket />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-ticket/edit/:iTicket"
            element={
              <PrivateRoute loginPath="/login">
                <EditTicket />
              </PrivateRoute>
            }
          />

          <Route
            path="/contact-center"
            element={
              <PrivateRoute loginPath="/login">
                <Relay />
              </PrivateRoute>
            }
          />

          <Route
            path="/contact"
            element={
              <PrivateRoute loginPath="/login">
                <Contact />
              </PrivateRoute>
            }
          />

          <Route
            path="/contact/search-contact"
            element={
              <PrivateRoute loginPath="/login">
                <SearchContact />
              </PrivateRoute>
            }
          />

          <Route
            path="/contact/search-organization"
            element={
              <PrivateRoute loginPath="/login">
                <SearchContactByOrganization />
              </PrivateRoute>
            }
          />

          <Route
            path="/more"
            element={
              <PrivateRoute loginPath="/login">
                <More />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-profile"
            element={
              <PrivateRoute loginPath="/login">
                <More />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute loginPath="/login">
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <PrivateRoute loginPath="/login">
                <FeedBack />
              </PrivateRoute>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivateRoute loginPath="/login">
                <PrivacyPollicy />
              </PrivateRoute>
            }
          />
          <Route
            path="/tnc"
            element={
              <PrivateRoute loginPath="/login">
                <TnC />
              </PrivateRoute>
            }
          />
          <Route
            path="/aboutus"
            element={
              <PrivateRoute loginPath="/login">
                <AboutUss />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RootNavigator;
