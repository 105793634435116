import { Avatar, Button, Col, Divider, Row, message } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { httpRequest } from "../../helpers/api";
import copy from "copy-to-clipboard";
import SLALevel from "../MyTask/SLALevel";
import { generateColor, getInitialName } from "../../helpers/constant";

type Props = {
  ticketId?: string;
  slaLevel?: string;
  createdBy?: number;
  createdByUser: string;
  userCreateTicket?: string;
};

const TicketInformation = (props: Props) => {
  const [createdByName, setCreatedByName] = useState("");
  const iUser = props.createdBy;

  const fetchData = async () => {
    try {
      const data = await httpRequest.get<any>("users/" + iUser);
      setCreatedByName(data.data.payload.vFullName );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iUser]);
  return (
    <div style={{ marginRight: 20 }}>
      <Row>
        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
          <Text>Ticket ID</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <TextBold>{`#${props.ticketId?.toUpperCase()}`}</TextBold>
          <Button
            type="primary"
            onClick={() => {
              if (props.ticketId) {
                copy(props.ticketId);
                message.success("Success copy Ticket ID");
              }
            }}
          >
            Copy
          </Button>
        </Col>
      </Row>
      <TicketDivider />
      <Row>
        <Col span={8}>
          <Text>SLA</Text>
        </Col>
        <SLALevel level={props.slaLevel} />
      </Row>
      <TicketDivider />
      <Row>
        <Col span={8}>
          <Text>Created by</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            style={{ backgroundColor: generateColor(props.createdByUser ? props.createdByUser: "adasd") }}
          >
            {getInitialName(props.createdByUser ? props.createdByUser: "adasd")}
          </Avatar>
          <TextBold>{props.createdByUser}</TextBold>
        </Col>
      </Row>
      <TicketDivider />
    </div>
  );
};

export default TicketInformation;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TicketDivider = styled(Divider)`
  padding: 0px;
  margin: 10px 0px;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
