import { BaseResponseProps } from "@qlibs/react-components";
import { message } from "antd";
import { useState } from "react";
import { httpRequest } from "../helpers/api";
import { getErrorMessage } from "@qlibs/react-components";
import { useSignIn, useSignOut } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clearOfflineData, syncOfflineData } from "./useOfflineData";
import { saveToken } from "../helpers/auth";
import { apiSignOut } from "../api/auth";

type Props = {
  apiLoginUrl?: string;
  apiGetMyProfileUrl?: string;
};

export default function useAuthApp(props?: Props) {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const signOut = useSignOut();

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const doOtpRegister = async (data: { vEmail?: string; vMobile?: string }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultOtpLogin = await httpRequest.post<
          BaseResponseProps<{
            vEmail: string;
            OTP: string;
          }>
        >(
          props?.apiLoginUrl ||
            process.env.REACT_APP_BASE_URL + "/auth/email/otp/send",
          { vEmail: data.vEmail }
        );

        if (!resultOtpLogin) {
          message.error("Register failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultOtpLogin.data.payload;
        }
      } else if (data.vMobile) {
        const resultOtpLogin = await httpRequest.post<
          BaseResponseProps<{
            vEmail: string;
            OTP: string;
          }>
        >(
          props?.apiLoginUrl ||
            process.env.REACT_APP_BASE_URL + "/auth/phone/otp/send",
          { vMobile: data.vMobile }
        );

        if (!resultOtpLogin) {
          message.error("Register failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultOtpLogin.data.payload;
        }
      }
    } catch (err) {
      message.error(
        "Create a new account failed. Email/Phone is already registered"
      );
    }

    setIsAuthLoading(false);
  };

  const doOtpAddEmailPhone = async (data: {
    vEmail?: string;
    vMobile?: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultOtpLogin = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
            otp: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/add/email", {
          vEmail: data.vEmail,
        });

        if (
          !resultOtpLogin ||
          resultOtpLogin.data.payload.isSuccess === false
        ) {
          message.error("Email already used by other user.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultOtpLogin.data.payload;
        }
      } else if (data.vMobile) {
        const resultOtpLogin = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
            otp: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/add/phone", {
          vMobile: data.vMobile,
        });

        if (
          !resultOtpLogin ||
          resultOtpLogin.data.payload.isSuccess === false
        ) {
          message.error("Phone Number already used by other user.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultOtpLogin.data.payload;
        }
      }
    } catch (err) {
      message.error("Add email/phone failed.");
    }

    setIsAuthLoading(false);
  };

  const doOtpForgotPassword = async (data: {
    vEmail?: string;
    vMobile?: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultAuthForgotPassword = await httpRequest.post<
          BaseResponseProps<{
            tempSession: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/forgot-password", data);

        if (!resultAuthForgotPassword) {
          message.error("ForgotPassword failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          const tempSessionData = {
            // vEmail: data.vEmail,
            tempSession: resultAuthForgotPassword.data.payload.tempSession,
          };
          const resultOtpForgotPassword = await httpRequest.post<
            BaseResponseProps<{
              vEmail: string;
              vMobile: string;
              OTP: string;
              vSession: string;
            }>
          >(
            process.env.REACT_APP_BASE_URL + "/auth/email/otp/send",
            tempSessionData
          );

          if (!resultOtpForgotPassword) {
            message.error("ForgotPassword failed. Empty response.");
            setIsAuthLoading(false);
            return;
          } else {
            setIsAuthLoading(false);
            return resultOtpForgotPassword.data.payload;
          }
        }
      } else if (data.vMobile) {
        const resultAuthForgotPassword = await httpRequest.post<
          BaseResponseProps<{
            tempSession: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/phone/forgot-password", data);

        if (!resultAuthForgotPassword) {
          message.error("ForgotPassword failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          const tempSessionData = {
            // vMobile: data.vMobile,
            tempSession: resultAuthForgotPassword.data.payload.tempSession,
          };
          const resultOtpForgotPassword = await httpRequest.post<
            BaseResponseProps<{
              vEmail: string;
              vMobile: string;
              OTP: string;
              vSession: string;
            }>
          >(
            process.env.REACT_APP_BASE_URL + "/auth/phone/otp/send",
            tempSessionData
          );

          if (!resultOtpForgotPassword) {
            message.error("ForgotPassword failed. Empty response.");
            setIsAuthLoading(false);
            return;
          } else {
            setIsAuthLoading(false);
            return resultOtpForgotPassword.data.payload;
          }
        }
      }
    } catch (err) {
      message.error("ForgotPassword failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doVerifyOtp = async (data: {
    vEmail?: string;
    vMobile?: string;
    otp: string;
    vSession: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultAuthVerifyOtp = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
            token: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/email/otp/verify", {
          otp: data.otp,
          vSession: data.vSession,
        });

        if (!resultAuthVerifyOtp) {
          message.error("Send otp failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else if (!resultAuthVerifyOtp?.data?.payload?.isSuccess) {
          message.error("Invalid OTP");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultAuthVerifyOtp.data.payload.token;
        }
      } else if (data.vMobile) {
        const resultAuthVerifyOtp = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
            token: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/phone/otp/verify", {
          otp: data.otp,
          vSession: data.vSession,
        });

        if (!resultAuthVerifyOtp) {
          message.error("Send otp failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else if (!resultAuthVerifyOtp?.data?.payload?.isSuccess) {
          message.error("Invalid OTP");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultAuthVerifyOtp.data.payload.token;
        }
      }
    } catch (err) {
      message.error("Send Otp failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doResendOtp = async (data: {
    vEmail?: string;
    vMobile?: string;
    tempSession?: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultAuthSendOtp = await httpRequest.post<
          BaseResponseProps<{
            vEmail: string;
            vMobile: string;
            OTP: string;
            vSession: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/email/otp/resend", data);

        if (!resultAuthSendOtp) {
          message.error("Resend otp failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultAuthSendOtp.data.payload;
        }
      } else if (data.vMobile) {
        const resultAuthSendOtp = await httpRequest.post<
          BaseResponseProps<{
            vEmail: string;
            vMobile: string;
            OTP: string;
            vSession: string;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/phone/otp/resend", data);

        if (!resultAuthSendOtp) {
          message.error("Resend otp failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultAuthSendOtp.data.payload;
        }
      }
    } catch (err) {
      message.error("Resend Otp failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doChangePassword = async (data: {
    vEmail?: string;
    vMobile?: string;
    token?: string;
    newPassword: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultAuthChangePassword = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/change-password", {
          token: data.token,
          newPassword: data.newPassword,
        });

        if (!resultAuthChangePassword) {
          message.error("Change password failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else if (!resultAuthChangePassword?.data?.payload?.isSuccess) {
          message.error("Invalid Token");
          setIsAuthLoading(false);
          return;
        } else {
          message.success("Success change password");
          setIsAuthLoading(false);
          return resultAuthChangePassword.data.payload.isSuccess;
        }
      } else if (data.vMobile) {
        const resultAuthChangePassword = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/phone/change-password", {
          token: data.token,
          newPassword: data.newPassword,
        });

        if (!resultAuthChangePassword) {
          message.error("Change password failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else if (!resultAuthChangePassword?.data?.payload?.isSuccess) {
          message.error("Invalid Token");
          setIsAuthLoading(false);
          return;
        } else {
          message.success("Success change password");
          setIsAuthLoading(false);
          return resultAuthChangePassword.data.payload.isSuccess;
        }
      }
    } catch (err) {
      message.error("Change password failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doOtpLogin = async (data: {
    vEmail?: string;
    vPassword?: string;
    vMobile?: string;
    vMobileGUID?: string;
    vWebGUID?: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultAuthLogin = await httpRequest.post<
          BaseResponseProps<{
            tempSession: string;
          }>
        >(
          props?.apiLoginUrl || process.env.REACT_APP_BASE_URL + "/auth/signin",
          data
        );

        if (!resultAuthLogin) {
          message.error("Login failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          const tempSessionData = {
            // vEmail: data.vEmail,
            tempSession: resultAuthLogin.data.payload.tempSession,
          };
          const resultOtpLogin = await httpRequest.post<
            BaseResponseProps<{
              vEmail: string;
              vMobile: string;
              OTP: string;
              vSession: string;
              tempSession: string;
            }>
          >(
            props?.apiLoginUrl ||
              process.env.REACT_APP_BASE_URL + "/auth/email/otp/send",
            tempSessionData
          );

          if (!resultOtpLogin) {
            message.error("Login failed. Empty response.");
            setIsAuthLoading(false);
            return;
          } else {
            setIsAuthLoading(false);
            const res = {
              ...resultOtpLogin.data.payload,
              tempSession: tempSessionData.tempSession,
            };
            return res;
          }
        }
      } else if (data.vMobile) {
        const resultAuthLogin = await httpRequest.post<
          BaseResponseProps<{
            tempSession: string;
          }>
        >(
          props?.apiLoginUrl || process.env.REACT_APP_BASE_URL + "/auth/signin",
          data
        );

        if (!resultAuthLogin) {
          message.error("Login failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          const tempSessionData = {
            // vMobile: data.vMobile,
            tempSession: resultAuthLogin.data.payload.tempSession,
          };
          const resultOtpLogin = await httpRequest.post<
            BaseResponseProps<{
              vEmail: string;
              vMobile: string;
              OTP: string;
              vSession: string;
            }>
          >(
            props?.apiLoginUrl ||
              process.env.REACT_APP_BASE_URL + "/auth/phone/otp/send",
            tempSessionData
          );

          if (!resultOtpLogin) {
            message.error("Login failed. Empty response.");
            setIsAuthLoading(false);
            return;
          } else {
            setIsAuthLoading(false);
            const res = {
              ...resultOtpLogin.data.payload,
              tempSession: tempSessionData.tempSession,
            };
            return res;
          }
        }
      }
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doLogin = async (
    data: {
      vEmail?: string;
      otp?: string;
      vMobile?: string;
      vSession?: string;
    },
    callback?: () => void
  ) => {
    setIsAuthLoading(true);
    try {
      const resultAuthLogin = await httpRequest.post<
        BaseResponseProps<{
          isSuccess: boolean;
          token: string;
        }>
      >(
        data.vEmail
          ? props?.apiLoginUrl ||
              process.env.REACT_APP_BASE_URL + "/auth/email/otp/verify"
          : process.env.REACT_APP_BASE_URL + "/auth/phone/otp/verify",
        data
      );

      if (!resultAuthLogin) {
        message.error("Login failed. Empty response.");
        setIsAuthLoading(false);
        return;
      }

      if (resultAuthLogin) {
        saveToken(resultAuthLogin.data.payload.token);
      }

      console.log(resultAuthLogin);
      const resProfile = await axios.get<BaseResponseProps<any>>(
        props?.apiGetMyProfileUrl ||
          process.env.REACT_APP_BASE_URL + "/users/me",
        {
          headers: {
            Authorization: "Bearer " + resultAuthLogin.data.payload.token,
          },
        }
      );

      if (!resProfile) {
        message.error("Login failed. No profile.");
        setIsAuthLoading(false);
        return;
      }

      if (
        signIn({
          token: resultAuthLogin.data.payload.token,
          expiresIn: 10000,
          tokenType: "Bearer",
          authState: resProfile.data.payload,
          // authState: { token: resultAuthLogin.data.payload.token },
          // refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
          // refreshTokenExpireIn: res.data.refreshTokenExpireIn, // Only if you are using refreshToken feature
        })
      ) {
        // Redirect or do-something
        // console.log(resProfile)
        if (callback) {
          callback();
        } else {
          navigate("/dashboard", { replace: true });
        }
        syncOfflineData(resProfile.data.payload.iUser);
        message.success("Welcome to " + process.env.REACT_APP_WEBSITE_NAME);
      } else {
        message.error("Login failed.");
        //Throw error
      }
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doSendOtpRegister = async (data: {
    vEmail?: string;
    vMobile?: string;
    otp: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data.vEmail) {
        const resultAuthOtpRegister = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
            token: string;
          }>
        >(
          props?.apiLoginUrl ||
            process.env.REACT_APP_BASE_URL + "/auth/email/otp/verify",
          data
        );

        if (!resultAuthOtpRegister) {
          message.error("Send otp failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultAuthOtpRegister.data.payload.token;
        }
      } else if (data.vMobile) {
        const resultAuthOtpRegister = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
            token: string;
          }>
        >(
          props?.apiLoginUrl ||
            process.env.REACT_APP_BASE_URL + "/auth/phone/otp/verify",
          data
        );

        if (!resultAuthOtpRegister) {
          message.error("Send otp failed. Empty response.");
          setIsAuthLoading(false);
          return;
        } else {
          setIsAuthLoading(false);
          return resultAuthOtpRegister.data.payload.token;
        }
      }
    } catch (err) {
      message.error("Send Otp failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doRegister = async (data: {
    vEmail: string;
    vMobile: string;
    vPassword: string;
    vFirstName: string;
    vLastName: string;
    vNickName: string;
    iGender: number;
    birthdate: string;
  }) => {
    setIsAuthLoading(true);
    try {
      console.log("Register Data", data);
      const resultAuthLogin = await httpRequest.post<
        BaseResponseProps<{
          isSuccess: boolean;
        }>
      >(
        props?.apiLoginUrl || process.env.REACT_APP_BASE_URL + "/auth/register",
        data
      );

      if (!resultAuthLogin) {
        message.error("Register failed. Empty response.");
        setIsAuthLoading(false);
        return;
      } else {
        navigate("/login", { replace: true });
      }
      message.success("Account created successfully");
    } catch (err) {
      message.error("Register failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const doAddEmailPhone = async (data: {
    vEmail?: string;
    vMobile?: string;
    otp: string;
  }) => {
    setIsAuthLoading(true);
    try {
      if (data?.vEmail) {
        const res = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/add/email/verify", data);
        if (res) {
          message.success("Email change successfully");
          await syncProfile();
          setIsAuthLoading(false);
          return res;
        }
      } else if (data?.vMobile) {
        const res = await httpRequest.post<
          BaseResponseProps<{
            isSuccess: boolean;
          }>
        >(process.env.REACT_APP_BASE_URL + "/auth/add/phone/verify", data);
        if (res) {
          message.success("Phone Number change successfully");
          await syncProfile();
          setIsAuthLoading(false);
          return res;
        }
      }
    } catch (err) {
      message.error(getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  const syncProfile = async () => {
    const myProfile = await httpRequest.get<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/users/me"
    );
    if (myProfile.data.payload) {
      localStorage.setItem(
        "_auth_state",
        JSON.stringify(myProfile.data.payload)
      );
    }
    window.location.reload();
  };

  const doLogout = async () => {
    try {
      clearOfflineData();
      await apiSignOut();
      signOut();
      navigate("/login");
    } catch (err) {
      message.error("Logout failed.");
    }
  };

  return {
    isAuthLoading,
    syncProfile,
    setIsAuthLoading,
    doLogin,
    doRegister,
    doOtpLogin,
    doOtpRegister,
    doSendOtpRegister,
    doOtpForgotPassword,
    doChangePassword,
    doVerifyOtp,
    doOtpAddEmailPhone,
    doAddEmailPhone,
    doResendOtp,
    doLogout,
  };
}
