import React, { Suspense, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  Layout,
  Space,
  Spin,
  Tooltip,
  message,
  Typography,
} from "antd";
import {
  AppstoreOutlined,
  BellOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LeftCircleOutlined,
  PictureOutlined,
  RightCircleOutlined,
  SettingOutlined,
  TableOutlined,
  TagOutlined,
  UserOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { LuClipboardList } from "react-icons/lu";
import { PiHeadsetBold } from "react-icons/pi";
import styled from "styled-components";
import UserAvatar from "../../components/UserAvatar";
import { appVersion, now, thisYear } from "../../helpers/constant";
import useConfigApp from "../../hooks/useConfigApp";
import AppLogo from "../../components/AppLogo";
import { Offline, Online } from "react-detect-offline";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { theme } from "../../assets/theme";
import { useAuthUser } from "react-auth-kit";
import { MainCheckHealth } from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { Clipboard, Headphones, Layers } from "react-feather";
import ExecutionTime from "../../components/ExecutionTime";
import useExecutionTime from "../../hooks/useExecutionTime";

const APP_LABEL = [
  {
    key: "/dashboard",
    label: "Home",
    icon: (
      <HomeOutlined style={{ fontSize: 18, color: theme.colors.primary }} />
    ),
  },
  {
    key: "/my-task",
    label: "My Task",
    icon: <Layers size={18} style={{ color: theme.colors.primary }} />,
  },
  {
    key: "/my-ticket",
    label: "My Ticket",
    icon: (
      <AppstoreOutlined style={{ fontSize: 18, color: theme.colors.primary }} />
    ),
  },
  {
    key: "/contact-center",
    label: "Contact Center",
    icon: <PiHeadsetBold size={18} style={{ color: theme.colors.primary }} />,
  },
  {
    key: "/contact",
    label: "Contact",
    icon: <LuClipboardList size={18} style={{ color: theme.colors.primary }} />,
  },
  {
    key: "/more",
    label: "More",
    icon: (
      <SettingOutlined style={{ fontSize: 18, color: theme.colors.primary }} />
    ),
  },
];

const { Header, Sider, Content } = Layout;

type Props = {};

const AppLayout: React.FC<Props> = () => {
  const [isOnline, setIsOnline] = React.useState(false);
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const { isOn, executionTime } = useExecutionTime();
  const location = useLocation();
  const auth = useAuthUser();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentStatus = window.navigator.onLine;
    setIsOnline(currentStatus);
  }, []);

  return (
    <>
      {/* <div>
        {!!process.env.REACT_APP_CHECK_HEALTH_URL && (
          <MainCheckHealth
            url={process.env.REACT_APP_CHECK_HEALTH_URL || "/"}
            axiosInstance={httpRequest as any}
          />
        )}
      </div> */}
      <Layout style={{ height: "100vh", flexDirection: "row" }}>
        <AppSider
          onCollapse={toggleSidebar}
          trigger={null}
          collapsible
          collapsed={sidebarCollapsed}
          collapsedWidth={70}
          width={250}
          style={{ paddingLeft: sidebarCollapsed ? 0 : "5px", height: "100vh" }}
        >
          <AppLogo collapsed={sidebarCollapsed} />
          <Sidebar />
          {/* {isOn ? <ExecutionTime /> : null} */}
        </AppSider>
        {sidebarCollapsed ? (
          <Tooltip placement="right" title={"Expand Sidebar"}>
            <Button
              style={{
                position: "absolute",
                bottom: 0,
                height: 40,
                width: 66,
                backgroundColor: theme.colors.primary,
              }}
              onClick={toggleSidebar}
              type="text"
              icon={
                <RightCircleOutlined
                  style={{ color: theme.colors.white }}
                  className="icon-collapsed"
                />
              }
            />
          </Tooltip>
        ) : (
          <MinimizeButton
            onClick={toggleSidebar}
            style={{
              position: "absolute",
              bottom: 0,
              height: 40,
              width: 240,
            }}
          >
            <div>{`v${appVersion} · ©${thisYear} ~ (${executionTime}ms)`}</div>
            <LeftCircleOutlined color={theme.colors.white} />
          </MinimizeButton>
        )}

        <Layout
          id="scroll-content"
          className="site-layout"
          style={{ minHeight: "100vh", overflowY: "scroll" }}
        >
          <AppHeader>
            {/* {APP_LABEL.map((item) => {
              if (item.key === location.pathname) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <AppHeaderLabel>
                      {item.icon}
                      <Typography.Title
                        level={5}
                        style={{
                          marginLeft: 8,
                          marginTop: 5,
                          color: theme.colors.gray,
                          fontWeight: "normal",
                          alignItems: "center",
                        }}
                      >
                        {item.label}
                      </Typography.Title>
                      <Divider type="vertical" />
                      <Typography.Text style={{ color: "darkgrey" }}>
                        {item.label}
                      </Typography.Text>
                    </AppHeaderLabel>
                  </div>
                );
              }
            })} */}

            <Breadcrumbs />

            <AppHeaderAccount>
              {/* <DateNow>
                Welcome, <b>{auth()?.firstName || auth()?.name}</b>{' '}
                <small>
                  (<i>{auth()?.role.roleName}</i>)
                </small>
              </DateNow> */}
              {/* <DateNow>{new Date().toISOString()}</DateNow> */}

              <DividerTitle />
              <UserAvatar />
            </AppHeaderAccount>
          </AppHeader>
          <AppContent>
            <Space size="middle" direction="vertical">
              <Suspense fallback={<Spin spinning={true} />}>
                <Outlet />
              </Suspense>
            </Space>
          </AppContent>
        </Layout>
        {/* <TawkMessengerReact
          propertyId="65a62d590ff6374032c0c3ea"
          widgetId="1hk8i29gf"
        /> */}
      </Layout>
    </>
  );
};

const AppContent = styled(Content)`
  height: 100%;
`;

const AppSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: auto;
  overflow-x: hidden;
  padding-left: 10px;
`;

const AppHeader = styled(Header)`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  padding: 0 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
`;

const DateNow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: flex-end;
`;

const AppHeaderLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DividerTitle = styled.div`
  width: 2.5px;
  height: 100%;
  background-color: #eef0f6;
  opacity: 0;
  margin: 0 10px;
`;

const MinimizeButton = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  cursor: pointer;

  div {
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AppLayout;
