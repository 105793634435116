import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Table,
  TableProps,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useFetchList from "../../hooks/useFetchList";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import styled from "styled-components";
import {
  Briefcase,
  ChevronRight,
  Mail,
  Phone,
  User,
  Users,
} from "react-feather";
import { ContactProps } from "../../types/contact.type";
import {
  apiDeleteContactByRelayId,
  apiDeleteContactByUserId,
  apiGetMyContact,
} from "../../api/contact";
import { censoredPhone, generateColor } from "../../helpers/constant";
import { getErrorMessage, getInitialName } from "@qlibs/react-components";
import OrganizationContact from "../../components/Contact/OrganizationContact";
import { getContactUser, getContacts } from "../../hooks/useOfflineData";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import useExecutionTime from "../../hooks/useExecutionTime";
import Search from "antd/es/input/Search";

const Contact = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [showModal, setShowModal] = useState(false);
  const [showModalDetailContact, setShowModalDetailContact] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<{
    id: { iUser?: number; iRelay?: number };
  }>();
  const [name, setName] = useState("Not Set");
  const { stopTimer } = useExecutionTime();
  const [organization, setOrganization] = useState("Personal");
  const [email, setEmail] = useState("Not Set");
  const [jobTitle, setJobTitle] = useState("Not Set");
  const [once, setOnce] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState("Not Set");
  const [userContact, setUserContact] = useState<any[]>();
  const [keyword, setKeyword] = useState<string>();
  const [organizationSelected, setOrganizationSelected] = useState<number>();

  const setContactData = (res: any) => {
    if (res) {
      if (res.data.payload.users && res.data.payload.relay) {
        const resConcat = res.data.payload.users.concat(res.data.payload.relay);
        resConcat.sort((a: any, b: any) => {
          const vNameA =
            a.user?.[0]?.vFirstName?.toLowerCase() || a.relay?.[0]?.vName;
          const vNameB =
            b.user?.[0]?.vFirstName?.toLowerCase() || b.relay?.[0]?.vName;

          return vNameA.localeCompare(vNameB);
        });
        setUserContact(resConcat);
      } else if (res.data.payload.relay) {
        setUserContact(res.data.payload.relay);
      } else if (res.data.payload.users) {
        setUserContact(res.data.payload.users);
      } else if (res.data.payload.results) {
        if (res.data.payload.results.users && res.data.payload.results.relays) {
          const resConcat = res.data.payload.results.users.concat(
            res.data.payload.results.relays
          );
          resConcat.sort((a: any, b: any) => {
            const vNameA =
              a.user?.vFirstName?.toLowerCase() || a.vName.toLowerCase();
            const vNameB =
              b.user?.vFirstName?.toLowerCase() || b.vName.toLowerCase();

            return vNameA.localeCompare(vNameB);
          });
          setUserContact(resConcat);
        } else if (res.data.payload.results.relays) {
          setUserContact(res.data.payload.results.relays);
        } else if (res.data.payload.results.users) {
          setUserContact(res.data.payload.results.users);
        }
      }
    }
  };

  const fetchContact = async () => {
    try {
      const res = await apiGetMyContact({
        searchQuery: keyword,
        iOrganization: organizationSelected ? organizationSelected : undefined,
      });
      if (res) {
        setContactData(res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   if (userContact === undefined && offlineData !== undefined) {
  //     setContactData({ data: { payload: offlineData } });
  //   }
  // }, [offlineData]);

  useEffect(() => {
    fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, organizationSelected]);

  const { data: userOrganizations } = useFetchList<OrganizationUserProps>({
    endpoint: "organization-users",
    pageData: "my-organization-users",
    iUser: auth()?.iUser,
    initialQuery: { iUser: auth()?.iUser },
  });

  const handleDeleteContact = async () => {
    try {
      if (selectedId?.id?.iUser) {
        await apiDeleteContactByUserId(selectedId.id.iUser);
      } else if (selectedId?.id?.iRelay) {
        await apiDeleteContactByRelayId(selectedId?.id?.iRelay);
      }
      fetchContact();
    } catch (err) {
      message.error(getErrorMessage(err));
    }
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "Contact Name",
      dataIndex: "contactName",
      key: "contactName",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            style={{
              backgroundColor: generateColor(
                record.user?.iUser
                  ? `${record.user?.vFirstName} ${record.user?.vLastName}`
                  : record.vName
                  ? record.vName
                  : record.user
                  ? `${record.user?.[0]?.vFirstName} ${record.user?.[0]?.vLastName}`
                  : record.relay?.[0]?.vName
              ),
            }}
          >
            {getInitialName(
              record.user?.iUser
                ? `${record.user?.vFirstName} ${record.user?.vLastName}`
                : record.vName
                ? record.vName
                : record.user
                ? `${record.user?.[0]?.vFirstName} ${record.user?.[0]?.vLastName}`
                : record.relay?.[0]?.vName
            )}
          </Avatar>
          {record.relay && (
            <Status color="#404852" backgroundColor="#E6E9EF">
              Contact Center
            </Status>
          )}
          <Text>
            {record.user?.iUser
              ? `${record.user?.vFirstName} ${record.user?.vLastName}`
              : record.vName
              ? record.vName
              : record.user
              ? `${record.user?.[0]?.vFirstName} ${record.user?.[0]?.vLastName}`
              : record.relay?.[0]?.vName}
          </Text>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => (
        <Text>{record.user?.[0]?.vEmail || record.user?.vEmail || "-"}</Text>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, record) => (
        <Text>
          {record.user?.[0]?.vMobile || record.user?.vMobile
            ? record.user?.[0]?.vMobile || record.user?.vMobile
            : "-"}
        </Text>
      ),
    },
    // {
    //   title: "Job Title",
    //   dataIndex: "jobTitle",
    //   key: "jobTitle",
    //   render: (_, record) => <Text>{record.iOrganization}</Text>,
    // },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: (_, record) => (
        <Text>
          {record.user?.[0]?.iUser
            ? record.organization?.[0]?.vName || "Personal"
            : record.relay?.[0]?.organization?.vName}
        </Text>
      ),
    },
  ];
  useEffect(() => {
    if (once && userContact !== undefined) {
      stopTimer();
      setOnce(false);
    }
  }, [userContact]);
  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col span={20}>
            <Row style={{ gap: 10 }}>
              <Col span={14}>
                <Text style={{ color: "gray" }}>Search</Text>
                <Search
                  style={{ marginTop: 10 }}
                  placeholder="Search"
                  onSearch={(value, _e, info) => setKeyword(value)}
                  allowClear
                />
              </Col>
              <Col span={8}>
                <Text style={{ color: "gray" }}>Organization</Text>
                <Select
                  style={{ marginTop: 10 }}
                  defaultValue={organizationSelected}
                  allowClear
                  placeholder="Status"
                  onChange={(val) => setOrganizationSelected(val)}
                >
                  <Select.Option value={undefined}>All</Select.Option>
                  <Select.Option value={0}>Personal</Select.Option>
                  {userOrganizations.map((item) => (
                    <Select.Option value={item.organization.iOrganization}>
                      {item.organization.vName}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              style={{ marginTop: 25, width: "100%" }}
              onClick={() => setShowModal(true)}
            >
              + Add Contact
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{ margin: 20 }}>
        <Table
          columns={columns}
          dataSource={userContact}
          onRow={(record, rowIndex) => {
            return {
              style: { cursor: "pointer" },
              onClick: () => {
                setSelectedId({
                  id: {
                    iUser: record.user?.[0]?.iUser,
                    iRelay: record.relay?.[0]?.iRelay,
                  },
                });
                setName(
                  record.user?.iUser
                    ? `${record.user?.vFirstName} ${record.user?.vLastName}`
                    : record.vName
                    ? record.vName
                    : record.user
                    ? `${record.user?.[0]?.vFirstName} ${record.user?.[0]?.vLastName}`
                    : record.relay?.[0]?.vName
                );
                setEmail(
                  record.user?.[0]?.vEmail || record.user?.vEmail || "Not Set"
                );
                setPhoneNumber(
                  record.user?.[0]?.vMobile || record.user?.vMobile || "Not Set"
                );
                setShowModalDetailContact(true);
              },
            };
          }}
        />
      </div>
      <Modal
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
      >
        <div style={{ marginBottom: 20 }}>
          <TextBold>Add Contact</TextBold>
          <SubText>Choose type to add contact</SubText>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contact/search-contact")}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                marginBottom: 5,
              }}
            >
              <Users style={{ color: "#045aff", fontWeight: "bold" }} />
              <Title>Search Contact</Title>
            </div>
            <SubText>Add Contact by Name, Phone Number or Email</SubText>
          </div>
          <ChevronRight />
        </div>
        <Divider />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contact/search-organization")}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                marginBottom: 5,
              }}
            >
              <Briefcase style={{ color: "#045aff", fontWeight: "bold" }} />
              <Title>Add From Organization</Title>
            </div>
            <SubText>
              Communicate and collaborate with members of a organization
            </SubText>
          </div>
          <ChevronRight />
        </div>
      </Modal>

      <Modal
        open={showModalDetailContact}
        onCancel={() => setShowModalDetailContact(false)}
        footer={false}
      >
        <SubTextBold>Detail Contact</SubTextBold>
        <Divider style={{ marginTop: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            size={50}
            style={{ backgroundColor: generateColor(name) }}
          >
            {getInitialName(name)}
          </Avatar>
          <TextBold>{name}</TextBold>
        </div>
        <Divider />
        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Organization</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Briefcase style={{ opacity: 0.5 }} />
            </div>
            <Text>{organization}</Text>
          </div>
        </div>
        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Email Address</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Mail style={{ opacity: 0.5 }} />
            </div>
            <Text>{email}</Text>
          </div>
        </div>

        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Job Title</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <User style={{ opacity: 0.5 }} />
            </div>
            <Text>{jobTitle}</Text>
          </div>
        </div>

        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Phone Number</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Phone style={{ opacity: 0.5 }} />
            </div>
            <Text>{phoneNumber}</Text>
          </div>
        </div>
        <Divider />

        <Button
          type="primary"
          danger
          onClick={() => {
            setShowModalDetailContact(false);
            setShowConfirmDelete(true);
          }}
        >
          Delete Contact
        </Button>
      </Modal>

      <ModalConfirm
        isOpen={showConfirmDelete}
        danger
        onOk={() => {
          handleDeleteContact();
          setShowConfirmDelete(false);
          navigate("/contact");
        }}
        onCancel={() => setShowConfirmDelete(false)}
        title="Delete Contact"
        message="Are you sure want to Delete this Contact?"
      />
    </React.Fragment>
  );
};

export default Contact;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: #045aff;
`;

const SubText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const SubTextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0px 10px;
  display: inline-block;
`;
