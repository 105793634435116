import { BaseResponseProps } from "@qlibs/react-components";
import { httpRequest } from "../helpers/api";

export async function apiGetNameById(iUser: number) {
  try {
    const res = await httpRequest.get<BaseResponseProps<{ vFullName: string }>>(
      process.env.REACT_APP_BASE_URL + "/users/" + iUser
    );
    return res.data.payload.vFullName;
  } catch (err) {
    return "";
  }
}

export async function apiDeleteMyAccount() {
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/request-delete",
      { reason: "I need to take a rest." }
    );
    if (res) {
      return res;
    }
    return null;
  } catch (err) {
    return "";
  }
}
