import { Button, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { TaskProps, initialCreateTask } from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import useTaskList from "../../hooks/useTaskList";
import { useAuthUser } from "react-auth-kit";
import TaskList from "../myTicket/taskList";
import { httpRequest } from "../../helpers/api";
import { TicketProps } from "../../types/ticket.type";
import { apiGetOneTask } from "../../api/task";

const ReassignTask = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const taskSelected = window.location.pathname.split("/").slice(-2, -1)[0];
  const {
    taskItems,
    updateTask,
    handleAddTask,
    handleEmptyTask,
    resolveDate,
    ticketDate,
  } = useTaskList();
  const [myTask, setMyTask] = useState<TaskProps>();
  const [taskTicket, setTaskTicket] = useState<TicketProps>();
  const { data: myTasks } = useFetchList<TaskProps>({
    endpoint: "tasks/custom/my-tasks",
  });

  const handleSaveTask = async () => {
    try {
      // Save tasks sequentially
      const arrayTaskId: number[] = [];
      for (let index = 0; index < taskItems.length; index++) {
        const task = taskItems[index];
        const submitTask = await httpRequest.post("/tasks", {
          ...task,
          iTicket: myTask?.ticket?.iTicket,
          iParentTask: myTask?.iTask,
          jAttachment: myTask?.jAttachment,
        });
        console.log("submitTask", submitTask);
        arrayTaskId.push(submitTask.data.payload.iTask);

        // Add attachment for the current task
        if (task.jAttachment && task.jAttachment.length > 0) {
          const taskId = submitTask.data.payload.iTask;
          const formData = new FormData();
          task.jAttachment.forEach((file: any) => {
            formData.append("files", file.originFileObj);
          });
          const submitAttachment = await httpRequest.put(
            `/tasks/${taskId}/attachments`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("submitAttachment", submitAttachment);
        }
      }
      console.log("arrayTaskId", arrayTaskId);
      message.success("Reassigned task, change saved successfully");
      handleEmptyTask();
      navigate(`/my-ticket/${taskTicket?.ipTicket}`);
    } catch (error) {
      console.log("error", error);
      message.error("Failed to save sub task");
    }
  };
  const gettaskTicket = async (iTicket: number | string) => {
    try {
      const ticket = await httpRequest.get(`/tickets/${iTicket}`);
      setTaskTicket(ticket.data.payload);
    } catch (error) {
      message.error("Task's ticket not found!");
      console.log("error", error);
    }
  };

  useEffect(() => {
    const fetchTask = async () => {
      const foundTask = await apiGetOneTask(taskSelected);
      if (foundTask && foundTask?.iResult !== 3) {
        navigate("/my-task/" + foundTask?.ipTask);
      }
      setMyTask(foundTask);
      if (foundTask?.ticket?.iTicket) {
        gettaskTicket(foundTask?.ticket?.iTicket);
      }
    };

    fetchTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTasks, taskSelected]);

  useEffect(() => {
    if (myTask?.vName) {
      // let attachments: {
      //   files: any[];
      // } = { files: [] };
      // myTask?.jAttachment?.files?.map((item: any) =>
      //   attachments.files.push({
      //     name: item?.vRawData?.fileLinkCache?.split("/").pop(),
      //   })
      // );
      updateTask(0, {
        ...taskItems[0],
        vName: myTask?.vName,
        vDescription: myTask?.vDescription,
        jSLA: myTask?.jSLA,
        iResponseType:
          myTask?.histories?.[myTask.histories?.length - 1].jData.iResponseType,
        iParentTask: myTask?.iParentTask,
        dCreatedLocal: new Date(),
        dLimitResponse: myTask?.dLimitResponse,
        dLimitResolve: myTask?.dLimitResolve,
        iOrganizationTarget: myTask?.iOrganizationTarget || 0,
        iUserTarget: myTask?.userTarget?.iUser || myTask?.relayTarget?.iRelay,
      });
    }
  }, [myTask]);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} />
          <Title>Reassign Task</Title>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button onClick={handleSaveTask} type="primary">
            Save
          </Button>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        <Row
          style={{
            gap: 10,
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <Col span={16} style={{ alignItems: "center" }}>
            <HeaderTitle style={{ marginBlock: 20 }}>Reassign Task</HeaderTitle>
          </Col>
          <Col span={5} style={{ alignItems: "center" }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                handleAddTask(initialCreateTask);
              }}
            >
              + Add New Task
            </Button>
          </Col>
        </Row>

        {taskItems.map((task, index) => (
          <TaskList
            isEdit={true}
            key={index}
            dataNumber={index}
            responseTaskLimit={
              new Date(
                taskTicket?.dLimitResponse ? taskTicket?.dLimitResponse : ""
              )
            }
            resolveTaskLimit={
              new Date(
                taskTicket?.dLimitResolve ? taskTicket?.dLimitResolve : ""
              )
            }
            iUser={auth()?.iUser}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default ReassignTask;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
