import { Button, Card, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StatusTask from "../../components/MyTask/StatusTask";
import {
  CreateTaskProps,
  TaskProps,
  initialCreateTask,
} from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import useTaskList from "../../hooks/useTaskList";
import { useAuthUser } from "react-auth-kit";
import TaskList from "../myTicket/taskList";
import { httpRequest } from "../../helpers/api";
import { TicketProps } from "../../types/ticket.type";
import { apiGetOneTask } from "../../api/task";

const CreateNewSubTask = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const isEdit = window.location.pathname.split("/").slice(-1)[0] === "edit";
  const taskSelected = window.location.pathname.split("/").slice(-2, -1)[0];
  const {
    taskItems,
    handleAddTask,
    handleEmptyTask,
    resolveDate,
    ticketDate,
    updateTask,
    isEdited,
    deletedAttachment,
    setDeletedAttachment,
  } = useTaskList();
  const [myTask, setMyTask] = useState<TaskProps>();
  const [taskTicket, setTaskTicket] = useState<TicketProps>();
  const { data: myTasks } = useFetchList<TaskProps>({
    endpoint: "tasks/custom/my-tasks",
  });

  const handleSaveTask = async () => {
    try {
      // Save tasks sequentially
      const arrayTaskId: number[] = [];
      if (isEdit) {
        for (let index = 0; index < taskItems.length; index++) {
          const task = taskItems[index];
          const submitTask = await httpRequest.patch(`/tasks/${task.iTask}`, {
            vName: task.vName,
            dUpdatedLocal: new Date(),
            vDescription: task.vDescription,
            iUserTarget: task.iUserTarget,
            iOrganizationTarget: task.iOrganizationTarget,
            iRelayTarget: task.iRelayTarget,
            iResponseType: task.iResponseType,
            dLimitResponse: task.dLimitResponse,
            dLimitResolve: task.dLimitResolve,
            jSLA: task.jSLA,
          });

          const taskId = task.iTask;
          const formData = new FormData();

          // Add attachment for the current task
          if (task.jAttachment && task.jAttachment.length > 0) {
            task.jAttachment.forEach((file: any) => {
              formData.append("files", file.originFileObj);
            });
          }
          const submitAttachment = await httpRequest.put(
            `/tasks/${taskId}/attachments`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        const deleteAttachment = async (
          iTask?: number,
          iAttachment?: number
        ) => {
          await httpRequest.delete(
            `/tasks/${iTask}/attachments/${iAttachment}`
          );
        };
        deletedAttachment?.map(
          async (item) => await deleteAttachment(item.iTask, item.iAttachment)
        );
        setDeletedAttachment([]);
        message.success("Task updated successfully");
      } else {
        for (let index = 0; index < taskItems.length; index++) {
          const task = taskItems[index];
          const submitTask = await httpRequest.post("/tasks", {
            ...task,
            iTicket: Number(myTask?.ticket?.iTicket),
            iParentTask: Number(myTask?.iTask),
          });
          console.log("submitTask", submitTask);
          arrayTaskId.push(submitTask.data.payload.iTask);

          // Add attachment for the current task
          if (task.jAttachment && task.jAttachment.length > 0) {
            const taskId = submitTask.data.payload.iTask;
            const formData = new FormData();
            task.jAttachment.forEach((file: any) => {
              formData.append("files", file.originFileObj);
            });
            const submitAttachment = await httpRequest.put(
              `/tasks/${taskId}/attachments`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("submitAttachment", submitAttachment);
          }
        }
        console.log("arrayTaskId", arrayTaskId);
        message.success("Sub Task saved successfully");
      }
      handleEmptyTask();
      navigate(-1);
    } catch (error) {
      console.log("error", error);
      message.error("Failed to save sub task");
    }
  };

  const gettaskTicket = async (
    iTask: string | number,
    iTicket: string | number
  ) => {
    try {
      if (isEdit) {
        const currtask = await httpRequest.get(`/tasks/${iTask}`);
        console.log("currtask", currtask);
        updateTask(0, currtask.data.payload);
        return;
      } else {
        const ticket = await httpRequest.get(`/tickets/${iTicket}`);
        setTaskTicket(ticket.data.payload);
      }
    } catch (error) {
      message.error("Task's ticket not found!");
      console.log("error", error);
    }
  };

  useEffect(() => {
    const fetchTask = async () => {
      const foundTask = await apiGetOneTask(taskSelected);
      setMyTask(foundTask);
      if (foundTask?.ticket?.iTicket && foundTask?.iTask) {
        gettaskTicket(foundTask.iTask, foundTask.ticket.iTicket);
      }
    };

    fetchTask();
  }, [myTasks, taskSelected]);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} />
          {isEdit ? (
            <Title>Edit Sub Task</Title>
          ) : (
            <Title>Create New Sub Task</Title>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <Button>Cancel</Button>
          <Button onClick={handleSaveTask} disabled={!isEdited} type="primary">
            Save
          </Button>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        {isEdit ? (
          <></>
        ) : (
          <>
            <HeaderTitle style={{ marginBlock: 20 }}>Parent Task</HeaderTitle>
            <Card style={{ marginBottom: 5 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <Text>Status:</Text>
                <StatusTask iStatus={myTask?.iResult} />
              </div>
              <HeaderTitle>{myTask?.vName}</HeaderTitle>
              <Text>{myTask?.vDescription}</Text>
            </Card>
          </>
        )}
        {isEdit ? (
          <></>
        ) : (
          <>
            <Row
              style={{
                gap: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Col span={20} style={{ alignItems: "center" }}>
                <HeaderTitle style={{ marginBlock: 20 }}>
                  New Sub Task
                </HeaderTitle>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  style={{ marginTop: 30, width: "100%", height: "100%" }}
                  onClick={() => navigate("/my-ticket/create")}
                >
                  + Add New Task
                </Button>
              </Col>
            </Row>
          </>
        )}
        {taskItems?.map((task, index) => (
          <TaskList
            isEdit={isEdit ? true : false}
            key={index}
            dataNumber={index}
            responseTaskLimit={
              taskTicket?.dLimitResponse
                ? taskTicket?.dLimitResponse
                : task.dLimitResponse
            }
            resolveTaskLimit={
              taskTicket?.dLimitResolve
                ? taskTicket?.dLimitResolve
                : task.dLimitResolve
            }
            iUser={auth()?.iUser}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default CreateNewSubTask;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
