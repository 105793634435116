import { ClockCircleOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";

type Props = {
  vTitle: string;
  vMessage: string;
  iRead: boolean;
  dCreatedServer: Date | string;
};

const NotificationItem = (props: Props) => {
  return (
    <React.Fragment>
      <div
        style={{
          padding: 12,
          backgroundColor: !props.iRead ? "#E6E9EF" : undefined,
        }}
      >
        <TextBold>{props.vTitle}</TextBold>
        <Text>{props.vMessage}</Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginTop: 5,
          }}
        >
          <ClockCircleOutlined style={{ color: "grey", opacity: 0.8 }} />
          <Text>
            {dayjs(props.dCreatedServer).format("DD MMMM YYYY HH:mm")}
          </Text>
        </div>
      </div>
      <Divider style={{ marginBlock: 0, paddingBlock: 0 }} />
    </React.Fragment>
  );
};

export default NotificationItem;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: grey;
  opacity: 0.8;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 5px;
`;
