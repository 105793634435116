import {
  CalendarOutlined,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Skeleton } from "antd";
import styled from "styled-components";
import SLALevelIcon from "./SLALevelIcon";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { apiGetOrganization } from "../../api/organization";
import { apiGetNameById } from "../../api/user";

type Props = {
  organizationName?: string | null;
  iOrganization?: number;
  status?: number;
  slaLevel?: string;
  taskName?: string;
  dueDate?: Date | string;
  attachmentTotal?: number;
  userCreatedBy?: string;
  iUserCreate?: number;
  onClick?: () => void;
};

const MyTaskCard = (props: Props) => {
  const formatDate = (date: Date | string): string => {
    const formattedDate = dayjs(date).format("DD MMM YYYY, HH:mm");
    return formattedDate;
  };
  const [assignerName, setAssignerName] = useState("");

  // const fetchOrganization = async () => {
  //   if (props.iOrganization) {
  //     const org = await apiGetOrganization(props.iOrganization);
  //     if (org) {
  //       setOrganizationName(org);
  //     }
  //   }
  // };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  // useEffect(() => {
  //   fetchOrganization();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   fetchOrganization();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.iOrganization]);

  return (
    <Card style={{ marginBlock: 10, cursor:'pointer' }} onClick={handleClick}>
      <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
        <Status color="#404852" backgroundColor="#E6E9EF">
          {props.organizationName || "Personal"}
        </Status>
        {props.status === 3 ? (
          <Status backgroundColor="#EB2222" color="white">
            Rejected
          </Status>
        ) : (
          props.status === 8 && (
            <Status backgroundColor="#D8EDFF" color="#3D98E7">
              Fowarded
            </Status>
          )
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <SLALevelIcon level={props.slaLevel} />
        <TextBold>{props.taskName}</TextBold>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <CalendarOutlined style={{ fontSize: "18px" }} />
        <Text>
          {props.dueDate ? `Due ${formatDate(props.dueDate)}` : "No Due Date"}
        </Text>
        <LinkOutlined style={{ marginLeft: 5, fontSize: "18px" }} />
        <Text>{props.attachmentTotal || 0}</Text>
      </div>
      {!assignerName ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginBottom: 10,
          }}
        >
          <UserOutlined style={{ fontSize: "18px" }} />
          <Text>{`From ${props.userCreatedBy}`}</Text>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginBottom: 10,
          }}
        >
          <UserOutlined style={{ fontSize: "18px" }} />
          <Text>{`From ${props.userCreatedBy}`}</Text>
        </div>
      )}
    </Card>
  );
};

export default MyTaskCard;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0px 10px;
  display: inline-block;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
