import { MailOutlined } from "@ant-design/icons";
import { Card, Form, Input, Button } from "antd";
import React from "react";
import AuthHeaderCard from "../../components/AuthHeaderCard";
import AuthLayout from "../layout/AuthLayout";
import useAuthApp from "../../hooks/useAuthApp";
import Otp from "./Otp";
import ResetPassword from "./ResetPassword";

const ForgotPassword: React.FC = () => {
  const { isAuthLoading, doOtpForgotPassword, doVerifyOtp } = useAuthApp();
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [session, setSession] = React.useState("");
  const [token, setToken] = React.useState("");
  const [isInvalid, setInvalid] = React.useState<
    "empty" | "invalid" | undefined
  >();
  const [message, setMessage] = React.useState<string>("");
  const [activeStep, setActiveStep] = React.useState(1);

  const nextStep = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
  };

  const resOtp = async (data: { vEmail?: string; vMobile?: string }) => {
    const res = await doOtpForgotPassword(data);
    if (res && data.vEmail) {
      setEmail(data.vEmail);
      setOtp(res.OTP);
      setSession(res.vSession);
      nextStep();
    } else if (res && data.vMobile) {
      setMobile(data.vMobile);
      setOtp(res.OTP);
      setSession(res.vSession);
      nextStep();
    }
  };

  const handleOtpSubmit = async (data: any) => {
    const res = await doVerifyOtp(data);
    if (res) {
      setToken(res);
      nextStep();
    }
  };

  const onSubmit = ({ verification }: { verification: string }) => {
    const ruleEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const rulePhone = /^\d{8,12}$/;

    if (!verification || verification === "") {
      setInvalid("empty");
    } else if (ruleEmail.test(verification)) {
      setInvalid(undefined);
      resOtp({ vEmail: verification });
    } else if (rulePhone.test(verification)) {
      setInvalid(undefined);
      const formattedPhone = formatPhone(verification);
      resOtp({ vMobile: formattedPhone });
    }
  };

  const formatPhone = (phone: string) => {
    let formattedPhone = phone;
    if (formattedPhone.startsWith("08")) {
      formattedPhone = `62${formattedPhone.slice(1)}`;
    } else if (!formattedPhone.startsWith("62")) {
      formattedPhone = `62${formattedPhone}`;
    }
    return formattedPhone;
  };

  const handleResendOtp = () => {
    if (email) {
      onSubmit({ verification: email });
    } else if (mobile) {
      onSubmit({ verification: mobile });
    }
  };

  React.useEffect(() => {
    if (isInvalid === "empty") {
      setMessage("Email address is required");
    } else {
      setMessage("Please enter correct email format");
    }
  }, [isInvalid]);

  return activeStep === 2 ? (
    <Otp
      vEmail={email}
      otp={otp}
      vMobile={mobile}
      vSession={session}
      isAuthLoading={isAuthLoading}
      onSubmit={handleOtpSubmit}
      onResendOtp={handleResendOtp}
    />
  ) : activeStep === 3 ? (
    <ResetPassword token={token} vMobile={mobile} vEmail={email} />
  ) : (
    <AuthLayout formPosition="left">
      <Card style={{ width: 500 }} bordered={false}>
        <AuthHeaderCard
          title="Forgot Password"
          subtitle="We will send code to your email or WhatsApp to verification and you can change your password."
        />

        <Form
          layout="vertical"
          name="basic"
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="verification"
            label="Email Address/Phone Number"
            validateStatus={isInvalid ? "error" : ""}
            help={isInvalid && message}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Enter your email/Phone number"
            />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBlock: 10,
              }}
            >
              <Button
                loading={isAuthLoading}
                type="primary"
                htmlType="submit"
                style={{
                  width: "70%",
                  paddingBlock: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Send Verification
              </Button>
            </div>
          </Form.Item>

          {/* <Link
              style={{
                display: "block",
                textAlign: "center",
              }}
              to="/login"
            >
              Back to Login Page
            </Link> */}
        </Form>
      </Card>
    </AuthLayout>
  );
};

// const InfoText = styled.div`
//   font-size: ${({ theme }) => theme.fontSize.smallText};
//   font-weight: ${({ theme }) => theme.fontWeight.regular};
//   color: ${({ theme }) => theme.colors.charcoal400};
//   text-align: center;
//   width: 100%;
//   margin-top: 30px;
//   margin-bottom: 30px;
// `;

export default ForgotPassword;
