import { Avatar, Button, Col, Collapse, Divider, Row, Modal, Card } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import { TaskProps } from "../../types/task.type";
import { httpRequest } from "../../helpers/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiGetNameById } from "../../api/user";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  GetTaskSubmissionProps,
  TaskHistoryProps,
} from "../../types/taskHistory.type";
import TaskAttachment from "./TaskAttachment";
import NoAttachment from "./NoAttachment";
import { YesNoTag } from "./YesNoTag";
import { sub } from "date-fns";
import Item from "antd/es/list/Item";

type Props = {
  myTask?: TaskProps;
};

const SubmissionResult = (props: Props) => {
  const [submissionData, setSubmissionData] = useState<TaskHistoryProps[]>([]);
  const [completeData, setCompleteData] = useState<TaskHistoryProps[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<TaskHistoryProps>();
  
  const getSubmissionData = async () => {
    try {
      const data = await httpRequest.get<GetTaskSubmissionProps>(
        process.env.REACT_APP_BASE_URL +
          `/task-history?iTask=${props.myTask?.iTask}`
      );
      console.log("tesdata", data)
      setCompleteData(data.data.payload.results);
      setSubmissionData(
        data.data.payload.results
        .filter(
          (item, index) =>
            (item.vAction === "REVIEW_TASK" && index+1 === data.data.payload.results.length && item.jData.vNoteReturn!== null)|| item.vAction === "SUBMIT_TASK" ||
            item.vAction === "RETURN_TASK" ||
            (item.vAction === "REVIEW_TASK" && item.jData.vNoteReturn === null && data.data.payload.results.length < 4) 
        )
      );
      return;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.myTask) {
      getSubmissionData();
    }
  }, [props.myTask]);

  console.log("submissionData", submissionData);

  return (

    <Card>
    <div style={{ marginRight: 20, marginTop: 20 }}>
      <Row>
        {
          submissionData?.length < 1 ?
          <></>
          :
          <Text style={{ color: "gray" }}>
          <CheckCircleOutlined /> Submission Result
          </Text>
        }
      </Row>
      {submissionData?.map((item, index) => (
        <Row style={{ marginTop: 10 }}>
          <Collapse
            style={{ width: "100%" }}
            size="large"
            items={[
              {
                key: "1",
                label: `Submission Date: ${dayjs(item.dCreated).format(
                  "DD MMM YYYY"
                )}`,
                children: (
                  <div>
                    {((item.jData.vNoteReturn !== null && item.vAction !== "SUBMIT_TASK") && (index+1 !== submissionData.length) || item.vAction === "RETURN_TASK")  ? (
                      <Card
                        style={{
                          backgroundColor: "#FFE8E8",
                          boxShadow: "0px 0px 0px 0px",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Text>
                              <CheckCircleOutlined /> Need Revision
                            </Text>
                          </div>
                          <div
                            style={{
                              marginLeft: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              onClick={() => {
                                setShowModal(true);
                                setModalData(item);
                              }}
                              style={{
                                textDecoration: "underline",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              View Detail
                            </Text>
                          </div>
                        </Row>
                      </Card>
                    ) : (
                      <></>
                    )}
                    {item.jData.iResponseType === 1 ? (
                      <>
                        <Row
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text style={{ color: "gray", marginTop: 10 }}>
                            {" "}
                            Note
                          </Text>
                          <Text style={{ marginTop: 5 }}>{item.vAction === "SUBMIT_TASK" ? completeData[completeData.length-2].vNote: item.vNote}</Text>
                        </Row>
                        <Row
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text style={{ color: "gray", marginTop: 10 }}>
                            Attachments
                          </Text>
                          <div>
                            {item.jData.jAttachmentSubmission?.files ? (
                              item.jData?.jAttachmentSubmission?.files?.map(
                                (item: any) => (
                                  <TaskAttachment
                                    fileName={
                                      item.vRawData.fileLinkCache
                                        ?.split("/")
                                        .pop() ||
                                      item.ipAttachment +
                                        "." +
                                        item.vRawData.fileLinkCache
                                          .split(".")
                                          .pop()
                                          ?.toLocaleLowerCase()
                                    }
                                    attachmentId={item.ipAttachment}
                                    url={item.vRawData.fileLinkCache}
                                    iTask={props.myTask?.iTask}
                                  />
                                )
                              )
                            ) : (
                              <NoAttachment />
                            )}
                          </div>
                        </Row>
                      </>
                    ) : (
                      item.vAction === "RETURN_TASK" ? (
                        <Row
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {
                          (item.jData.iResult === 621 || item.jData.iResult === 21  || item.jData.iResult === 622 || item.jData.iResult === 22)? 
                          (
                            <Text style={{ color: "gray", marginTop: 10 }}>
                                      {" "}
                                      Yes/No Result
                            </Text>
                          ):(<Text style={{ color: "gray", marginTop: 10 }}>
                          {" "}
                          Yes/No Result</Text>)
                        }
                            <Text style={{ marginTop: 5 }}>
                              <YesNoTag 
                                iResult={
                                  [621, 21, 622, 22].includes(item.jData.iResult)
                                    ? item.jData.iResult
                                    : completeData.find((data) => data.iHistory === item.iHistory-1)?.jData.iResult ?? 0 // Provide a default value, e.g., 0
                                } 
                              />
                            </Text>
                      </Row>
                      ):(undefined)
                    )}
                  </div>
                ),
              },
            ]}
          ></Collapse>
        </Row>
      ))}
      <Modal
        title={`Return Information`}
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={600}
        footer={false}
      >
        <>
          <Row
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text style={{ color: "gray", marginTop: 10 }}> Note</Text>
            <Text style={{ marginTop: 5 }}>{modalData?.jData.vNoteReturn}</Text>
          </Row>
          <Row
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text style={{ color: "gray", marginTop: 10 }}> Attachments</Text>
            <div>
              {modalData?.jData.jAttachmentReturnTemp ? (
                modalData.jData?.jAttachmentReturnTemp?.files?.map((item: any) => (
                  <TaskAttachment
                    fileName={
                      item.vRawData.fileLinkCache?.split("/").pop() ||
                      item.ipAttachment +
                        "." +
                        item.vRawData.fileLinkCache
                          .split(".")
                          .pop()
                          ?.toLocaleLowerCase()
                    }
                    attachmentId={item.ipAttachment}
                    url={item.vRawData.fileLinkCache}
                    iTask={props.myTask?.iTask}
                  />
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "solid",
                    borderColor: "#E6E9EF", // Set border color here
                    borderWidth: 1,
                    borderRadius: 8,
                  }}
                >
                  <Text
                    style={{
                      color: "#757575",
                      marginTop: 10,
                      fontWeight: "bold",
                    }}
                  >
                    No Attachment
                  </Text>
                  <Text style={{ marginTop: 5, color: "#A0A0A0" }}>
                    This task has no attachment yet.
                  </Text>
                </div>
              )}
            </div>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 5,
              marginTop: 10,
            }}
          >
            <Button
              style={{
                display: "flex",
                flex: 1,
                color: "#045AFF",
                justifyContent: "center",
                padding: 20,
                alignItems: "center",
              }}
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </div>
        </>
      </Modal>
    </div>
    </Card>
  );
};

export default SubmissionResult;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TaskDivider = styled(Divider)`
  padding: 0px;
  margin: 10px 0px;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
