import { Link } from "react-router-dom";
import { Form, Input, Card, Button, Divider, Modal } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import AuthLayout from "../layout/AuthLayout";
import AuthHeaderCard, {
  AuthSubTitleCard,
} from "../../components/AuthHeaderCard";
import AppVersion from "../../components/AppVersion";
import useAuthApp from "../../hooks/useAuthApp";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Otp from "./Otp";
import CreateProfile from "./CreateProfile";
import TnC from "../../components/More/TnC";
import PrivacyPolicy from "../../components/More/PrivacyPolicy";

const Register = () => {
  const { isAuthLoading, doOtpRegister, doSendOtpRegister, doRegister } =
    useAuthApp();
  const [isUsePhone, setIsUsePhone] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [otp, setOtp] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );

  const nextStep = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
  };
  // const prevStep = () => {
  //   if (activeStep > 1) {
  //     setActiveStep(activeStep - 1);
  //   }
  // };

  const resOtp = async (data: {
    vEmail?: string;
    vPassword?: string;
    vMobile?: string;
  }) => {
    const res = await doOtpRegister(data);
    if (res && data.vEmail) {
      setEmail(data.vEmail);
      setOtp(res.OTP);
      nextStep();
    } else if (res && data.vMobile) {
      setMobile(data.vMobile);
      setOtp(res.OTP);
      nextStep();
    }
  };

  const handleOtpSubmit = async (data: any) => {
    const isSuccess = await doSendOtpRegister(data);
    if (isSuccess) {
      setSessionId(isSuccess);
      nextStep();
    }
  };

  const handleResendOtp = () => {
    if (email) {
      resOtp({ vEmail: email, vPassword: password });
    } else if (mobile) {
      resOtp({ vMobile: mobile, vPassword: password });
    }
  };

  const toggleUsePhone = () => {
    setIsUsePhone(!isUsePhone);
  };

  useEffect(() => {
    setEmail("");
    setPassword("");
    setMobile("");
    setSessionId("");
    setOtp("");
  }, [isUsePhone]);

  return activeStep === 2 ? (
    <Otp
      vEmail={email}
      otp={otp}
      vMobile={mobile}
      onSubmit={handleOtpSubmit}
      onResendOtp={handleResendOtp}
    />
  ) : activeStep === 3 ? (
    <CreateProfile
      vEmail={email}
      vMobile={mobile}
      vPassword={password}
      sessionId={sessionId}
      onSubmit={doRegister}
    />
  ) : (
    <AuthLayout formPosition="left">
      <Card style={{ width: 500 }} bordered={false}>
        <AuthHeaderCard
          title="Create a New Account"
          subtitle={
            "Create an account to manage your personal task and team to"
          }
        />
        {isUsePhone ? (
          <Form
            layout="vertical"
            name="basic"
            onFinish={resOtp}
            autoComplete="off"
            initialValues={{
              vMobile: "",
              vPassword: "",
            }}
          >
            <Form.Item name="vMobile" label="Phone Number">
              <Input
                type="phone"
                addonBefore={"+62"}
                placeholder="Enter phone number"
              />
            </Form.Item>

            <Form.Item name="vPassword" label="Password">
              <Input.Password
                prefix={<LockOutlined style={{ color: "#A5B2BD" }} />}
                placeholder="Enter your password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Item>

            <Subtitle>
              By signing up, you're agreeing to our{" "}
              <Link
                // onClick={() => setModalContent(<TnC />)}
                to="/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link
                // onClick={() => setModalContent(<PrivacyPolicy />)}
                to="/privacy-and-policy"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Subtitle>

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  loading={isAuthLoading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "70%",
                    marginTop: 20,
                    paddingBlock: 20,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Register
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : (
          <Form
            layout="vertical"
            name="basic"
            onFinish={resOtp}
            autoComplete="off"
            initialValues={{
              vEmail: "",
              vPassword: "",
            }}
          >
            <Form.Item name="vEmail" label="Email Address">
              <Input
                type="email"
                prefix={<MailOutlined style={{ color: "#A5B2BD" }} />}
                placeholder="Enter your email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item name="vPassword" label="Password">
              <Input.Password
                prefix={<LockOutlined style={{ color: "#A5B2BD" }} />}
                placeholder="Enter your password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Item>

            <Subtitle>
              By signing up, you're agreeing to our{" "}
              <Link
                // onClick={() => setModalContent(<TnC />)}
                to="/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link
                // onClick={() => setModalContent(<PrivacyPolicy />)}
                to="/privacy-and-policy"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Subtitle>

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  loading={isAuthLoading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "70%",
                    marginTop: 20,
                    paddingBlock: 20,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Register
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        <Divider style={{ color: "grey", fontWeight: "normal" }}>OR</Divider>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            loading={isAuthLoading}
            type="default"
            htmlType="submit"
            style={{
              width: "70%",
              fontWeight: "bold",
              paddingBlock: 20,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={toggleUsePhone}
          >
            {`Register with ${isUsePhone ? "email" : "phone number"}`}
          </Button>
        </div>
        <div
          style={{
            display: "block",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          <AuthSubTitleCard>
            {"Already have an account? "}
            <Link to="/login">Login</Link>
          </AuthSubTitleCard>
        </div>
        <AppVersion />
      </Card>
      <Modal
        style={{ minWidth: "60vw" }}
        open={!!modalContent}
        footer={false}
        onCancel={() => setModalContent(null)}
      >
        <div style={{ padding: 20 }}>{modalContent}</div>
      </Modal>
    </AuthLayout>
  );
};

export default Register;

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.smallText};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
