import { Badge } from "antd";
import styled from "styled-components";

type Props = {
  title?: string;
  badgeCount?: number;
};

const HeaderTitleWithBadge = (props: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <HeaderTitle>{props.title}</HeaderTitle>
      <Badge
        count={props.badgeCount}
        style={{
          backgroundColor: "#1890ff",
          fontSize: 14,
          fontWeight: "bold",
          marginLeft: 8,
        }}
      />
    </div>
  );
};

export default HeaderTitleWithBadge;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
