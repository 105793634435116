import { message } from "antd";
import React from "react";
import { ChevronRight, Circle } from "react-feather";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

type Props = {
  color: string;
  text: string;
  totalTasks?: number;
  totalTickets?: number;
  isTicket?: boolean;
  organizationSelected?: string;
  navQuery?: string;
  onClick?: () => void;
};

const ListDashboard = (props: Props) => {
  const navigate = useNavigate();
  const [navigateQuery, setNavigateQuery] = React.useState("");
  const handleNavigationQuery = (input?: string) => {
    if (navigateQuery === "") {
      setNavigateQuery("?organization=" + props.organizationSelected);
    }
    const newNavigateQuery = input
      ? navigateQuery + "&" + input
      : navigateQuery;
    setNavigateQuery(newNavigateQuery);
    console.log("newNavigateQuery", newNavigateQuery);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 3,
      }}
      onClick={() => {
        handleNavigationQuery(props.navQuery);
        navigate(
          props.navQuery
            ? props.navQuery
            : `/dashboard/${props.organizationSelected}`
        );
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Circle
          fill={props.color}
          stroke={props.color}
          size={12}
          style={{ marginRight: 10 }}
        />
        <Text>{props.text}</Text>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.isTicket ? (
          <Text>
            {props.totalTickets ? props.totalTickets + " Tickets" : "No Ticket"}
          </Text>
        ) : (
          <Text>
            {props.totalTasks ? props.totalTasks + " Tasks" : "No Task"}
          </Text>
        )}
        <ChevronRight size={16} style={{ marginLeft: 10 }} />
      </div>
    </div>
  );
};

export default ListDashboard;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
