import { Avatar, Card } from "antd";
import { Download, FileText, Image } from "react-feather";
import styled from "styled-components";

type Props = {
  fileName: string;
  attachmentId?: string;
  iTask?: number;
  url?: string;
};

const TaskAttachment = (props: Props) => {
  const fileType = props?.fileName.split(".").pop()?.toLocaleLowerCase() || "";
  const isImage = (ext: string) => {
    if (ext === "png") {
      return true;
    } else if (ext === "jpg" || ext === "jpeg") {
      return true;
    } else {
      return false;
    }
  };
  const handleView = () => {
    if (
      props?.url &&
      props?.attachmentId &&
      props?.iTask &&
      (isImage(fileType) || fileType === "pdf")
    ) {
      window.open(
        `/my-task/${props.iTask}/attachment/${props.attachmentId}/view`,
        "_blank"
      );
    } else {
      handleDownload();
    }
  };
  const handleDownload = () => {
    if (props?.url) {
      window.location.href = props.url;
    }
  };
  return (
    <div onClick={handleView}>
      <AttachmentCard>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div>
              {isImage(fileType) ? (
                <Avatar
                  style={{
                    backgroundColor: "#3D98E7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  shape="square"
                  icon={<Image />}
                />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#EA6262",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  shape="square"
                  icon={<FileText />}
                />
              )}
            </div>
            <div>
              <Text>{props.fileName}</Text>
            </div>
          </div>
          <div>
            <Download onClick={handleDownload} />
          </div>
        </div>
      </AttachmentCard>
    </div>
  );
};

export default TaskAttachment;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const AttachmentCard = styled(Card)`
  background-color: #e6e9ef;
  box-shadow: none;
  margin-bottom: 5px;
`;
