import { Card } from "antd";
import styled from "styled-components";

const NoAttachment = () => {
  return (
    <Card>
      <div style={{ paddingBlock: 20 }}>
        <TextBold>No Attachment</TextBold>
        <Text>This task has no attachment yet</Text>
      </div>
    </Card>
  );
};

export default NoAttachment;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: center;
  opacity: 0.5;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
  opacity: 0.5;
`;
