import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import styled from "styled-components";
import { Button, Col, Divider, Form, Input, message, Modal, Row } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { BsShieldLock } from "react-icons/bs";
import { InputOTP } from "antd-input-otp";
import { theme } from "../../assets/theme";
import useAuthApp from "../../hooks/useAuthApp";
import { apiDeleteMyAccount } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { apiSignOut } from "../../api/auth";

const Account = () => {
  const auth = useAuthUser();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [form] = Form.useForm();
  const [formMobile] = Form.useForm();
  const [formEmail] = Form.useForm();
  const {
    isAuthLoading,
    setIsAuthLoading,
    doOtpAddEmailPhone,
    doAddEmailPhone,
  } = useAuthApp();
  const [isEdit, setIsEdit] = useState(false);
  const [isShowModal, setIsShowModal] = useState<"phone" | "email" | null>(
    null
  );
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  const [countdown, setCountdown] = useState(59);

  const handlePhoneNumber = (phone: string) => {
    let phoneNumber = phone;
    if (phoneNumber?.startsWith("62")) {
      phoneNumber = phoneNumber.substring(2);
    }
    return phoneNumber;
  };

  const [userPhone, setUserPhone] = useState(
    handlePhoneNumber(auth()?.vMobile)
  );
  const [userEmail, setUserEmail] = useState(auth()?.vEmail);
  const [otp, setOtp] = useState<string[]>();

  const resOtp = async (data: { vEmail?: string; vMobile?: string }) => {
    if (data?.vMobile) {
      data.vMobile = "62" + data.vMobile;
    }
    const res = await doOtpAddEmailPhone(data);
    if (res) {
      setOtp(res.otp?.split(""));
      data?.vEmail ? setIsShowModal("email") : setIsShowModal("phone");
    }
    setIsAuthLoading(false);
  };

  const handleSubmitOtp = async (data: {
    vEmail?: string;
    vMobile?: string;
    otp: string;
  }) => {
    const res = await doAddEmailPhone(data);
    if (res?.data.payload.isSuccess === true) {
      setIsAuthLoading(false);
      setIsShowModal(null);
    }
  };

  const resetCountDown = () => {
    setCountdown(59);
  };

  const handleSave = () => {
    setUserEmail(auth()?.vEmail);
    setUserPhone(handlePhoneNumber(auth()?.vMobile));
    formMobile.setFieldValue("vMobile", auth()?.vMobile);
    formEmail.setFieldValue("vEmail", auth()?.vEmail);
    setIsEdit(false);
  };

  const handleDeleteAccount = async () => {
    const deleteAcc = await apiDeleteMyAccount();
    if (deleteAcc) {
      setIsDeleteAccount(false);
      apiSignOut();
      signOut();
      navigate("/login");
    } else {
      message.error("Cannot Request to Delete Account");
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);

  useEffect(() => {
    form.setFieldValue("otp", otp?.join("") || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          backgroundColor: "white",
          margin: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <BsShieldLock style={{ fontSize: 24 }} />
            <TitleBold>Account</TitleBold>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                isEdit ? handleSave() : setIsEdit(true);
              }}
            >
              {!isEdit ? <EditOutlined /> : <SaveOutlined />}
              {isEdit ? `Save` : `Edit`}
            </Button>
          </div>
        </div>
        <Divider style={{ marginBlock: 0 }} />
        <Form
          form={formMobile}
          onFinish={resOtp}
          initialValues={{ vMobile: userPhone }}
        >
          <Row style={{ gap: 10 }}>
            <Col span={4}>
              <Text>Phone Number</Text>
            </Col>
            <Col span={17}>
              <Form.Item name="vMobile">
                <Input
                  addonBefore={"+62"}
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUserPhone(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Button
                  loading={isAuthLoading}
                  htmlType="submit"
                  type="default"
                  disabled={userPhone === auth()?.vMobile || !isEdit}
                  onClick={() => {
                    resetCountDown();
                  }}
                >
                  Change
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Form form={formEmail} onFinish={resOtp}>
          <Row style={{ gap: 10 }}>
            <Col span={4}>
              <Text>Email</Text>
            </Col>
            <Col span={17}>
              <Form.Item name="vEmail">
                <Input
                  disabled={!isEdit}
                  defaultValue={auth()?.vEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Button
                  loading={isAuthLoading}
                  htmlType="submit"
                  type="default"
                  disabled={userEmail === auth()?.vEmail || !isEdit}
                  onClick={() => {
                    resetCountDown();
                  }}
                >
                  Change
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider style={{ marginBlock: 0 }} />
        <div style={{ width: 100 }}>
          <Button
            onClick={() => setIsDeleteAccount(true)}
            style={{ height: 40 }}
            type="primary"
            danger
          >
            Delete My Account
          </Button>
        </div>
      </div>

      <Modal
        open={isDeleteAccount}
        footer={false}
        onCancel={() => {
          setIsDeleteAccount(false);
        }}
        closeIcon={false}
        width={450}
      >
        <TitleBold style={{ textAlign: "center", marginBlock: 10 }}>
          Delete My Account
        </TitleBold>
        <TextCenter>
          You will lose all of your data by deleting your account. This action
          cannot be undone.
        </TextCenter>
        <div style={{ display: "flex", gap: 10, margin: 20 }}>
          <Button
            type="primary"
            danger
            style={{
              display: "flex",
              height: 40,
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleDeleteAccount}
          >
            <TextBold>Delete</TextBold>
          </Button>
          <Button
            onClick={() => setIsDeleteAccount(false)}
            style={{
              display: "flex",
              height: 40,
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
            type="default"
          >
            <TextBold>Cancel</TextBold>
          </Button>
        </div>
      </Modal>

      <Modal
        open={!!isShowModal}
        footer={false}
        onCancel={() => {
          handleSave();
          setIsShowModal(null);
          setIsAuthLoading(false);
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmitOtp}
          initialValues={{
            vEmail: isShowModal === "email" ? userEmail : "",
            vMobile: isShowModal === "phone" ? userPhone : "",
            otp: otp?.join("") || "",
            otpInput: otp,
          }}
        >
          <div>
            <Text>
              {isShowModal === "email"
                ? "Email Verification"
                : "Phone Number Verification"}
            </Text>
            <Divider style={{ marginTop: 5 }} />
            <div style={{ paddingInline: 60 }}>
              <TextCenter>
                Check your inbox. We've sent you the OTP verification code to{" "}
                <span
                  style={{ fontWeight: "bold", color: theme.colors.secondary }}
                >
                  {isShowModal === "email" ? userEmail : userPhone}
                </span>
              </TextCenter>
            </div>
            <div style={{ marginBlock: 20 }}>
              <Form.Item name="vEmail" hidden />
              <Form.Item name="vMobile" hidden />
              <Form.Item name="otp" hidden>
                <Input value={otp?.join("") || ""} />
              </Form.Item>
              <Form.Item name="otpInput">
                <InputOTP inputType="numeric" onChange={(val) => setOtp(val)} />
              </Form.Item>
            </div>
            <TextCenter>
              Please wait{" "}
              <span style={{ fontWeight: "bold" }}>{`00:${
                countdown < 10 ? "0" + countdown : countdown
              }`}</span>{" "}
              before resend another OTP
            </TextCenter>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() =>
                resOtp({
                  vEmail: isShowModal === "email" ? userEmail : "",
                  vMobile: isShowModal === "phone" ? userPhone : "",
                })
              }
              type="link"
              disabled={countdown > 0}
            >
              Resend OTP
            </Button>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Submit OTP
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Account;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: justify;
  line-height: 2;
`;

const TextCenter = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: center;
  line-height: 2;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
`;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
