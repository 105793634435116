import { Card, Divider, Tabs, Button } from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import TaskHistory from "../../components/MyTask/TaskHistory";
import styled from "styled-components";
import StatusTicket from "../../components/MyTicket/StatusTicket";
import DetailTicket from "../../components/MyTicket/DetailTicket";
import TicketSummary from "../../components/MyTicket/TicketSummary";
import TaskList from "../../components/MyTicket/TaskList";
import { TicketProps, initialCreateTicket } from "../../types/ticket.type";
import useFetchList from "../../hooks/useFetchList";
import { httpRequest } from "../../helpers/api";
import { TaskProps } from "../../types/task.type";
import { useAuthUser } from "react-auth-kit";
import { BugTwoTone, EditOutlined } from "@ant-design/icons";
import { set } from "date-fns";
import {
  BaseResponsePaginationProps,
  BaseResponseProps,
} from "@qlibs/react-components";

const { TabPane } = Tabs;

const MyTicketDetail = () => {

  const auth = useAuthUser();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("1");
  const ticketSelected = window.location.pathname.split("/").pop();
  const [myTicket, setMyTicket] = useState<TicketProps>();
  const [ticketTasks, setTicketTasks] = useState<TaskProps[]>();
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const fetchTicket = async () => {
    try {
      const data = await httpRequest.get<BaseResponseProps<TicketProps>>(
        process.env.REACT_APP_BASE_URL + "/tickets/ipTicket/" + ticketSelected
      );
      const tasks = await httpRequest.get<
        BaseResponsePaginationProps<TicketProps>
      >(
        process.env.REACT_APP_BASE_URL +
          "/tickets/custom/my-tickets?ipTicket=" +
          ticketSelected
      );
      if (data) {
        console.log("data", data);
        console.log("tasks", tasks);
        setMyTicket(data.data.payload);
        setTicketTasks(tasks.data.payload.results[0].tasks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTicket();
  }, [ticketSelected]);

  useEffect(() => {
    let checkStatus = false;
    if (
      myTicket?.iStatus === 1 &&
      myTicket?.userOwner?.iUser === auth()?.iUser
    ) {
      checkStatus = true;
    } else {
      checkStatus = false;
    }
    ticketTasks?.forEach((task: TaskProps) => {
      if (task.iResult !== 1) {
        checkStatus = false;
      }
    });
    console.log("checkStatus", checkStatus);
    setIsEditable(checkStatus);
  }, [myTicket]);

  console.log("myTicket", myTicket, "owner", auth()?.iUser);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} />
          <Title>Detail Ticket</Title>
        </div>
        {myTicket?.iStatus === 1 &&
         myTicket?.userOwner?.iUser === auth()?.iUser &&
        isEditable ? (
          <div>
            <Button 
              type="default"
              style={{
                color: "#045AFF",
              }}
              onClick={() => {
                navigate(`/my-ticket/edit/${myTicket?.ipTicket}`);
              }}
            >
              <EditOutlined />
              Edit Ticket
            </Button>
          </div>
        ) : undefined}
      </div>
      <div style={{ margin: 20 }}>
        <Card style={{ marginBottom: 5 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Text>Status:</Text>
            <StatusTicket iStatus={myTicket?.iStatus} />
          </div>
          <HeaderTitle>{myTicket?.vName}</HeaderTitle>
          <Text>{myTicket?.vDescription}</Text>
        </Card>
        <Card style={{ marginBottom: 5 }}>
          <Tabs
            onChange={(key) => setSelectedTab(key)}
            defaultActiveKey={selectedTab}
          >
            <TabPane tab={<Title>Detail</Title>} key="1">
              <DetailTicket myTicket={myTicket} />
            </TabPane>
            {/* <TabPane tab={<Title>Task List</Title>} key="2">
              {myTicket?.tasks?.map((task) => (
                <div>
                  <TaskList
                    task={task}
                    resolveTimeLimit={myTicket.dLimitResolve}
                  />
                  <Divider />
                </div>
              ))}
            </TabPane> */}
            <TabPane tab={<Title>Ticket History</Title>} key="3">
              <TaskHistory
                isTask={false}
                iTicket={myTicket?.iTicket}
                myTicket={myTicket}
              />
            </TabPane>
          </Tabs>
        </Card>
        {selectedTab === "1" && (
          <Card>
            <TicketSummary
              tasks={ticketTasks}
              resolveTimeLimit={myTicket?.dLimitResolve}
              iTicket={myTicket?.iTicket}
              iStatus={myTicket?.iStatus}
              iUserOwner={myTicket?.userOwner?.iUser}
            />
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};

export default MyTicketDetail;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
