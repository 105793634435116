export interface OrganizationUserProps {
  iOrganization: number;
  iUser: number;
  jDetails: any;
  dUpdatedServer: string;
  dCreatedServer: string;
  organization: {
    iOrganization: number;
    ipOrganization: string;
    iStatus: number;
    vName: string;
    jOrganizationDetails: {
      vCompanyEmail: string;
      vCompanyPhoneNumber: string;
    };
    dCreatedServer: string;
    dUpdatedServer: string;
  };
}
export const initialOrganizationUser: OrganizationUserProps = {
  iOrganization: 0,
  iUser: 0,
  jDetails: {},
  dUpdatedServer: '',
  dCreatedServer: '',
  organization: {
    iOrganization: 0,
    ipOrganization: '',
    iStatus: 0,
    vName: '',
    jOrganizationDetails: {
      vCompanyEmail: '',
      vCompanyPhoneNumber: '',
    },
    dCreatedServer: '',
    dUpdatedServer: '',
  },
};

export interface OrganizationContactProps {
  iOrganization: number;
  ipOrganization: string;
  iStatus: number;
  vName: string;
  jOrganizationDetail: {
    vCompanyEmail: string;
    vCompanyPhoneNumber: string;
  }
  dCreatedServer: Date | string;
  dUpdatedServer: Date | string;
}
