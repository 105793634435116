import React from "react";
import { Avatar, Badge, Card, Col, Divider, Row, Button, message } from "antd";
import styled from "styled-components";
import { AppstoreOutlined } from "@ant-design/icons";
import { ChevronRight, Layers } from "react-feather";
import useFetchList from "../../hooks/useFetchList";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import { httpRequest } from "../../helpers/api";
import useExecutionTime from "../../hooks/useExecutionTime";
import { Timer, Time, TimerOptions } from "timer-node";
import { isDoneStatus } from "../../helpers/isDoneStatus";

type OrganizationData = {
  iOrganization: number;
  ipOrganization: string;
  organization: string;
  myTickets?: number;
  myTasks?: number;
};

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [pageTitle, setPageTitle] = React.useState<string>("");
  const [onceStop, setOnceStop] = React.useState<boolean>(false);
  const { stopTimer } = useExecutionTime();
  const [organizations, setOrganizations] = React.useState<OrganizationData[]>(
    []
  );
  const { data: userOrganizations, fetchList: fetchOrganization } =
    useFetchList<OrganizationUserProps>({
      endpoint: "organization-users",
      pageData: "my-organization-users",
      initialQuery: { iUser: auth()?.iUser },
    });

  const { data: myTickets, fetchList: fetchTicket } = useFetchList<any>({
    apiRequest: httpRequest,
    endpoint: "tickets/custom/my-tickets",
    pageData: "my-tickets",
    initialQuery: {
      limit: 0,
      offset: 0,
    },
  });
  React.useEffect(() => {
    if (
      myTickets.length !== 0 &&
      userOrganizations.length !== 0 &&
      onceStop === false
    ) {
      stopTimer();
      setOnceStop(true);
    }
  }, [myTickets, userOrganizations]);

  React.useEffect(() => {
    fetchTicket();
    fetchOrganization();
  }, []);

  React.useEffect(() => {
    const boolTitle = organizations.length > 1 ? "Projects" : "Project";
    setPageTitle(boolTitle);
    let myTicketCount: { [key: number]: number } = {};
    let myTaskCount: { [key: number]: number } = {};
    let orgTemp: OrganizationData[] = [];
    let personalTicketCount = 0;
    let personalTaskCount = 0;
    myTickets.forEach((ticket) => {
      const allTargets = ticket.tasks.map(
        (task: { iOrganizationTarget: number }) =>
          task.iOrganizationTarget ? task.iOrganizationTarget : 0
      );

      const uniqueTargetsSet = new Set<number>(allTargets);
      const uniqueTargetsArray = Array.from(uniqueTargetsSet);
      for (let i = 0; i < uniqueTargetsArray.length; i++) {
        if (ticket.iStatus !== 4) {
          if (!uniqueTargetsArray[i]) {
            personalTicketCount += 1;
          }
          if (myTicketCount[uniqueTargetsArray[i]]) {
            myTicketCount[uniqueTargetsArray[i]]++;
          } else {
            myTicketCount[uniqueTargetsArray[i]] = 1;
          }
        }
      }
      ticket.tasks.forEach(
        (task: { iOrganizationTarget: number; iResult: number }) => {
          if (!isDoneStatus(task.iResult)) {
            if (!task?.iOrganizationTarget) {
              personalTaskCount += 1;
            }
            if (myTaskCount[task.iOrganizationTarget]) {
              myTaskCount[task.iOrganizationTarget]++;
            } else {
              myTaskCount[task.iOrganizationTarget] = 1;
            }
          }
        }
      );
    });
    orgTemp.push({
      iOrganization: 0,
      ipOrganization: "personal",
      organization: "Personal",
      myTickets: personalTicketCount,
      myTasks: personalTaskCount,
    });

    userOrganizations.map((item) =>
      orgTemp.push({
        iOrganization: item.iOrganization,
        ipOrganization: item.organization.ipOrganization,
        organization: item.organization.vName,
        myTickets: myTicketCount[item.iOrganization],
        myTasks: myTaskCount[item.iOrganization],
      })
    );
    setOrganizations(orgTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOrganizations, myTickets]);
  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginInline: 20,
            marginBottom: 20,
          }}
        >
          <Col span={19}>
            <div>
              <HeaderTitle>{pageTitle}</HeaderTitle>
              <Text>{`You have ${organizations.length} ${pageTitle}`}</Text>
            </div>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{ marginTop: 30, width: "100%", height: "100%" }}
              onClick={() => navigate("/my-ticket/create")}
            >
              + Create Ticket
            </Button>
          </Col>
        </Row>
        <Row>
          {organizations.map((item) => (
            <Col span={12}>
              <Card
                style={{ marginRight: 20, marginBottom: 20, cursor: "pointer" }}
                bordered={true}
              >
                <Title
                  onClick={() => navigate(`/dashboard/${item.ipOrganization}`)}
                >
                  {item.organization}
                </Title>
                <Divider style={{ marginBlock: 20 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                  onClick={() =>
                    navigate("/my-task?organization=" + item.iOrganization)
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Badge>
                      <Avatar
                        shape="square"
                        size={40}
                        style={{
                          color: "#045AFF",
                          backgroundColor: "#E9F5FF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        icon={<Layers size={25} />}
                      />
                    </Badge>
                    <SubTitle style={{ marginLeft: 15 }}>My Task</SubTitle>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SubTitle>{`${
                      item.myTasks ? item.myTasks : "No"
                    } Tasks`}</SubTitle>
                    <ChevronRight size={25} style={{ marginLeft: 10 }} />
                  </div>
                </div>
                <Divider style={{ marginBlock: 20 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate("/my-ticket?organization=" + item.iOrganization)
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Badge>
                      <Avatar
                        shape="square"
                        size={40}
                        style={{
                          color: "#FFC72D",
                          backgroundColor: "#FEF0C9",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        icon={<AppstoreOutlined size={25} />}
                      />
                    </Badge>
                    <SubTitle style={{ marginLeft: 15 }}>My Ticket</SubTitle>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SubTitle>{`${
                      item.myTickets ? item.myTickets : "No"
                    } Tickets`}</SubTitle>
                    <ChevronRight size={25} style={{ marginLeft: 10 }} />
                  </div>
                </div>
                <Divider style={{ marginBlock: 20 }} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-color: ${({ theme }) => theme.colors.gray_light2};
`;

const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
