import { message } from "antd";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { SubmitBodyProps, initialSubmitBody } from "../types/task.type";
import { TicketProps } from "../types/ticket.type";

const initialState: {
    draftSubmit: {
        ipTask: String;
        draft: SubmitBodyProps;
        files?: any[];
    }[];
    attachTicket: TicketProps[];
} = {
    draftSubmit: [{
        ipTask: "",
        draft: initialSubmitBody,
        files: []
    }],
    attachTicket: []
};

const { useGlobalState } = createGlobalState(initialState);

export default function useDraft() {
    const [draftSubmit, setDraftSubmit] = useGlobalState("draftSubmit");
    const [attachTicket, setAttachTicket] = useGlobalState("attachTicket");

    useEffect(() => {}, [draftSubmit]);

    const appendDraft = (ipTask: String, draft: SubmitBodyProps, files: any[]) => {
        const newDraft = draftSubmit.find((item) => item.ipTask === ipTask);
        if (newDraft) {
            updateDraft(ipTask, draft, files);
            return;
        } else {
        setDraftSubmit([...draftSubmit, { ipTask, draft, files }]); 
        localStorage.setItem("draftSubmit", JSON.stringify([...draftSubmit, { ipTask, draft, files }]));
        message.success("New draft saved");
        }
    }

    const updateDraft = (ipTask: String, draft: SubmitBodyProps, files: any[]) => {
        const newDraft = draftSubmit.map((item) => {
            if (item.ipTask === ipTask) {
                return { ipTask, draft, files }; 
            }
            return item;
        });
        setDraftSubmit(newDraft);
        localStorage.setItem("draftSubmit", JSON.stringify(newDraft));
    }

    const getDraft = (ipTask: string) => { 
        const draft = draftSubmit.find((item) => item.ipTask === ipTask);
        return draft ? draft : null;
     }
    const removeDraft = (iTask: number) => { /* Function body unchanged */ }

    return { appendDraft, getDraft, removeDraft, updateDraft, draftSubmit, attachTicket, setAttachTicket };
}
