import { Divider, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Info } from "react-feather";
import { FaFacebookF, FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa";
import styled from "styled-components";
import { apiGetAboutUs } from "../../api/appConfig";
import StringToMarkup from "../../components/More/StringToMarkup";

const AboutUs = () => {
  const logoBase64 = "./logo-blue.png";

  const [value, setValue] = useState("");
  const fetchValue = async () => {
    const res = await apiGetAboutUs();
    setValue(res);
  };

  useEffect(() => {
    fetchValue();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "60vw",
        backgroundColor: "white",
        margin: "20px",
        padding: 40,
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Info size={24} />
        <TextBold>About Us</TextBold>
      </div>
      <Divider style={{ marginTop: 15 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <img
            src={logoBase64}
            alt="Logo"
            style={{
              width: "50px",
              height: "50px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10%",
            }}
          />

          <TextBold>{process.env.REACT_APP_WEBSITE_NAME}</TextBold>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 20,
          }}
        >
          <FaFacebookF style={{ fontSize: "24px", cursor: "pointer" }} />
          <FaTwitter style={{ fontSize: "24px", cursor: "pointer" }} />
          <FaInstagram style={{ fontSize: "24px", cursor: "pointer" }} />
          <FaTiktok style={{ fontSize: "24px", cursor: "pointer" }} />
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        {value ? <StringToMarkup text={value} /> : <Spin />}
      </div>
      <p style={{ textAlign: "justify", color: "grey" }}>
        {`Web Version ${process.env.REACT_APP_VERSION_NAME}`}
      </p>
    </div>
  );
};

export default AboutUs;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
