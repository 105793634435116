import React, { useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  message,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  InfoCircleOutlined,
  SettingOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  LinkOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  InboxOutlined,
  FileTwoTone,
} from "@ant-design/icons";
import { Circle } from "react-feather";
import { theme } from "../../assets/theme";
import dayjs from "dayjs";
import en from "antd/es/date-picker/locale/en_US";
import { httpRequest } from "../../helpers/api";
import type { UploadProps } from "antd";
import useTaskList from "../../hooks/useTaskList";
import {
  MyContactProps,
  OrganizationContact,
} from "../../types/myContact.type";
import useFetchList from "../../hooks/useFetchList";
import {
  OrganizationUserProps,
  initialOrganizationUser,
} from "../../types/organizationUser.type";
import { apiGetRelayOrganization } from "../../api/relay";
import { useAuthUser } from "react-auth-kit";
import { ISLAProps, initialSLA } from "../../types/ticket.type";
import { ContactProps } from "../../types/contact.type";

interface TaskListProps {
  dataNumber: number;
  iUser: number;
  isEdit: boolean;
  resolveTaskLimit: String | Date;
  responseTaskLimit: String | Date;
  // Other props if TaskList component accepts any
}
const { Dragger } = Upload;

const TaskList: React.FC<TaskListProps> = ({
  dataNumber,
  iUser,
  isEdit,
  resolveTaskLimit,
  responseTaskLimit,
}) => {
  const {
    taskItems,
    handleRemoveTask,
    updateTask,
    ticketDate,
    selectedHour,
    setSelectedHour,
    resolveDate,
    setIsEdited,
    deletedAttachment,
    setDeletedAttachment,
  } = useTaskList();
  const { Text } = Typography;
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [iUserSelected, setIUserSelected] = React.useState(iUser);
  const [iOrganizationSelected, setIOrganizationSelected] =
    React.useState<number>();
  const [contactList, setContactList] = React.useState<MyContactProps>();
  const [selectResponse, setSelectResponse] = React.useState<string>(
    dayjs(taskItems[dataNumber]?.dLimitResponse).format("YYYY-MM-DD | HH:mm")
  );
  const [selectResolve, setSelectResolve] = React.useState<string>(
    dayjs(taskItems[dataNumber]?.dLimitResolve).format("YYYY-MM-DD | HH:mm")
  );
  const [datePickerResponseValue, setDatePickerResponseValue] =
    React.useState<any>(null);
  const [datePickerResolveValue, setDatePickerResolveValue] =
    React.useState<any>(null);
  const [userContactOrg, setUserContactOrg] = React.useState<
    OrganizationContact[]
  >([]);
  const [taskResponse, setTaskResponse] = React.useState<string>("");
  const [isResolveValid, setIsResolveValid] = React.useState(false);
  const [isResolveDisable, setIsResolveDisable] = React.useState(false);
  const auth = useAuthUser();

  // const { data: slaData , fetchList:fetchSLA} = useFetchList<ISLAProps>({
  //   endpoint: "sla_levels",
  // });
  // console.log("slaData", slaData);
  const slaData = initialSLA;

  const { data: myOrganizations, fetchList: fetchMyOrganization } =
    useFetchList<OrganizationUserProps>({
      endpoint: "organization-users",
      pageData: "my-organization-users",
      initialQuery: { iUser: auth()?.iUser },
    });

  const buddhistLocale: typeof en = {
    ...en,
    lang: {
      ...en.lang,
      dateFormat: "YYYY-MM-DD",
      dateTimeFormat: "YYYY-MM-DD HH:mm",
      yearFormat: "YYYY",
    },
  };
  useEffect(() => {
    fetchMyOrganization();
  }, [userContactOrg]);

  const handleDeleteFileList = (index: number) => {
    setIsEdited(true);
    setDeletedAttachment([
      ...deletedAttachment,
      {
        iTask: taskItems[dataNumber].iTask,
        iAttachment: fileList[index].id,
      },
    ]);
    let newFileList = fileList;
    newFileList.splice(index, 1);
    setFileList(newFileList);
    updateTask(dataNumber, {
      ...taskItems[dataNumber],
      jAttachment: newFileList,
    });
    message.success("File removed");
    return;
  };

  const organizationByRelayId = async (iRelay: number) => {
    const res = await apiGetRelayOrganization(iRelay);
    setIOrganizationSelected(res);
  };

  const fetchContactList = async () => {
    try {
      const response = await httpRequest.get("/my-contacts");
      if (response) {
        setContactList(response.data.payload);
        console.log("contactList", response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContactList();
  }, []);

  useEffect(() => {
    setSelectResolve(
      dayjs(taskItems[dataNumber]?.dLimitResolve).format("YYYY-MM-DD | HH:mm")
    );
  }, [taskItems[dataNumber]?.dLimitResponse]);

  useEffect(() => {
    setSelectResponse(
      dayjs(taskItems[dataNumber]?.dLimitResponse).format("YYYY-MM-DD | HH:mm")
    );
  }, [taskItems[dataNumber]?.dLimitResponse]);

  useEffect(() => {
    if (fileList?.length === 0) {
      fetchAttachment();
    }
  }, [taskItems[dataNumber]]);

  const fetchAttachment = () => {
    const files: any[] = [];
    taskItems[dataNumber]?.jAttachment?.files?.map((item: any) => {
      const file = {
        id: item?.iAttachment,
        name: item?.vRawData?.fileLinkCache?.split("/")?.pop(),
        status: "done",
        url: item?.vRawData?.fileLinkCache,
      };
      files.push(file);
    });
    setFileList(files);
  };

  const handleTimeChange = (
    e: dayjs.Dayjs | null,
    timeString: string,
    isResponse: boolean
  ) => {
    const formatDate = e?.toISOString()
      ? e?.toISOString()
      : dayjs().toISOString();
    if (isResponse) {
      setSelectResponse(timeString);
      setIsResolveDisable(true);
      updateTask(dataNumber, {
        ...taskItems[dataNumber],
        dLimitResponse: formatDate,
      });
    } else {
      if (timeString < selectResponse) {
        message.error("Resolve time must be greater than response time");
        setSelectResolve("");
        return;
      } else {
        setSelectResolve(timeString);
        updateTask(dataNumber, {
          ...taskItems[dataNumber],
          dLimitResolve: formatDate,
        });
      }
    }
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current: dayjs.Dayjs | null): boolean => {
    if (!current) return false;
    const stringResponse = responseTaskLimit?.toString();

    const response = responseTaskLimit
      ? dayjs(stringResponse).startOf("day")
      : dayjs().startOf("day");

    return (
      current.startOf("day").isBefore(dayjs().startOf("day")) ||
      current.startOf("day").isAfter(response)
    );
  };
  const disabledResolveDate = (current: dayjs.Dayjs | null): boolean => {
    const stringdate = responseTaskLimit?.toString();
    const stringResponse = resolveTaskLimit?.toString();
    // const parsedDate = dayjs(stringdate, "YYYY-MM-DD   |   HH:mm");
    // const responseDate = parsedDate?.toISOString();
    if (!current) return false;

    const response = responseTaskLimit
      ? dayjs(stringResponse).startOf("day")
      : dayjs().startOf("day");
    const resolve = resolveTaskLimit
      ? dayjs(stringdate).startOf("day")
      : dayjs().startOf("day");

    return (
      current.startOf("day").isBefore(resolve) ||
      current.startOf("day").isAfter(response)
    );
  };
  const disabledTime = () => {
    const minTime = new Date(ticketDate);
    const maxTime = new Date(resolveDate);

    const minDate = minTime.getDate();
    const maxDate = maxTime.getDate();
    const minHour = minTime.getHours();
    const minMinute = minTime.getMinutes();
    const maxHour = maxTime.getHours();
    const maxMinute = maxTime.getMinutes();

    const hoursRange = range(0, 24);
    return {
      disabledHours: () => {
        if (selectedHour === minDate) {
          return hoursRange.filter(
            (hour) => !range(0, 24).splice(minHour, 24).includes(hour)
          );
        } else if (selectedHour === maxDate) {
          return hoursRange.filter(
            (hour) => !range(0, 24).splice(0, maxHour).includes(hour)
          );
        } else {
          return [];
        }
      },
      disabledMinutes: () => {
        if (selectedHour === minHour) {
          // If the selected hour is equal to the minimum hour, disable minutes before minMinute
          return range(0, 60).filter((minute) => minute < minMinute);
        } else if (selectedHour === maxHour) {
          // If the selected hour is equal to the maximum hour, disable minutes after maxMinute
          return range(0, 60).filter((minute) => minute > maxMinute);
        }
        // For other hours, no minutes are disabled
        return [];
      },
    };
  };

  const displayUserOrganization = (iUser: number, isRelay: boolean) => {
    const defaultOrg = [initialOrganizationUser];
    if (isRelay) {
      const UserOrg = contactList?.relay
        ?.filter((item) => item.relay[0].iRelay === iUser)
        ?.map((item) => item.relay[0].organization);
      if (UserOrg) {
        setUserContactOrg(UserOrg);
      }
    } else {
      const UserOrg = contactList?.users
        ?.filter(
          (item) =>
            item.user.iUser === iUser && item.organization?.[0] !== undefined
        )
        ?.map((item) => item.organization?.[0]);
      console.log("UserOrg user", UserOrg);
      if (UserOrg === undefined) {
        setUserContactOrg([]);
      }
      if (UserOrg) {
        setUserContactOrg(UserOrg);
      }
    }
  };

  const props: UploadProps = {
    multiple: true,
    fileList: fileList,
    onChange(info) {
      if (info.file.size && info.file.size > 2000000) {
        message.config({ maxCount: 1 });
        message.error("File size must be less than 10MB");
        return;
      } else {
        setFileList(info.fileList);
        updateTask(dataNumber, {
          ...taskItems[dataNumber],
          jAttachment: info.fileList,
        });
      }
    },
  };
  const handleFileChange = (info: any) => {
    setIsEdited(true);
    updateTask(dataNumber, {
      ...taskItems[dataNumber],
      jAttachment: info.fileList,
    });
  };

  useEffect(() => {
    setDatePickerResolveValue(null);
    setDatePickerResponseValue(null);
    setSelectResponse("");
    setSelectResolve("");
  }, [ticketDate, resolveDate]);

  useEffect(() => {}, [
    fileList,
    taskItems,
    datePickerResponseValue,
    datePickerResolveValue,
    taskResponse,
  ]);
  return (
    <>
      <Col
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "24px",
          gap: 40,
          marginTop: 10,
        }}
      >
        {dataNumber !== 0 && (
          <Row
            justify={"end"}
            style={{
              alignItems: "end",
              justifyItems: "end",
              textAlign: "end",
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              onClick={() => {
                handleRemoveTask(dataNumber);
              }}
            >
              <Text>
                <DeleteOutlined style={{ color: "red" }} /> Delete Task
              </Text>
            </Button>
          </Row>
        )}
        <Row justify={"space-between"}>
          <Col span={11}>
            <Row style={{ marginBottom: 10 }}>
              <Text style={{ color: "black", fontSize: 20 }}>
                <InfoCircleOutlined
                  style={{ fontSize: 20, color: "#B8BFCD", margin: 3 }}
                />{" "}
                Task Information
              </Text>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Text style={{ color: "gray" }}>
                Task Name <Text style={{ color: "red" }}>*</Text>
              </Text>
              <Input
                style={{ marginTop: 10 }}
                placeholder="Task Name"
                value={taskItems[dataNumber]?.vName}
                onChange={(e) => {
                  setIsEdited(true);
                  updateTask(dataNumber, {
                    ...taskItems[dataNumber],
                    vName: e.target.value,
                  });
                }}
              />
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Text style={{ color: "gray" }}>
                Task Description <Text style={{ color: "red" }}>*</Text>
              </Text>
              <TextArea
                style={{ marginTop: 10 }}
                placeholder="Task Description"
                value={taskItems[dataNumber]?.vDescription}
                onChange={(e) => {
                  setIsEdited(true);
                  updateTask(dataNumber, {
                    ...taskItems[dataNumber],
                    vDescription: e.target.value,
                  });
                }}
              />
            </Row>
          </Col>
          <Col span={11}>
            <Row style={{ marginBottom: 10 }}>
              <Text style={{ color: "black", fontSize: 20 }}>
                <SettingOutlined
                  style={{ fontSize: 20, color: "#B8BFCD", margin: 3 }}
                />{" "}
                Task Setting
              </Text>
            </Row>
            <Row style={{ marginTop: 10 }} justify={"space-between"}>
              <Col span={11}>
                <Text style={{ color: "gray" }}>
                  SLA Level <Text style={{ color: "red" }}>*</Text>
                </Text>
                <Select
                  style={{ marginTop: 10 }}
                  allowClear
                  placeholder="Status"
                  value={taskItems[dataNumber]?.jSLA?.level}
                  onChange={(value) => {
                    const slaSelect = slaData.find(
                      (item) => item.vLevel === value
                    );
                    updateTask(dataNumber, {
                      ...taskItems[dataNumber],
                      jSLA: slaSelect
                        ? {
                            name: slaSelect.vName,
                            level: slaSelect.vLevel,
                            description: slaSelect.vDescription,
                            maxResolveTimeInHours:
                              slaSelect.iMaxResolveTimeinHours,
                            maxResponseTimeInHours:
                              slaSelect.iMaxResponseTimeinHours,
                          }
                        : undefined,
                    });
                    setIsEdited(true);
                  }}
                >
                  <Select.Option value="CRITICAL">
                    <WarningOutlined style={{ color: "red", marginRight: 8 }} />
                    Critical
                  </Select.Option>
                  <Select.Option value="HIGH">
                    <ExclamationCircleOutlined
                      style={{ color: theme.colors.warning, marginRight: 8 }}
                    />
                    High
                  </Select.Option>
                  <Select.Option value="NORMAL">
                    <Circle
                      color={theme.colors.warning}
                      fill={theme.colors.warning}
                      size={10}
                      style={{ marginRight: 8 }}
                    />
                    Normal
                  </Select.Option>
                  <Select.Option value="LOW">
                    <Circle
                      color={theme.colors.green500}
                      fill={theme.colors.green500}
                      size={10}
                      style={{ marginRight: 8 }}
                    />
                    Low
                  </Select.Option>
                </Select>
              </Col>
              <Col span={11}>
                <Text style={{ color: "gray", fontSize: 12 }}>
                  Response Type <Text style={{ color: "red" }}>*</Text>
                </Text>
                <Select
                  style={{ marginTop: 10 }}
                  allowClear
                  value={taskItems[dataNumber]?.iResponseType}
                  // suffixIcon
                  onChange={(value) => {
                    updateTask(dataNumber, {
                      ...taskItems[dataNumber],
                      iResponseType: value,
                    });
                    setIsEdited(true);
                  }}
                  placeholder={
                    <>
                      <CheckCircleOutlined /> Choose Response Type
                    </>
                  }
                >
                  <Select.Option value={1}>Submit Task</Select.Option>
                  <Select.Option value={2}>Yes/No Task</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} justify={"space-between"}>
              <Col span={11}>
                <Row style={{ marginTop: 5 }}>
                  <Text style={{ color: "gray", fontSize: 12 }}>
                    Response Time <Text style={{ color: "red" }}>*</Text>
                  </Text>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <DatePicker
                    placeholder="Pick Response time"
                    showTime
                    disabledDate={disabledDate}
                    // disabledTime={disabledTime}
                    style={{ width: "100%" }}
                    format={"YYYY-MM-DD   |   HH:mm"}
                    locale={buddhistLocale}
                    value={
                      isEdit
                        ? dayjs(taskItems[dataNumber]?.dLimitResponse)
                        : datePickerResponseValue
                    }
                    onChange={(e, timeString) => {
                      const isoString =
                        e?.toISOString() ||
                        datePickerResponseValue?.toISOString();
                      const selectedDate = new Date(isoString);

                      if (selectedDate < new Date()) {
                        message.error(
                          "Response time must be greater than current time"
                        );
                        setSelectResponse("");
                        setDatePickerResponseValue(null);
                        return;
                      }
                      if (
                        selectedDate >
                        (responseTaskLimit
                          ? new Date(responseTaskLimit?.toString())
                          : new Date(ticketDate))
                      ) {
                        message.error(
                          "Valid response time is before the ticket`s response time"
                        );
                        setSelectResponse("");
                        setDatePickerResponseValue(null);
                        return;
                      } else {
                        const selectedDate = e ? e.date() : 1;
                        handleTimeChange(e, timeString, true);
                        setSelectedHour(selectedDate);
                        setTaskResponse(timeString);
                        setIsResolveValid(timeString > selectResolve);
                        setDatePickerResponseValue(e);
                      }
                      setIsEdited(true);
                    }}
                  />
                  {/* <TimePicker style={{width: '100%'}} defaultValue={dayjs('12:08', format)} format={format} onChange={(e, timeString) => handleTimeChange(e, timeString, true)}/> */}
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Text style={{ color: "gray", fontSize: 12 }}>
                    Response time limit{" "}
                    <b>{selectResponse ? selectResponse : ""}</b>
                  </Text>
                </Row>
              </Col>
              <Col span={11}>
                <Row style={{ marginTop: 5 }}>
                  <Text style={{ color: "gray", fontSize: 12 }}>
                    Resolve Time <Text style={{ color: "red" }}>*</Text>
                  </Text>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <DatePicker
                    disabled={isEdit ? false : !isResolveDisable}
                    disabledDate={disabledResolveDate}
                    // disabledTime={disabledTime}
                    placeholder="Pick Resolve time"
                    showTime
                    style={{
                      width: "100%",
                      borderColor: isResolveValid ? "red" : "",
                    }}
                    format={"YYYY-MM-DD   |   HH:mm"}
                    locale={buddhistLocale}
                    value={
                      isEdit
                        ? dayjs(taskItems[dataNumber]?.dLimitResolve)
                        : datePickerResolveValue
                    }
                    onChange={(e, timeString) => {
                      const isoString =
                        e?.toISOString() ||
                        datePickerResponseValue?.toISOString();
                      const selectedDate = new Date(isoString);
                      if (timeString < selectResponse) {
                        message.error(
                          "Resolve time must be greater than response time"
                        );
                        setSelectResolve("");
                        setDatePickerResolveValue(null);
                        return;
                      }
                      if (
                        selectedDate >
                        (responseTaskLimit
                          ? new Date(resolveTaskLimit?.toString())
                          : new Date(resolveDate))
                      ) {
                        message.error(
                          "Resolve time must be greater than task`s response time"
                        );
                        setSelectResolve("");
                        setDatePickerResolveValue(null);
                        return;
                      } else {
                        handleTimeChange(e, timeString, false);
                        setSelectedHour(e?.date() || 0);
                        setIsResolveValid(timeString < selectResponse);
                        setDatePickerResolveValue(e);
                      }
                      setIsEdited(true);
                    }}
                  />
                  {/* <TimePicker style={{width: '100%'}} defaultValue={dayjs('12:08', format)} format={format} onChange={(e, timeString) => handleTimeChange(e, timeString, true)}/> */}
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Text style={{ color: "gray", fontSize: 12 }}>
                    Resolve time limit{" "}
                    <b>{selectResolve ? selectResolve : ""}</b>
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} justify={"space-between"}>
              <Col span={11}>
                <Text style={{ color: "gray", fontSize: 12 }}>
                  Assignee <Text style={{ color: "red" }}>*</Text>
                </Text>
                <Select
                  style={{ marginTop: 10 }}
                  value={taskItems[dataNumber]?.metaUser?.vNameUserTarget}
                  // suffixIcon
                  placeholder={
                    <>
                      <UserOutlined /> Choose Assignee
                    </>
                  }
                  onChange={(value) => {
                    // setUserOrganizations([]);
                    if (value[0] === "r") {
                      displayUserOrganization(parseInt(value.slice(1)), true);
                      updateTask(dataNumber, {
                        ...taskItems[dataNumber],
                        iOrganizationTarget: undefined,
                        iRelayTarget: parseInt(value.slice(1)),
                        iUserTarget: 0,
                      });
                      organizationByRelayId(parseInt(value.slice(1)));
                    } else if (value[0] === "u") {
                      displayUserOrganization(parseInt(value.slice(1)), false);
                      updateTask(dataNumber, {
                        ...taskItems[dataNumber],
                        iOrganizationTarget: undefined,
                        iUserTarget: parseInt(value.slice(1)),
                        iRelayTarget: 0,
                      });
                      setIUserSelected(parseInt(value.slice(1)));
                    }
                    setIsEdited(true);
                  }}
                >
                  {contactList?.relay?.map((item, itemIndex) => (
                    // Using a unique key for the outer map
                    <React.Fragment key={itemIndex}>
                      {item?.relay?.map((contact, contactIndex) => (
                        // Using a unique key for the inner map
                        <Select.Option
                          key={`${contactIndex}`}
                          value={"r" + contact.iRelay}
                        >
                          {contact.vName}
                        </Select.Option>
                      ))}
                    </React.Fragment>
                  ))}
                  {contactList?.users?.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                      <Select.Option
                        key={`${item.user.iUser}`}
                        value={"u" + item.user.iUser}
                      >
                        {item.user.vFirstName + " " + item.user.vLastName}
                      </Select.Option>
                    </React.Fragment>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <Text style={{ color: "gray", fontSize: 12 }}>
                  Organization
                </Text>
                <Select
                  style={{ marginTop: 10 }}
                  allowClear
                  // value={taskItems[dataNumber]?.iOrganizationTarget}
                  // disabled={organizationLoading}
                  onChange={(value) => {
                    updateTask(dataNumber, {
                      ...taskItems[dataNumber],
                      iOrganizationTarget: value,
                    });
                    setIsEdited(true);
                  }}
                  value={
                    taskItems[dataNumber]?.iOrganizationTarget
                      ? taskItems[dataNumber]?.iOrganizationTarget
                      : taskItems[dataNumber]?.iOrganizationTarget === null
                      ? 0
                      : undefined
                  }
                  // suffixIcon
                  placeholder={
                    <>
                      <CheckCircleOutlined /> Choose Organization
                    </>
                  }
                >
                  {userContactOrg?.map(
                    (item, index) =>
                      myOrganizations.find(
                        (itemChild) =>
                          itemChild.iOrganization === item.iOrganization &&
                          item.iOrganization !== undefined
                      ) && (
                        <Select.Option key={index} value={item.iOrganization}>
                          {item.vName}
                        </Select.Option>
                      )
                  )}
                  <Select.Option value={0}>Personal</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }} justify={"space-between"}>
              <Col>
                <Text style={{ color: "black", fontSize: 16 }}>
                  <LinkOutlined style={{ color: "grey", fontSize: 16 }} />{" "}
                  Attachment
                </Text>
              </Col>
              <Col>
                <Upload
                  showUploadList={false}
                  onChange={handleFileChange}
                  {...props}
                >
                  <Button
                    onClick={() => setIsEdited(true)}
                    icon={<CloudUploadOutlined />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {fileList ? (
                  fileList?.length > 0 ? (
                    <>
                      {fileList?.map((item, index) => {
                        return (
                          <Row
                            key={index}
                            style={{
                              marginTop: 10,
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%", // Ensure each row takes up full width
                            }}
                          >
                            <div>
                              <FileTwoTone />
                              <Text style={{ color: "grey", marginLeft: 10 }}>
                                {item.name}
                              </Text>
                            </div>
                            <Button onClick={() => handleDeleteFileList(index)}>
                              <DeleteOutlined style={{ color: "red" }} />
                            </Button>
                          </Row>
                        );
                      })}
                      <Dragger
                        showUploadList={false}
                        style={{
                          width: "100%",
                          height: "15vh",
                          border: "solid",
                          borderRadius: 5,
                          borderColor: "#E6E9EF",
                          alignItems: "start",
                          justifyContent: "start",
                          marginTop: 20,
                          display: "flex",
                          flexDirection: "column", // Set flexDirection to "column"
                          textAlign: "center",
                        }}
                        {...props}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <Text style={{ fontSize: 14, color: "#A0A0A0" }}>
                          Click or drag file to this area to upload
                        </Text>
                      </Dragger>
                    </>
                  ) : (
                    <Dragger
                      style={{
                        width: "100%",
                        height: "15vh",
                        border: "solid",
                        borderRadius: 5,
                        borderColor: "#E6E9EF",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 20,
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                      {...props}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          color: "#757575",
                        }}
                      >
                        No Attachment
                      </Text>
                      <br />
                      <Text style={{ fontSize: 14, color: "#A0A0A0" }}>
                        This task has no attachment yet
                      </Text>
                      <br />
                      <Text style={{ fontSize: 14, color: "#A0A0A0" }}>
                        Click or drag file to this area to upload
                      </Text>
                    </Dragger>
                  )
                ) : (
                  <Dragger
                    style={{
                      width: "100%",
                      height: "15vh",
                      border: "solid",
                      borderRadius: 5,
                      borderColor: "#E6E9EF",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                    {...props}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#757575",
                      }}
                    >
                      No Attachment
                    </Text>
                    <br />
                    <Text style={{ fontSize: 14, color: "#A0A0A0" }}>
                      This task has no attachment yet
                    </Text>
                    <br />
                    <Text style={{ fontSize: 14, color: "#A0A0A0" }}>
                      Click or drag file to this area to upload
                    </Text>
                  </Dragger>
                )}
              </Col>
            </Row>
            <Row></Row>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginTop: 20 }} justify={"space-between"}>
          <Col span={11}>
            <Row style={{ marginTop: "auto" }}>
              <Text style={{ color: "gray", fontSize: 14 }}>Created at</Text>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Text
                style={{ color: "black", fontSize: 16, fontWeight: "bold" }}
              >
                {dayjs(taskItems[dataNumber]?.dCreatedLocal).format(
                  "DD MMM YYYY"
                )}
              </Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default TaskList;
