import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import TicketSummaryList from "./TicketSummaryList";
import { TaskProps } from "../../types/task.type";
import { Button, message, Modal } from "antd";
import {
  apiCloseOpenTicket,
  apiCloseTicket,
  apiReOpenTicket,
} from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

type Props = {
  tasks?: TaskProps[];
  resolveTimeLimit?: Date | string;
  iTicket?: number;
  iStatus?: number;
  iUserOwner?: number;
};

const TicketSummary = (props: Props) => {
  const navigate = useNavigate();
  const auth = useAuthUser();

  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const [confirmationReOpen, setConfirmationReOpen] = React.useState(false);

  const handleCloseTicket = async (iTicket: number) => {
    if (props.iStatus === 1 || props.iStatus === 2) {
      const res = await apiCloseOpenTicket(iTicket);
      if (res) {
        message.success("Ticket status has been updated");
        navigate("/my-ticket");
      }
    } else if (props.iStatus === 3) {
      const res = await apiCloseTicket(iTicket);
      if (res) {
        message.success("Ticket has been closed");
        navigate("/my-ticket");
      }
    }
  };

  const handleOpenTicket = async (iTicket: number) => {
    if (props.iStatus === 3) {
      const res = await apiReOpenTicket(iTicket);
      if (res) {
        message.success("Ticket has been open");
        navigate("/my-ticket");
      }
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBlock: 20,
          gap: 10,
        }}
      >
        <div>
          <InfoCircleOutlined style={{ fontSize: "24px" }} />
        </div>
        <div>
          <TitleBold>Ticket Summary</TitleBold>
        </div>
      </div>
      <TicketSummaryList
        tasks={props.tasks}
        resolveTimeLimit={props.resolveTimeLimit}
      />
      {props.iStatus !== 4 && props.iUserOwner === auth()?.iUser && (
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            type="primary"
            style={{ fontSize: 20, margin: 20, height: 50 }}
            onClick={() => props.iTicket && setConfirmationModal(true)}
          >
            {props.iStatus === 2 ? "Approve Close" : "Close Ticket"}
          </Button>

          {props.iStatus === 3 && (
            <Button
              type="default"
              style={{ fontSize: 20, marginBlock: 20, height: 50 }}
              onClick={() => props.iTicket && setConfirmationReOpen(true)}
            >
              Re-open
            </Button>
          )}
        </div>
      )}
      <Modal
        title={`Close Ticket`}
        open={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        width={350}
        footer={false}
      >
        <>
          <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            {`Are you sure you want to `}
            <strong>close</strong>
            {` this ticket?`}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 5,
              marginTop: 10,
            }}
          >
            <Button
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 20,
                alignItems: "center",
              }}
              onClick={() => setConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 20,
                alignItems: "center",
              }}
              onClick={() => {
                props.iTicket && handleCloseTicket(props.iTicket);
              }}
            >
              Yes
            </Button>
          </div>
        </>
      </Modal>

      <Modal
        title={`Re-open Ticket`}
        open={confirmationReOpen}
        onCancel={() => setConfirmationReOpen(false)}
        width={350}
        footer={false}
      >
        <>
          <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            {`Are you sure you want to `}
            <strong>re-open</strong>
            {` this ticket?`}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 5,
              marginTop: 10,
            }}
          >
            <Button
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 20,
                alignItems: "center",
              }}
              onClick={() => setConfirmationReOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 20,
                alignItems: "center",
              }}
              onClick={() => {
                props.iTicket && handleOpenTicket(props.iTicket);
              }}
            >
              Yes
            </Button>
          </div>
        </>
      </Modal>
    </React.Fragment>
  );
};

export default TicketSummary;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
