import { httpRequest } from "../helpers/api";
import UseActionQueue from "../hooks/useActionQueue";
import { addMyTask, addRelayTask } from "../hooks/useOfflineData";
import { BaseResponseProps } from "../types/config.type";
import { TaskProps } from "../types/task.type";

export async function apiGetAttachmentUrl(data: {
  iTask: number;
  ipAttachment: string;
}) {
  try {
    let url = "";
    const res = await httpRequest.get<
      BaseResponseProps<{
        jAttachment: any;
        jAttachmentSubmission: any;
        jAttachmentReturn: any;
      }>
    >(process.env.REACT_APP_BASE_URL + "/tasks/" + data.iTask);
    if (res) {
      res?.data?.payload?.jAttachment?.files?.map(
        (item: {
          ipAttachment: string;
          vRawData: { fileLinkCache: string };
        }) => {
          if (item.ipAttachment === data.ipAttachment) {
            url = item.vRawData.fileLinkCache;
          }
        }
      );

      res?.data?.payload?.jAttachmentSubmission?.files?.map(
        (item: {
          ipAttachment: string;
          vRawData: { fileLinkCache: string };
        }) => {
          if (item.ipAttachment === data.ipAttachment) {
            url = item.vRawData.fileLinkCache;
          }
        }
      );

      res?.data?.payload?.jAttachmentReturn?.files?.map(
        (item: {
          ipAttachment: string;
          vRawData: { fileLinkCache: string };
        }) => {
          if (item.ipAttachment === data.ipAttachment) {
            url = item.vRawData.fileLinkCache;
          }
        }
      );
    }
    return url;
  } catch (err) {
    return "";
  }
}

export async function apiGetTicketData(iTask: number) {
  try {
    const res = await httpRequest.get<BaseResponseProps<TaskProps>>(
      process.env.REACT_APP_BASE_URL + "/tasks/" + iTask
    );
    if (res) {
      return res.data.payload;
    }
    return {};
  } catch (err) {
    return {};
  }
}

export async function apiSyncMyTask() {
  try {
    const res = await httpRequest.get<
      BaseResponseProps<{ results: TaskProps[] }>
    >(process.env.REACT_APP_BASE_URL + "/tasks/custom/my-tasks");
    if (res) {
      res.data.payload.results.forEach((element: TaskProps) =>
        addMyTask(element)
      );
    }
  } catch (err) {}
}

export async function apiSyncRelayTasks() {
  try {
    const relayTask = await httpRequest.get<
      BaseResponseProps<{ results: TaskProps[] }>
    >(process.env.REACT_APP_BASE_URL + "/tasks/custom/optimize/relay-tasks");
    if (relayTask) {
      relayTask.data.payload.results.forEach((element: TaskProps) => {
        addRelayTask(element);
      });
    }
  } catch (err) {}
}

export async function apiGetOneTask(ipTask: string) {
  try {
    const res = await httpRequest.get<BaseResponseProps<TaskProps>>(
      process.env.REACT_APP_BASE_URL + "/tasks/ipTask/" + ipTask
    );
    if (res) {
      return res.data.payload;
    }
  } catch (err) {
    console.error(err);
  }
}
