import { Button, Card, Modal, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft, CornerUpRight } from "react-feather";
import styled from "styled-components";
import DetailTask from "../../components/MyTask/DetailTask";
import TaskHistory from "../../components/MyTask/TaskHistory";
import { useNavigate } from "react-router-dom";
import StatusTask from "../../components/MyTask/StatusTask";
import { TaskProps } from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "@qlibs/react-components";
import { useAuthUser } from "react-auth-kit";
import SubmissionResult from "../../components/MyTask/SubmissionResult";
import { EditOutlined } from "@ant-design/icons";
import { getIpTask } from "../../hooks/useOfflineData";
import { get } from "http";
const { TabPane } = Tabs;

const MyTaskDetail = () => {
  const auth = useAuthUser();
  const navigate = useNavigate();
  const taskSelected = window.location.pathname.split("/").pop();
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");
  const [submitType, setSubmitType] = useState("");
  const [myTask, setMyTask] = useState<TaskProps>();
  const offlineTask = getIpTask(taskSelected?taskSelected:"");

  const handleModal = () => {
    setShowModal(true);
  };

  const handleReturn = async (taskId: string) => {
    navigate(`/my-task/${taskId}/return`);
  };

  const handleAccept = async (taskId: number) => {
    await httpRequest.post<BaseResponseProps<{}>>(
      process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/approve",
      { vNote: "", dUpdatedLocal: new Date(), dResponse: new Date() }
    );
    navigate("/my-task");
  };

  const handleReassign = (ipTask: string) => {
    navigate(`/my-task/${ipTask}/reassign`);
  };

  const handleReject = async (taskId: number) => {
    await httpRequest.post<BaseResponseProps<{}>>(
      process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/reject",
      { dUpdatedLocal: new Date(), dResolve: new Date() }
    );
    navigate("/my-task");
  };

  const handleSubmit = async (taskId: number, type?: number) => {
    try {
      await httpRequest.post<BaseResponseProps<{}>>(
        process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/submit",
        {
          vNote: "",
          dUpdatedLocal: new Date(),
          iResult: type === 622 ? 22 : type === 621 ? 21 : 9,
          dResolve: new Date(),
        }
      );
      message.success("Task submitted successfully");
      navigate("/my-task");
    } catch (error) {
      message.error("Failed to submit task");
    }
  };

  const handleReview = async (taskId: number, type?: number) => {
    try {
      await httpRequest.post<BaseResponseProps<{}>>(
        process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/review",
        {
          vNote: "",
          dUpdatedLocal: new Date(),
          iReviewTask: type,
          dResolve: new Date(),
        }
      );
      message.success("Task submitted successfully");
      navigate("/my-task");
    } catch (error) {
      message.error("Failed to submit task");
    }
  };
  useEffect(() => {}, [myTask, auth()]);

  const getMyTask = async () => {
    try {
      const foundTask = await httpRequest.get<BaseResponseProps<TaskProps>>(
        process.env.REACT_APP_BASE_URL + "/tasks/ipTask/" + taskSelected
      );
      console.log("foundTask data", foundTask);
      if (foundTask) {
        setMyTask(foundTask.data.payload);
        console.log("foundTask", foundTask);
      }
    } catch (error) {
      setMyTask(getIpTask(taskSelected?taskSelected:""));
    }
  };
  useEffect(() => {
    getMyTask();
  }, [taskSelected]);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            width: "100%",
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} />
          <Title>Detail Task</Title>
        </div>
        {myTask?.iResult === 1 &&
        myTask?.userCreate?.iUser === auth()?.iUser ? (
          <div>
            <Button
              type="default"
              style={{
                color: "#045AFF",
              }}
              onClick={() => {
                navigate(`/my-task/${myTask?.ipTask}/edit`);
              }}
            >
              <EditOutlined />
              Edit Task
            </Button>
          </div>
        ) : undefined}
        {myTask?.iResult === 1 &&
        myTask?.userCreate?.iUser !== auth()?.iUser ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            <Button
              danger
              onClick={() => {
                setType("Reject");
                handleModal();
              }}
            >
              Reject
            </Button>
            <Button
              style={{ color: "white", backgroundColor: "green" }}
              onClick={() => {
                setType("Accept");
                handleModal();
              }}
            >
              Accept
            </Button>
          </div>
        ) : (myTask?.iResult === 621 ||
            myTask?.iResult === 622 ||
            myTask?.iResult === 69) &&
            myTask?.userCreate.iUser === auth()?.iUser ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            <Button
              danger
              onClick={() => {
                setType("Return");
                handleModal();
              }}
            >
              Return Task
            </Button>

            <Button
              type="primary"
              style={{ color: "white" }}
              onClick={() => {
                setType("Approve");
                handleModal();
              }}
            >
              Approve Task
            </Button>
          </div>
        ) : (myTask?.iResult === 2 || myTask?.iResult === 7) &&
          (myTask?.iResponseType === 1 ||
            myTask?.histories?.[0]?.jData?.iResponseType === 1) &&
          myTask?.userCreate.iUser !== auth()?.iUser ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            <Button
              type="primary"
              icon={<CornerUpRight size={16} fontWeight={"bold"} />}
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                setType("Forward");
                handleModal();
              }}
            >
              Forward Task
            </Button>
            <Button
              onClick={() =>
                navigate(`/my-task/${myTask?.ipTask}/create-new-sub-task`)
              }
              type="default"
            >
              Add New Task
            </Button>
            <Button
              onClick={() => {
                navigate(`/my-task/submit/${myTask?.ipTask}`);
                // setType("Submit");
                // handleModal();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        ) : (myTask?.iResult === 2 || myTask?.iResult === 7) &&
          (myTask?.iResponseType === 2 ||
            myTask?.histories?.[0]?.jData?.iResponseType === 2) &&
          myTask?.userCreate.iUser !== auth()?.iUser ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <Button
                style={{
                  color: "red",
                  borderColor: "red",
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                onClick={() => {
                  setSubmitType("No");
                  setType("Save");
                  setShowModal(true);
                }}
                type="default"
              >
                No
              </Button>
              <Button
                type="primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                onClick={() => {
                  setSubmitType("Yes");
                  setType("Save");
                  setShowModal(true);
                }}
              >
                Yes
              </Button>
            </div>
          </>
        ) : myTask?.iResult === 3 &&
          myTask?.userCreate?.iUser === auth()?.iUser &&
          (myTask?.dLimitResponse
            ? new Date(myTask.dLimitResponse) > new Date()
            : true) ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            <Button
              type="primary"
              icon={<CornerUpRight size={16} fontWeight={"bold"} />}
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                setType("Reassign");
                handleModal();
              }}
            >
              Reassign Task
            </Button>
          </div>
        ) : undefined}
      </div>
      <div style={{ margin: 20 }}>
        <Card style={{ marginBottom: 5 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Text>Status:</Text>
            <StatusTask iStatus={myTask?.iResult} />
          </div>
          <HeaderTitle>{myTask?.vName}</HeaderTitle>
          <Text>{myTask?.vDescription}</Text>
        </Card>
        <Card style={{ marginBottom: 5 }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab={<Title>Detail</Title>} key="1">
              <DetailTask myTask={myTask} />
            </TabPane>
            <TabPane tab={<Title>Task History</Title>} key="2">
              <TaskHistory myTask={myTask} isTask={true} />
            </TabPane>
          </Tabs>
        </Card>
        <SubmissionResult myTask={myTask} />
      </div>

      <Modal
        title={`${type} Task`}
        open={showModal}
        onOk={() => {
          setShowModal(false);
          if (type === "Reject") {
            myTask && handleReject(myTask.iTask);
          } else if (type === "Accept") {
            myTask && handleAccept(myTask.iTask);
          } else if (type === "Forward") navigate("/my-task/" + myTask?.ipTask);
        }}
        onCancel={() => setShowModal(false)}
        width={350}
        footer={false}
      >
        {type !== "Submit" ? (
          <>
            <div
              style={{ textAlign: "center" }}
            >{`Are you sure want to ${type} this task?`}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 5,
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  padding: 20,
                  alignItems: "center",
                }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  padding: 20,
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowModal(false);
                  if (type === "Reject") {
                    myTask && handleReject(myTask.iTask);
                  } else if (type === "Return") {
                    myTask && handleReturn(myTask.ipTask);
                  } else if (type === "Approve") {
                    myTask && handleSubmit(myTask.iTask, myTask.iResult);
                  } else if (type === "Accept") {
                    myTask && handleAccept(myTask.iTask);
                  } else if (type === "Reassign") {
                    myTask && handleReassign(myTask.ipTask);
                  } else if (type === "Save" && submitType === "Yes") {
                    myTask && handleReview(myTask.iTask, 621);
                  } else if (type === "Save" && submitType === "No") {
                    myTask && handleReview(myTask.iTask, 622);
                  } else {
                    navigate("/my-task/forward/" + myTask?.ipTask);
                  }
                }}
              >
                Yes
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default MyTaskDetail;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
