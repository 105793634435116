import { TaskHistoryProps } from "./taskHistory.type";
import { jSLAProps } from "./ticket.type";

export enum iResponseType {
  "SUBMIT" = 1,
  "YES_NO" = 2,
}

export enum iResult {
  "WAITING_APPROVAL" = 1,
  "APPROVED" = 2,
  "REJECTED" = 3,
  "IN_REVIEW_YES" = 621,
  "IN_REVIEW_NO" = 622,
  "IN_REVIEW_SUBMIT" = 69,
  "RETURNED" = 7,
  "DONE" = 9,
  "MISSED_SCHEDULE" = 10,
  "FORWARDED" = 8,
  "YES" = 21,
  "NO" = 22,
}

export interface TaskProps {
  iTask: number;
  ipTask: string;
  vName: string;
  iTicket: number;
  dCreatedLocal: Date | string;
  dCreatedServer: Date | string;
  iUserCreate?: number;
  iRelayCreate?: number;
  vDescription?: string;
  jAttachment?: any;
  vNote?: string;
  vNoteToText?: string;
  iResult?: iResult | number;
  jSLA?: jSLAProps;
  iParentTask?: number;
  iUserTarget?: number;
  iRelayTarget?: number;
  organization?: {
    vName: string;
  };
  iOrganizationTarget?: number;
  histories?: TaskHistoryProps[];
  iType?: number;
  iResponseType?: iResponseType;
  dUpdatedLocal: Date | string;
  dUpdatedServer: Date | string;
  dReadLocal?: Date | string;
  dReadServer?: Date | string;
  metaUser?: any;
  iForwardedFromTask?: number;
  dLimitResponse: Date | string;
  dLimitResolve: Date | string;
  dResponse?: Date | string;
  dResolve?: Date | string;
  due?: number;
  userCreate?: any;
  userTarget?: any;
  relayCreate?: any;
  relayTarget?: any;
  ticket?: any;
  isMissSchedule?: boolean;
}

export interface CreateTaskProps {
  vName: string;
  iTicket: number;
  iTask?: number;
  dCreatedLocal: Date | string;
  dUpdatedLocal: Date | string;
  vDescription?: string;
  vNote?: string;
  vNoteToText?: string;
  iRelayCreate?: number;
  iParentTask?: number;
  iUserTarget?: number;
  iOrganizationTarget?: number;
  iRelayTarget?: number;
  iResponseType?: iResponseType | number;
  jSLA?: jSLAProps;
  metaUser?: any;
  dLimitResponse: Date | string;
  dLimitResolve: Date | string;
  jAttachment?: any;
}

export interface ForwardBodyProps {
  dUpdatedLocal: Date | string;
  forwardto: {
    iUserTarget: number;
    iOrganizationTarget: number;
    iRelayTarget: number;
  };
  vNote: string;
  dResolve: Date | string;
}

export interface SubmitBodyProps {
  vNote: string;
  dCreatedLocal: Date | string;
  iResult: iResult;
  dResolve: Date | string;
}
export const initialSubmitBody: SubmitBodyProps = {
  vNote: "",
  dCreatedLocal: new Date(),
  iResult: iResult.APPROVED,
  dResolve: new Date(),
};

export const initialForwardBody: ForwardBodyProps = {
  dUpdatedLocal: new Date(),
  forwardto: {
    iUserTarget: 0,
    iOrganizationTarget: 0,
    iRelayTarget: 0,
  },
  vNote: "",
  dResolve: new Date(),
};

export const initialCreateTask: CreateTaskProps = {
  vName: "",
  iTicket: 0,
  dCreatedLocal: new Date(),
  dUpdatedLocal: new Date(),
  vDescription: "",
  vNote: "",
  vNoteToText: "",
  iResponseType: undefined,
  iRelayCreate: undefined,
  iParentTask: 0,
  iUserTarget: 0,
  iRelayTarget: 0,
  dLimitResponse: "",
  dLimitResolve: "",
  jAttachment: undefined,
};

export interface AttachmentFile {
  iTask: number;
  iType: number;
  iTicket: number;
  dCreated: string; // Assuming this is a date string in ISO 8601 format
  vRawData: {
    fileLinkCache: string;
    fileCacheTimeout: string; // Assuming this is a date string in ISO 8601 format
  };
  dTaskDone: string | null; // Assuming this is a date string in ISO 8601 format or null
  iAttachment: number;
  ipAttachment: string;
}

export interface Attachment {
  files: AttachmentFile[];
  tickets: any[]; // Assuming tickets have their own structure, it's left as an empty array for now
}
