import { Divider, Spin } from "antd";
import { useEffect, useState } from "react";
import { BsShieldExclamation } from "react-icons/bs";
import styled from "styled-components";
import { apiGetTermsAndConditions } from "../../api/appConfig";
import StringToMarkup from "./StringToMarkup";

const TnC = () => {
  const [value, setValue] = useState("");
  const fetchValue = async () => {
    const res = await apiGetTermsAndConditions();
    setValue(res);
  };

  useEffect(() => {
    fetchValue();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <BsShieldExclamation style={{ fontSize: 24 }} />
        <TitleBold>Terms & Conditions</TitleBold>
      </div>
      <Divider />
      {value ? <StringToMarkup text={value} /> : <Spin />}
    </div>
  );
};

export default TnC;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: justify;
  line-height: 2;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
