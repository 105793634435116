import { Button, Card, Modal, Tabs, message, Row, Upload, Col } from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft, CornerUpRight } from "react-feather";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StatusTask from "../../components/MyTask/StatusTask";
import { TaskProps } from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "@qlibs/react-components";
import {
  FileTextOutlined,
  LinkOutlined,
  CloudUploadOutlined,
  AlignLeftOutlined,
  InboxOutlined,
  DeleteOutlined,
  FileTwoTone,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { UploadProps } from "antd/lib";
import { apiGetOneTask } from "../../api/task";
const { Dragger } = Upload;

const MyTaskReturn = () => {
  const navigate = useNavigate();
  const pathParts = window.location.pathname.split("/");
  const taskSelected = pathParts[pathParts.indexOf("my-task") + 1];
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");
  const [vNote, setVNote] = useState<string>("");
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [myTask, setMyTask] = useState<TaskProps>();
  // const { data: myTasks } = useFetchList<TaskProps>({
  //   endpoint: "tasks/custom/my-tasks",
  // });
  // console.log("myTasks", myTasks);

  const handleModal = () => {
    setShowModal(true);
  };
  const handleReturn = async (taskId: number) => {
    navigate(`/my-task/${taskId}/return`);
  };

  const handleApprove = async (taskId: number) => {
    await httpRequest.post<BaseResponseProps<{}>>(
      process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/approve",
      { vNote: "", dCreatedLocal: new Date(), dResolve: new Date() }
    );
    navigate("/my-task");
  };

  const handleReject = async (taskId: number) => {
    await httpRequest.post<BaseResponseProps<{}>>(
      process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/reject",
      { dCreatedLocal: new Date(), dResolve: new Date() }
    );
    navigate("/my-task");
  };

  const handleSubmit = async (taskId: number) => {
    try {
      await httpRequest.post<BaseResponseProps<{}>>(
        process.env.REACT_APP_BASE_URL + "/tasks/" + taskId + "/submit",
        {
          vNote: "",
          dCreatedLocal: new Date(),
          iResult: 9,
          dResolve: new Date(),
        }
      );
      message.success("Task submitted successfully");
      navigate("/my-task");
    } catch (error) {
      console.log("error", error);
      message.error("Failed to submit task");
    }
  };

  const handleDeleteFileList = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleReturnTask = async (taskId: number) => {
    try {
      if (fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach((file: any) => {
          formData.append("files", file.originFileObj);
        });
        const uploadFile = await httpRequest.put(
          `/tasks/${myTask?.iTask}/return/attachments`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (uploadFile) {
          message.success("File attached successfully");
        }
      }
      const returnTask = await httpRequest.post<TaskProps>(
        `/tasks/${taskId}/return-task`,
        {
          vNote: vNote,
        }
      );
      if (returnTask) {
        message.success("Task returned successfully");
        navigate("/my-task");
      }
    } catch (error) {
      console.log("error", error);
      message.error("Failed to return task");
    }
  };

  const props: UploadProps = {
    onChange(info) {
      const { file } = info;
      if (file.size && file.size > 2000000) {
        message.config({ maxCount: 1 });
        message.error("File size must be less than 10MB");
      } else {
        const isFileInList = fileList.some((item) => item.uid === file.uid);
        if (!isFileInList) {
          setFileList([...fileList, file]);
        }
        console.log("info.fileList", fileList);
      }
    },
  };

  useEffect(() => {
    // const foundTask = myTasks.find((item) => item.ipTask === taskSelected);
    // setMyTask(foundTask);
    if (taskSelected) {
      const getOne = async () => {
        const resTask = await apiGetOneTask(taskSelected);
        if (resTask) {
          setMyTask(resTask);
        }
      };
      getOne();
    }
  }, [taskSelected]);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate("/my-task")} />
          <Title>Return Task</Title>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <Button
            onClick={() => {
              setType("Return");
              handleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ color: "white" }}
            onClick={() => {
              setType("Submit");
              handleModal();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        <Card style={{ marginBottom: 5 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Text>Status:</Text>
            <StatusTask iStatus={myTask?.iResult} />
          </div>
          <HeaderTitle>{myTask?.vName}</HeaderTitle>
          <Text>{myTask?.vDescription}</Text>
        </Card>
        <Card>
          <Row>
            <Text style={{ fontSize: 16, marginBottom: 5 }}>
              <FileTextOutlined style={{ color: "gray" }} /> Note{" "}
              <span style={{ color: "red" }}>*</span>
            </Text>
            <TextArea
              onChange={(e) => {
                setVNote(e.target.value);
              }}
              rows={4}
              style={{ width: "100%" }}
              placeholder="Write return task note here"
            />
          </Row>
        </Card>
        <Card>
          <Row style={{ marginBottom: 5 }}>
            <Text style={{ fontSize: 16, marginBottom: 5 }}>
              <LinkOutlined style={{ color: "gray" }} /> Attachments
            </Text>
          </Row>
          <Row style={{ marginBottom: 5 }}>
            {fileList.length > 0 ? (
              <>
                {fileList.map((item, index) => {
                  return (
                    <Row
                      key={index}
                      style={{
                        marginTop: 10,
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%", // Ensure each row takes up full width
                      }}
                    >
                      <Text style={{ color: "grey" }}>
                        <FileTwoTone /> {item.name}
                      </Text>
                      <Button onClick={() => handleDeleteFileList(index)}>
                        <DeleteOutlined style={{ color: "red" }} />
                      </Button>
                    </Row>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Row>
          <Row style={{ marginBottom: 5 }}>
            <Upload {...props} fileList={fileList} showUploadList={false}>
              <Button type="primary">
                <CloudUploadOutlined /> Attach File
              </Button>
            </Upload>
          </Row>
          <Dragger
            style={{ height: "50%", width: "100%" }}
            {...props}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from
              uploading company data or other banned files.
            </p>
          </Dragger>
        </Card>
      </div>

      <Modal
        title={`Return Task`}
        open={showModal}
        onOk={() => {
          setShowModal(false);
          if (type === "Submit") {
            myTask && handleReturnTask(myTask.iTask);
          } else {
            navigate("/my-task");
          }
        }}
        onCancel={() => setShowModal(false)}
        width={350}
        footer={false}
      >
        <div
          style={{ textAlign: "center" }}
        >{`Are you sure want to return this task?`}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 5,
            marginTop: 10,
          }}
        >
          <Button
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              padding: 20,
              alignItems: "center",
            }}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              padding: 20,
              alignItems: "center",
            }}
            onClick={() => {
              setShowModal(false);
              if (type === "Submit") {
                myTask && handleReturnTask(myTask.iTask);
              } else if (type === "Reject") {
                myTask && handleReject(myTask.iTask);
              } else if (type === "Return") {
                myTask && handleApprove(myTask.iTask);
              } else if (type === "Approve") {
                myTask && handleApprove(myTask.iTask);
              } else {
                navigate("/my-task/forward/" + myTask?.ipTask);
              }
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MyTaskReturn;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
