import styled from "styled-components";
import HeaderSection from "../../components/HeaderSection";
import { Button, Calendar, Modal } from "antd";
import type { CalendarProps } from "antd";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Circle } from "react-feather";
import { TaskProps } from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import { httpRequest } from "../../helpers/api";
import { apiGetResolveTimeLimit } from "../../api/ticket";
import { useNavigate } from "react-router-dom";

type EventCalendar = { ipTask: string; date: Dayjs; content: string };

const CalendarComponent = (props: { iOrganization: number }) => {
  const navigate = useNavigate();
  const [date, setDate] = React.useState(dayjs());
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [day, setDay] = React.useState("");
  const [dayNumber, setDayNumber] = React.useState(0);
  const [myTasksData, setMyTasksData] = React.useState<TaskProps[]>([]);
  const [eventSelected, setEventSelected] = useState<EventCalendar[]>([]);
  const [resolveTimeLimit, setResolveTimeLimit] = useState<{
    [key: number]: Date | string | undefined;
  }>({});

  const { data: myTasks, fetchList } = useFetchList<TaskProps>({
    apiRequest: httpRequest,
    endpoint: "tasks/custom/optimize/my-tasks",
    initialQuery: {
      limit: 0,
      offset: 0,
      iOrganization: props.iOrganization,
    },
  });

  useEffect(() => {
    setMyTasksData(myTasks?.filter((item)=> item.iResult !== 3 && item.iResult !== 10));
  }, [myTasks, resolveTimeLimit]);

  useEffect(() => {
    fetchList();
    fetchResolveTimeLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.iOrganization]);

  const fetchResolveTimeLimit = async () => {
    const res = await apiGetResolveTimeLimit();
    if (res) {
      setResolveTimeLimit(res);
    }
  };

  const getListData = (value: Dayjs) => {
    let listData: EventCalendar[] = [];

    myTasksData?.map(
      (item) =>
        (item?.dLimitResolve || resolveTimeLimit[item.iTicket]) &&
        item?.vName &&
        value.isSame(
          dayjs(item?.dLimitResolve || resolveTimeLimit[item.iTicket]),
          "day"
        ) &&
        listData.push({
          ipTask: item.ipTask,
          date: dayjs(item?.dLimitResolve || resolveTimeLimit[item.iTicket]),
          content: item?.vName || "",
        })
    );

    listData.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    return listData;
  };

  const getMonthData = (value: Dayjs) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <div className="events">
        {listData &&
          listData.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div style={{ flexShrink: 0 }}>
                <Circle size={10} fill="#3D98E7" stroke="#3D98E7" />
              </div>
              <TextSmall>
                {item.date.format("HH:mm")}{" "}
                <span style={{ fontWeight: "bold" }}>{item.content}</span>
              </TextSmall>
            </div>
          ))}
      </div>
    );
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const handleModal = (day: string, daysInMonth: number, value?: Dayjs) => {
    if (value) {
      const eventData = getListData(value);
      if (eventData) {
        setEventSelected(eventData);
      }
    }
    setIsModalOpen(true);
    setDay(day);
    setDayNumber(daysInMonth);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        <HeaderSection title="Schedule" />
        <div style={{ marginRight: 5, marginBottom: 40 }}>
          <Calendar
            mode="month"
            cellRender={cellRender}
            value={date}
            onSelect={(data, info) =>
              handleModal(data.format("ddd"), data.date(), data)
            }
            onPanelChange={(date) => setDate(date.add(0, "months"))}
            headerRender={() => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                    padding: 20,
                  }}
                >
                  <Button onClick={() => setDate(date.add(-1, "months"))}>
                    <LeftOutlined />
                  </Button>
                  <Button onClick={() => setDate(date.add(1, "months"))}>
                    <RightOutlined />
                  </Button>
                  <Text>{date.format("MMMM YYYY")}</Text>
                </div>
              );
            }}
          />
        </div>
      </div>
      <Modal
        width={300}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Title>{day}</Title>
          <HeadingTitle>{dayNumber}</HeadingTitle>
          <div style={{ textAlign: "left" }}>
            {eventSelected?.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  marginTop: 10,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/my-task/" + item.ipTask)}
              >
                <div style={{ flexShrink: 0 }}>
                  <Circle size={10} fill="#3D98E7" stroke="#3D98E7" />
                </div>
                <TextBody
                  onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.7")}
                  onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
                >
                  {item.date.format("HH:mm")}{" "}
                  <span style={{ fontWeight: "bold" }}>{item.content}</span>
                </TextBody>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CalendarComponent;

const HeadingTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  opacity: 0.6;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  opacity: 0.6;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextSmall = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
