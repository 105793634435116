import {
  RightOutlined,
  MoreOutlined,
  EditOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import { CheckCircle, Clipboard, Eye, Layers } from "react-feather";
import { Link } from "react-router-dom";
import { Avatar, Dropdown, MenuProps, Space, Typography, message } from "antd";
import { Card, Divider } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { apiCloseTicket } from "../../api/ticket";
import { useAuthUser } from "react-auth-kit";

interface MyTicketCardProps {
  title: string;
  description: string;
  inProgress: number;
  needApproval: number;
  ipTicket: string;
  inReview: number;
  done: number;
  iTicket: number;
  iStatus: number;
  iUserOwner: number;
}
const { Title } = Typography;
const MyTicketCard: React.FC<MyTicketCardProps> = ({
  title,
  description,
  inProgress,
  ipTicket,
  needApproval,
  inReview,
  done,
  iTicket,
  iStatus,
  iUserOwner,
}) => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCloseTicket = async (iTicket: number) => {
    const res = await apiCloseTicket(iTicket);
    if (res) {
      message.success("Ticket has been closed");
      navigate("/my-ticket");
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Edit Ticket",
      key: "1",
      icon: <EditOutlined />,
      onClick: () => {
        navigate(`/my-ticket/edit/${ipTicket}`);
        setIsDropdownOpen(false);
      },
      disabled:
        inProgress > 0 ||
        done > 0 ||
        needApproval === 0 ||
        iUserOwner !== auth()?.iUser ||
        iStatus === 4,
    },
    {
      label: "Close Ticket",
      key: "2",
      icon: <CloseSquareOutlined />,
      onClick: () => {
        handleCloseTicket(iTicket);
        setIsDropdownOpen(false);
      },
      style: {
        color: "red",
        opacity: iStatus === 4 || iUserOwner !== auth()?.iUser ? 0.3 : 1,
      },
      disabled: iStatus === 4 || iUserOwner !== auth()?.iUser,
    },
  ];

  const handleCardClick = () => {
    if (!isDropdownOpen) {
      navigate(`/my-ticket/${ipTicket}`);
    }
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };
  return (
    <Card style={{ marginBlock: 5, cursor:'pointer'}}>
      <div onClick={handleCardClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title style={{ color: "black" }} level={4}>
            {title}
          </Title>
          <div>
            {/* <Dropdown
              menu={{ items }}
              open={isDropdownOpen}
              onOpenChange={(e) => handleDropdownVisibleChange(e)}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <MoreOutlined style={{ fontSize: 20 }} />
                </Space>
              </a>
            </Dropdown> */}
          </div>
        </div>
        <Text style={{ fontSize: 16, color: "#6F7887" }}>{description}</Text>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#C8930B",
                backgroundColor: "#FFF5D8",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<Clipboard size={25} />}
            />
            <Text style={{ marginLeft: 10, fontWeight: "semibold" }}>
              Need Approval
            </Text>
          </div>
          <Link
            to={`/#`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                marginLeft: 10,
                marginRight: 5,
                fontWeight: "semibold",
                color: "#6F7887",
              }}
            >
              {needApproval} Task
            </Text>
            <RightOutlined style={{ fontSize: "20px", color: "#E6E9EF" }} />
          </Link>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#045AFF",
                backgroundColor: "#E9F5FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<Layers size={25} />}
            />
            <Text style={{ marginLeft: 10, fontWeight: "semibold" }}>
              In Progress
            </Text>
          </div>
          <Link
            to={`/#`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                marginLeft: 10,
                marginRight: 5,
                fontWeight: "semibold",
                color: "#6F7887",
              }}
            >
              {inProgress} Task
            </Text>
            <RightOutlined style={{ fontSize: "20px", color: "#E6E9EF" }} />
          </Link>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#A14DD6",
                backgroundColor: "#F0D8FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<Eye size={25} />}
            />
            <Text style={{ marginLeft: 10, fontWeight: "semibold" }}>
              In Review
            </Text>
          </div>
          <Link
            to={`/#`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                marginLeft: 10,
                marginRight: 5,
                fontWeight: "semibold",
                color: "#6F7887",
              }}
            >
              {inReview} Task
            </Text>
            <RightOutlined style={{ fontSize: "20px", color: "#E6E9EF" }} />
          </Link>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#50D831",
                backgroundColor: "#E3FDDD",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<CheckCircle size={25} />}
            />
            <Text style={{ marginLeft: 10, fontWeight: "semibold" }}>Done</Text>
          </div>
          <Link
            to={`/#`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                marginLeft: 10,
                marginRight: 5,
                fontWeight: "semibold",
                color: "#6F7887",
              }}
            >
              {done} Task
            </Text>
            <RightOutlined style={{ fontSize: "20px", color: "#E6E9EF" }} />
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default MyTicketCard;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0px 10px;
  display: inline-block;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
