import { TaskProps } from "./task.type";
import { TaskHistoryProps } from "./taskHistory.type";

export enum iStatusTicket {
  "OPEN" = 1,
  "NEED_APPROVAL_FOR_COMPLETE" = 2,
  "CLOSED_OPEN" = 3,
  "CLOSED" = 4,
}

export interface jSLAProps {
  name: string;
  level: string;
  description: string;
  maxResolveTimeInHours: number;
  maxResponseTimeInHours: number;
}

export interface ISLAProps {
  iSLA: number;
  vLevel: string;
  vName: string;
  vDescription: string;
  iMaxResponseTimeinHours: number;
  iMaxResolveTimeinHours: number;
  dUpdatedServer: Date | string;
  dCreatedServer: Date | string;
}

export const initialSLA: ISLAProps[] = [
  {
    iSLA: 1,
    vLevel: "CRITICAL",
    vName: "Critical",
    vDescription: "This task is important and urgent so must be done",
    iMaxResponseTimeinHours: 1,
    iMaxResolveTimeinHours: 4,
    dUpdatedServer: "2023-05-10T08:15:47.407Z",
    dCreatedServer: "2023-05-10T08:15:47.407Z"
  },
  {
    iSLA: 2,
    vLevel: "HIGH",
    vName: "High",
    vDescription: "This task is urgent and must be done",
    iMaxResponseTimeinHours: 1,
    iMaxResolveTimeinHours: 12,
    dUpdatedServer: "2023-05-10T08:15:47.407Z",
    dCreatedServer: "2023-05-10T08:15:47.407Z"
  },
  {
    iSLA: 3,
    vLevel: "NORMAL",
    vName: "Normal",
    vDescription: "This task is important and must be done",
    iMaxResponseTimeinHours: 2,
    iMaxResolveTimeinHours: 48,
    dUpdatedServer: "2023-05-10T08:15:47.407Z",
    dCreatedServer: "2023-05-10T08:15:47.407Z"
  },
  {
    iSLA: 4,
    vLevel: "LOW",
    vName: "Low",
    vDescription: "Tasks is regular and not too hasty but must be done",
    iMaxResponseTimeinHours: 6,
    iMaxResolveTimeinHours: 120,
    dUpdatedServer: "2023-05-10T08:15:47.407Z",
    dCreatedServer: "2023-05-10T08:15:47.407Z"
  }
];

export interface TicketProps {
  iTicket: number;
  ipTicket: string;
  iStatus: iStatusTicket;
  iUserOwner: number;
  jSLA: jSLAProps;
  dCreatedLocal: Date | string;
  dCreatedServer: Date | string;
  vName: string;
  histories?: TaskHistoryProps[];
  vDescription: string;
  dLimitResponse?: Date | string;
  dLimitResolve?: Date | string;
  dResponse?: Date | string;
  dResolve?: Date | string;
  dReadLocal?: Date | string;
  dReadServer?: Date | string;
  userOwner: any;
  tasks?: TaskProps[];
}

export interface TicketSubmitResponse {
  data: {
    code: string;
    message: string;
    payload: {
      vName: string;
      vDescription: string;
      jFinalResult: JSON | null;
      ipTicket: string;
      iUserOwner: number;
      iTicket: string;
      istatus: iStatusTicket;
      ifinalResult: number;
      dUpdatedserver: string;
      dUpdatedlocal: string;
      dResponse: string;
      dResolve: string;
      dReadLocal: string | null;
      dReadServer: string | null;
      dLimitResponse: string;
      dLimitResolve: string;
      dCreatedServer: string;
      dCreatedLocal: string;
    };
  };
}

export interface CreateTicketProps {
  vName: string;
  vDescription?: string;
  dCreatedLocal: Date | string;
  dUpdatedLocal: Date | string;
  dLimitResponse: Date | string;
  dLimitResolve: Date | string;
  jHistoryTask?: JSON | null;
  dResponse?: Date | string;
  dResolve?: Date | string;
  iFinalResult?: number;
  jFinalResult?: JSON | null;
  autoCreateTask?: boolean;
  jSLA?: jSLAProps | null;
}

export const initialCreateTicket: CreateTicketProps = {
  vName: "",
  vDescription: "",
  dCreatedLocal: new Date(),
  dUpdatedLocal: new Date(),
  dLimitResponse: new Date(),
  dLimitResolve: new Date(),
  jHistoryTask: null,
  dResponse: new Date(),
  dResolve: new Date(),
  iFinalResult: 0,
  jFinalResult: null,
  autoCreateTask: false,
  jSLA: null,
};
