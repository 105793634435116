import styled from "styled-components";

const StatusTicket = (props: { iStatus?: number }) => {
  if (props.iStatus === 1) {
    return (
      <Status backgroundColor="#D8EDFF" color="#3D98E7">
        Open
      </Status>
    );
  } else if (props.iStatus === 2) {
    return (
      <Status backgroundColor="#FEF0C9" color="#A97C09">
        Need Approval Complete
      </Status>
    );
  } else if (props.iStatus === 3) {
    return (
      <Status backgroundColor="#FFE3E3" color="#EB2222">
        Closed Open
      </Status>
    );
  } else if (props.iStatus === 4) {
    return (
      <Status backgroundColor="#FFE3E3" color="#EB2222">
        Closed
      </Status>
    );
  }
  return <></>;
};

export default StatusTicket;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 5px;
  padding: 0px 10px;
  display: inline-block;
  margin-left: 10px;
`;
