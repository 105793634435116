import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  MenuProps,
  Popover,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { generateInitialFromName } from "../helpers/generator";
import { getFullName } from "../helpers/name";
import { Offline } from "react-detect-offline";
import { capitalizeFirstLetter } from "../helpers/text";
import { useAuthUser } from "react-auth-kit";
import NotificationList from "./Notification/NotificationList";
import useFetchList from "../hooks/useFetchList";
import { CloudOff } from "react-feather";
import { syncOfflineData } from "../hooks/useOfflineData";
import UseActionQueue from "../hooks/useActionQueue";
import useAuthApp from "../hooks/useAuthApp";

const UserAvatar: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const { doLogout } = useAuthApp();

  const { data, fetchList } = useFetchList<{
    iNotification: number;
    iTo: number;
    vTitle: string;
    vMessage: string;
    dCreatedServer: Date | string;
    iRead: boolean;
    jMeta: any;
  }>({
    endpoint: "notifications",
    limit: 25,
    initialQuery: {
      toId: auth()?.iUser,
    },
  });

  const [totalNotification, setTotalNotification] = useState(0);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { executeAction } = UseActionQueue();
  const initial = generateInitialFromName(auth()?.vFullName!);
  const profilePicture = auth()?.jUserDetails?.profilePicture?.url || null;
  // const role = capitalizeFirstLetter(auth()?.userType!);
  let name = auth()?.vFullName ? getFullName(auth()) : "";
  name = name
    ? name
        .split(" ")
        .map((value: any) => capitalizeFirstLetter(value))
        .join(" ")
    : "";

  const handleMenuClick = (e: any) => {
    if (e.key === "profile") {
      navigate("/my-profile");
    } else if (e.key === "change-password") {
      navigate("/change-password");
    } else {
      doLogout();
    }
  };

  const items: MenuProps = {
    onClick: handleMenuClick,
    items: [
      {
        key: "1",
        type: "group",
        label:
          "Welcome, " +
          (auth()?.vFullName?.length > 15
            ? auth()?.vFullName!.substring(0, 15) + "..."
            : auth()?.vFullName),
        children: [
          {
            key: "profile",
            icon: <UserOutlined />,
            label: "My Profile",
          },
          // {
          //   key: "change-password",
          //   icon: <LockOutlined />,
          //   label: "Change Password",
          // },
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Logout",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    let count = 0;
    data.forEach((item) => {
      if (item.iRead === false) {
        count++;
      }
    });
    setTotalNotification(count);
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        gap: 14,
        width: "100%",
        alignItems: "center",
      }}
    >
      <Offline
        onChange={(online: any) => {
          if (online) {
            message.success("You are online ✅");
            syncOfflineData(auth()?.iUser);
            executeAction();
          } else {
            message.warning("You are offline");
          }
        }}
      >
        <Divider type="vertical" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <CloudOff />
          <Text
            style={{ color: "#757575", fontSize: 10, fontWeight: "normal" }}
          >
            Unsynced
          </Text>
        </div>
        <Divider type="vertical" />
      </Offline>
      <Popover
        placement="bottomRight"
        trigger="click"
        open={isNotificationOpen}
        onOpenChange={setIsNotificationOpen}
        content={
          <NotificationList
            data={data}
            fetchList={fetchList}
            setIsNotificationOpen={setIsNotificationOpen}
          />
        }
      >
        <Button type="text">
          <Badge color="#20A1F5" count={totalNotification}>
            <BellOutlined style={{ fontSize: 20 }} />
          </Badge>
        </Button>
      </Popover>
      <Divider type="vertical" />
      <Dropdown menu={items}>
        <div
          style={{
            display: "flex",
            gap: 8,
            width: "100%",
            alignItems: "center",
          }}
        >
          {profilePicture ? (
            <Avatar size="large" src={profilePicture} />
          ) : (
            <AppAvatar size="large">{initial}</AppAvatar>
          )}
          <Text>{auth()?.vFullName}</Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              lineHeight: 1.5,
            }}
          >
            <span
              style={{
                marginBottom: 0,
                fontSize: 12,
                color: "#768499",
              }}
            >
              {/* {role} */}
            </span>
            <span
              style={{
                marginTop: 0,
                marginBottom: 0,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {name}
            </span>
          </div>

          <Icon />
        </div>
      </Dropdown>
    </div>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 7px;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default UserAvatar;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
