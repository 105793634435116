import { BaseResponseProps, getErrorMessage } from "@qlibs/react-components";
import { httpRequest } from "../helpers/api";
import { ContactProps } from "../types/contact.type";
import { message } from "antd";
import {
  addContact,
  addOrganizationUsers,
  getContacts,
} from "../hooks/useOfflineData";
import { OrganizationUserProps } from "../types/organizationUser.type";
import { add } from "date-fns";
import { ContactArray, RelayContact } from "../types/myContact.type";

export async function apiGetMyContact(query?: {
  searchQuery?: string;
  iOrganization?: number;
}) {
  try {
    let url = `${process.env.REACT_APP_BASE_URL}/my-contacts`;
    if (query?.searchQuery) {
      url += `?keyword=${query.searchQuery}`;
    }
    if (query?.iOrganization !== undefined) {
      url += `${query.searchQuery ? "&" : "?"}iOrganization=${
        query.iOrganization
      }`;
    }
    const res = await httpRequest.get<
      BaseResponseProps<{
        users: ContactProps[];
        relay: any[];
        results: { users: any[]; relays: any[] };
      }>
    >(url);
    return res;
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiSearchMyContactByOrganization(
  searchQuery?: string,
  iOrganization?: number
) {
  try {
    const res = await httpRequest.get<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/find-contacts?keyword=${searchQuery}${
        iOrganization !== undefined ? "&iOrganization=" + iOrganization : ""
      }`
    );
    return res;
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiSearchMyContact(searchQuery: string) {
  try {
    const res = await httpRequest.get<BaseResponseProps<{ user: any[] }>>(
      `${process.env.REACT_APP_BASE_URL}/find-contacts?keyword=${searchQuery}`
    );
    return res;
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiAddContact(data: {
  iUser?: number;
  iRelay?: number;
  iOrganization?: number;
}) {
  let newData: {
    iUserContact?: number;
    iRelayContact?: number;
    iOrganization?: number;
    dCreatedLocal?: Date | string;
    dUpdatedLocal?: Date | string;
  } = {};
  newData.iUserContact = data.iUser;
  newData.iRelayContact = data.iRelay;
  newData.iOrganization = data.iOrganization;
  newData.dCreatedLocal = new Date();
  newData.dUpdatedLocal = new Date();
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/contacts`,
      newData
    );
    if (res) {
      message.success("Success add new contact");
    }
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiDeleteContactByUserId(iUser: number) {
  try {
    const res = await httpRequest.delete<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/my-contacts/user/${iUser}`
    );
    if (res) {
      message.success("Success delete contact");
    }
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiDeleteContactByRelayId(iUser: number) {
  try {
    const res = await httpRequest.delete<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/my-contacts/relay/${iUser}`
    );
    if (res) {
      message.success("Success delete contact");
    }
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiSetContactOrganization(iUser: number) {
  try {
    const res = await httpRequest.get<
      BaseResponseProps<{ results: OrganizationUserProps }>
    >(process.env.REACT_APP_BASE_URL + `/organization-users?iUser=${iUser}`);
    if (res) {
      addOrganizationUsers(res.data.payload.results);
    }
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}

export async function apiSyncMyContact() {
  try {
    const res = await httpRequest.get<
      BaseResponseProps<{
        relay: RelayContact[];
        users: ContactArray[];
      }>
    >(process.env.REACT_APP_BASE_URL + "/my-contacts");
    console.log("contact ", res.data.payload);
    if (res) {
      console.log("contact passed", res.data.payload);
      addContact(res.data.payload);
    }
    // res.data.payload.relay.forEach((element: any) =>
    // );
  } catch (err) {}
}
