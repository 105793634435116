import { BaseResponseProps } from "@qlibs/react-components";
import { httpRequest } from "../helpers/api";

enum EKeyConfig {
  PRIVACY_AND_POLICY = "PRIVACY_AND_POLICY",
  ABOUT_US = "ABOUT_US",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
}

export async function apiGetPrivacyPolicy() {
  try {
    const res = await httpRequest.get<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL +
        "/app-configs/" +
        EKeyConfig.PRIVACY_AND_POLICY
    );
    return res.data.payload.vValue;
  } catch (err) {
    return "";
  }
}

export async function apiGetTermsAndConditions() {
  try {
    const res = await httpRequest.get<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL +
        "/app-configs/" +
        EKeyConfig.TERMS_AND_CONDITIONS
    );
    return res.data.payload.vValue;
  } catch (err) {
    return "";
  }
}

export async function apiGetAboutUs() {
  try {
    const res = await httpRequest.get<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/app-configs/" + EKeyConfig.ABOUT_US
    );
    return res.data.payload.vValue;
  } catch (err) {
    return "";
  }
}
