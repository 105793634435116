import React, { useEffect, useState } from "react";
import { useAuthUser, useSignIn } from "react-auth-kit";
import styled from "styled-components";
import dayjs from "dayjs";
import { UploadProps } from "antd/lib";
import {
  Button,
  Col,
  Input,
  Row,
  Upload,
  message,
  Select,
  DatePicker,
  Avatar,
  Image,
} from "antd";
import { EditOutlined, UserOutlined, SaveOutlined } from "@ant-design/icons";
import { UploadFile, RcFile } from "antd/lib/upload/interface";
import { generateColor, getInitialName } from "../../helpers/constant";
import { httpRequest } from "../../helpers/api";
import { UserUpdate, initialProfileUpdate } from "../../types/user.type";
import useAuthApp from "../../hooks/useAuthApp";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const More = () => {
  const auth = useAuthUser();
  const { syncProfile } = useAuthApp();
  const [fileList, setFileList] = React.useState<any[]>(
    auth()?.jUserDetails?.profilePicture?.url
      ? [
          {
            url: auth()?.jUserDetails?.profilePicture?.url,
          },
        ]
      : []
  );
  const [isEdit, setIsEdit] = useState(false);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [profileUpdate, setProfileUpdate] =
    React.useState<UserUpdate>(initialProfileUpdate);
  const [previewImage, setPreviewImage] = React.useState("");

  const props: UploadProps = {
    onChange(info) {
      const { file } = info;
      if (file.size && file.size > 2000000) {
        message.config({ maxCount: 1 });
        message.error("File size must be less than 10MB");
      } else {
        const isFileInList = fileList.some((item) => item.uid === file.uid);
        if (!isFileInList) {
          setFileList([file]);
          console.log("info.fileList", fileList);
        }
        console.log("info.fileList", fileList);
      }
    },
  };

  const uploadButton = (
    <div
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        backgroundSize: "cover",
      }}
    >
      {getInitialName(auth()?.vFullName)}
    </div>
  );

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setFileList([]);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
    }
    return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
  };

  const optionGender = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
  ];

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleSaveProfile = async () => {
    try {
      const saveData = {
        vFirstName:
          profileUpdate.vFirstName === ""
            ? auth()?.vFirstName
            : profileUpdate.vFirstName,
        vLastName:
          profileUpdate.vLastName === ""
            ? auth()?.vLastName
            : profileUpdate.vLastName,
        vNickName:
          profileUpdate.vNickName === ""
            ? auth()?.vNickName
            : profileUpdate.vNickName,
        iGender:
          profileUpdate.iGender === 0 ? auth()?.iGender : profileUpdate.iGender,
        jUserDetails: {
          birthdate:
            profileUpdate.jUserDetails.birthdate === ""
              ? auth()?.jUserDetails.birthdate
              : profileUpdate.jUserDetails.birthdate,
        },
      };
      const upload = await httpRequest.patch(
        `/users/${auth()?.iUser}`,
        saveData
      );
      console.log("upload", fileList);

      if (fileList?.length > 0 && fileList?.[0]?.originFileObj) {
        let formData = new FormData();
        formData.append("file", fileList?.[0]?.originFileObj as Blob);

        const uploadProfilePic = await httpRequest.put(
          `/users/${auth()?.iUser}/upload-profile-picture`,
          formData
        );
      } else if(auth()?.jUserDetails?.profilePicture?.url && fileList?.[0]?.originFileObj === undefined && isDelete) {
        await httpRequest.delete(
          `/users/${auth()?.iUser}/delete-profile-picture`
        );
      }


      if (upload) {
        syncProfile();
        setIsEdit(false);
        message.success("Profile saved");
      }
    } catch (error) {
      console.error("error profile", error);
      message.error("Failed to save profile");
    }
  };

  useEffect(() => {

  console.log("fileList", fileList);
  }, [fileList]);
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          backgroundColor: "white",
          margin: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          gap: "20px",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #ccc",
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            <UserOutlined /> My Profile
          </h2>
          <Button
            type="primary"
            onClick={() => {
              isEdit ? handleSaveProfile() : setIsEdit(true);
            }}
          >
            {!isEdit ? <EditOutlined /> : <SaveOutlined />}
            {isEdit ? `Save` : `Edit`}
          </Button>
        </div>
        <Row style={{ alignItems: "center" }}>
          <Col span={5}>
            <Text>Profile Picture</Text>
          </Col>
          <Col span={16}>
            {isEdit ? (
              <Upload
                beforeUpload={beforeUpload}
                fileList={fileList}
                onChange={handleChange}
                onPreview={handlePreview}
                listType="picture-circle"
              >
                {fileList.length > 0 ? "" : uploadButton}
              </Upload>
            ) : auth()?.jUserDetails?.profilePicture?.url ? (
              <Avatar
                size={110}
                src={auth()?.jUserDetails?.profilePicture?.url}
              />
            ) : (
              <Avatar
                size={110}
                style={{ backgroundColor: generateColor(auth()?.vFullName) }}
              >
                <TextBig>{getInitialName(auth()?.vFullName)}</TextBig>
              </Avatar>
            )}
          </Col>
        </Row>

        {previewOpen && (
          <Image
            wrapperStyle={{ display: "none" }}
            onEmptied={() => setIsDelete(true)}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}

        <Row style={{ alignItems: "center" }}>
          <Col span={5}>
            <Text>First Name</Text>
          </Col>
          <Col span={16}>
            <Input
              disabled={!isEdit}
              defaultValue={auth()?.vFirstName}
              onChange={(e) => {
                setProfileUpdate({
                  ...profileUpdate,
                  vFirstName: e.target.value,
                });
              }}
            ></Input>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={5}>
            <Text>Last Name</Text>
          </Col>
          <Col span={16}>
            <Input
              disabled={!isEdit}
              defaultValue={auth()?.vLastName}
              onChange={(e) => {
                setProfileUpdate({
                  ...profileUpdate,
                  vLastName: e.target.value,
                });
              }}
            ></Input>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={5}>
            <Text>Nickname</Text>
          </Col>
          <Col span={16}>
            <Input
              disabled={!isEdit}
              defaultValue={auth()?.vNickName}
              onChange={(e) => {
                setProfileUpdate({
                  ...profileUpdate,
                  vNickName: e.target.value,
                });
              }}
            ></Input>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={5}>
            <Text>Gender</Text>
          </Col>
          <Col span={16}>
            <Select
              disabled={!isEdit}
              defaultValue={auth()?.iGender}
              options={optionGender}
              onChange={(e) => {
                setProfileUpdate({
                  ...profileUpdate,
                  iGender: e,
                });
              }}
            />
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={5}>
            <Text>Birthday</Text>
          </Col>
          <Col span={16}>
            <DatePicker
              disabled={!isEdit}
              defaultValue={dayjs(auth()?.jUserDetails?.birthdate)}
              onChange={(e) => {
                if (e) {
                  setProfileUpdate({
                    ...profileUpdate,
                    jUserDetails: {
                      ...profileUpdate.jUserDetails,
                      birthdate: e.format("YYYY-MM-DD"),
                    },
                  });
                }
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default More;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: left;
`;

const TextBig = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: left;
`;
