import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { httpRequest } from "../helpers/api";
import Create from "../screens/myTicket/create";
import { CreateTaskProps, initialCreateTask } from "../types/task.type";

const initialState: {
  actionQueue: {
    type: string;
    link: string;
    body: object;
  }[];
  ticketTask: {
    ticket: object;
    tasks: CreateTaskProps[];
  }[];
} = {
  actionQueue: [{
    type: "",
    link: "",
    body: {},
  }],
  ticketTask: [{
    ticket: {},
    tasks: [initialCreateTask]
  }],
};

const { useGlobalState } = createGlobalState(initialState);

export default function UseActionQueue() {
  const [actionQueue, setActionQueue] = useGlobalState("actionQueue");
  const [ticketTask, setTicketTask] = useGlobalState("ticketTask");
  useEffect(() => {
    checkClear();
  }, [actionQueue]);

  const checkClear = () => {
    if (actionQueue.length > 10) {
      clearAction();
    }
  };

  const pushAction = (type: string, link: string, body: object) => {
    // Check if the action already exists in the actionQueue
    const actionExists = actionQueue.some(
      (action) =>
        action.type === type &&
        action.link === link &&
        JSON.stringify(action.body) === JSON.stringify(body)
    );

    if (!actionExists) {
      // If the action doesn't exist, add it to the actionQueue
      setActionQueue([...actionQueue, { type, link, body }]);
    }
    // If the action already exists, do nothing
  };

  const peekAction = () => {
    return actionQueue[0];
  };
  const getAllActions = () => {
    return actionQueue;
  };
  const removeAction = () => {
    setActionQueue(actionQueue.slice(1));
  };
  const clearAction = () => {
    setActionQueue(initialState.actionQueue);
  };

  const createTicketTask = (ticket: {}, tasks: CreateTaskProps[]) => {
    const ticketExists = ticketTask.some(
      (item) => item.ticket === ticket
    );
    if (!ticketExists) {
      setTicketTask([...ticketTask, { ticket, tasks }]);
    };
  }

  const executeTicketTask = async (ticket:{}) => {
    const executedItem = ticketTask.find((item) => item.ticket === ticket);
    if (executedItem){
      try{
        const submitTicket = await httpRequest.post("/tickets", executedItem.ticket);
        const dataTicket = submitTicket.data.payload;
        const ticketId = dataTicket.iTicket;
        //save tasks
        const arrayTaskId: number[] = [];
        for (const task of executedItem.tasks){
          const submitTask = await httpRequest.post("/tasks", {
            ...task,
            iTicket: ticketId,
          });
          const dataTask = submitTask.data.payload;
          if(task.jAttachment && task.jAttachment.length > 0){
            const taskId = dataTask.iTask;
            const formData = new FormData();
            task.jAttachment.forEach((attachment: any) => {
              formData.append("files", attachment);
            });
            const submitAttachment = await httpRequest.put(
              `/tasks/${taskId}/attachments`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
          }
          arrayTaskId.push(dataTask.iTask);
        }
      }catch (error){
        console.error(error);
      }
    }
  }

  const executeAction = async () => {
    const action = peekAction();
    let response;
    if (action) {
      // Execute the action
      switch (action.type) {
        case "GET":
          response = await httpRequest.get(action.link, action.body);
          // Execute a GET request
          break;
        case "POST":
          response = await httpRequest.post(action.link, action.body);
          // Execute a POST request
          break;
        case "PUT":
          response = await httpRequest.put(action.link, action.body);
          // Execute a PUT request
          break;
        case "DELETE":
          response = await httpRequest.delete(action.link, action.body);
          // Execute a DELETE request
          break;
        case "CREATE":
          executeTicketTask(action.body);
          break;
        default:
          break;
      }
      removeAction();
    }
  };
  return {
    actionQueue,
    setActionQueue,
    pushAction,
    peekAction,
    removeAction,
    clearAction,
    getAllActions,
    executeAction,
    createTicketTask
  };
}
