import AboutUsComponent from "../../components/More/AboutUs";

const AboutUs = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBlock: 40,
        paddingInline: 30,
      }}
    >
      <AboutUsComponent />
    </div>
  );
};

export default AboutUs;
