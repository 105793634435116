import { Divider, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TaskProps } from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import { TaskHistoryProps, action } from "../../types/taskHistory.type";
import dayjs from "dayjs";
import StatusTask from "./StatusTask";
import { Circle } from "react-feather";
import { apiGetNameById } from "../../api/user";
import { da } from "date-fns/locale";
import { apiGetNameRelayById } from "../../api/relay";
import { TicketProps } from "../../types/ticket.type";

type Props = {
  isTask: boolean;
  myTask?: TaskProps;
  myTicket?: TicketProps;
  iTicket?: number;
};

const TaskHistory = (props: Props) => {
  const [nameCreate, setNameCreate] = useState<string[]>(
    new Array(100).fill(null)
  );
  const [nameTarget, setNameTarget] = useState<string[]>(
    new Array(100).fill(null)
  );
  const historyData = props.isTask
    ? props.myTask?.histories || []
    : props.myTicket?.histories || [];

  const formatDate = (date: Date | string): string => {
    const formattedDate = dayjs(date).format("DD MMMM YYYY");
    return formattedDate;
  };

  const formatTime = (date: Date | string): string => {
    const formattedDate = dayjs(date).format("HH:mm");
    return formattedDate;
  };

  const setNameById = async (data: TaskHistoryProps[]) => {
    let updatedNameCreate = [...nameCreate];
    let updatedNameTarget = [...nameTarget];

    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      let res = "";
      let resTarget = "";
      if (item.iRelayCreate) {
        const relayName = await apiGetNameRelayById(item.iRelayCreate);
        if (relayName) {
          res = relayName + " (Contact Center)";
        }
      } else {
        res = await apiGetNameById(item.iUserCreate);
      }

      if (item.iRelayTarget) {
        const relayTargetName = await apiGetNameRelayById(item.iRelayTarget);
        if (relayTargetName) {
          resTarget = relayTargetName + " (Contact Center)";
        }
      } else {
        resTarget = await apiGetNameById(item.iUserTarget);
      }

      if (res) {
        updatedNameCreate[index] = res;
      }

      if (resTarget) {
        updatedNameTarget[index] = resTarget;
      }
    }

    setNameCreate(updatedNameCreate);
    setNameTarget(updatedNameTarget);
  };

  const generateWordingStatus = (actionStatus: string, iResult: number) => {
    if (actionStatus === "CREATE") {
      return "has been Created by";
    } else if (actionStatus === "SUBMISSION") {
      return "has been marked as Done";
    } else {
      if (iResult.toString().charAt(0) === "6") {
        return "has been Submited by";
      } else if (iResult === 2) {
        return "has been Accepted by";
      } else if (iResult === 3) {
        return "has been Rejected by";
      } else if (iResult === 7) {
        return "has been Returned to";
      } else if (iResult === 8) {
        return "has been Forwarded to";
      } else if (iResult === 10) {
        return "has been marked as Late";
      } else {
        return "has been updated by";
      }
    }
  };
  useEffect(() => {
    if (historyData) {
      setNameById(historyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (historyData) {
      setNameById(historyData);
    }
  }, [historyData]);

  return (
    <React.Fragment>
      {historyData
        .sort((a, b) => {
          return b.iHistory - a.iHistory;
        })
        .map((item, index, array) => (
          <div>
            {((index > 0 &&
              new Date(array[index].dCreated).toDateString() !==
                new Date(array[index - 1].dCreated).toDateString()) ||
              index === 0) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBlock: 20,
                }}
              >
                <Status backgroundColor="#DCDFE5">
                  {formatDate(item.dCreated)}
                </Status>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBlock: 10,
              }}
            >
              <TextBody>{formatTime(item.dCreated)}</TextBody>
              <Circle
                size={8}
                fill="grey"
                color="grey"
                style={{ marginLeft: 10, opacity: 0.5 }}
              />
              <StatusTask iStatus={item.jData.iResult} />
              <Circle
                size={8}
                fill="grey"
                color="grey"
                style={{ marginRight: 10, opacity: 0.5 }}
              />
              {nameCreate[index] ? (
                <TextBody>{nameCreate[index] || item.vFullName}</TextBody>
              ) : (
                <Skeleton.Input
                  style={{ marginBlock: 10, height: 20 }}
                  active
                />
              )}
            </div>
            {nameCreate[index] ? (
              <TextBold>
                {item.jData.vName}{" "}
                <span style={{ fontWeight: "normal" }}>
                  {`${generateWordingStatus(
                    item.vAction,
                    item.jData.iResult
                  )} ${item.vAction !== "SUBMISSION" ? nameCreate[index] : ""}`}
                </span>
              </TextBold>
            ) : (
              <div>
                <Skeleton.Input
                  style={{ marginBlock: 10, height: 20, width: 400 }}
                  active
                />
              </div>
            )}
            {nameCreate[index] && nameTarget[index] ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBlock: 10,
                }}
              >
                <TextBody>{`From: ${
                  nameCreate[index] || item.vFullName
                }`}</TextBody>
                <Circle
                  size={8}
                  fill="grey"
                  color="grey"
                  style={{ marginInline: 20, opacity: 0.5 }}
                />
                <TextBody>{`To: ${
                  nameTarget[index] || item.userTarget.vFullName
                }`}</TextBody>
              </div>
            ) : (
              <Skeleton.Input style={{ marginBlock: 10, height: 20 }} active />
            )}
            <Divider />
          </div>
        ))}
    </React.Fragment>
  );
};

export default TaskHistory;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0px 10px;
  display: inline-block;
`;

const TextBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: gray;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
