import { Users } from "react-feather";
import styled from "styled-components";

const SearchEmptyContact = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Users size={60} style={{ opacity: "30%", marginBlock: 20 }} />
      <TextBold>Search by name, phone number, email</TextBold>
      <Text>Find and add contact to collaborate and communicate</Text>
    </div>
  );
};

export default SearchEmptyContact;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: center;
  opacity: 0.3;
  width: 40%;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
  opacity: 0.3;
  width: 40%;
  margin-bottom: 10px;
`;
