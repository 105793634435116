import { Card, Tabs } from "antd";
import styled from "styled-components";
import NotificationItem from "../../components/Notification/NotificationItem";
import {
  apiNotificationMarkAllAsRead,
  apiNotificationMarkAsRead,
} from "../../api/notification";
import { useAuthUser } from "react-auth-kit";
import useFetchList from "../../hooks/useFetchList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoNotification from "../../components/Notification/NoNotification";
const { TabPane } = Tabs;

const NotificationScreen = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const userId = auth()?.iUser;

  const [activeKey, setActiveKey] = useState("1");

  const { data, fetchList, setQuery } = useFetchList<{
    iNotification: number;
    iTo: number;
    vTitle: string;
    vMessage: string;
    dCreatedServer: Date | string;
    iRead: boolean;
    jMeta: any;
  }>({
    endpoint: "notifications",
    limit: 25,
    initialQuery: {
      toId: userId,
    },
  });

  const markAllAsRead = async () => {
    await apiNotificationMarkAllAsRead();
    fetchList();
  };

  const markAsRead = async (notificationId: string, toId: string) => {
    await apiNotificationMarkAsRead(notificationId, toId);
    fetchList();
  };

  useEffect(() => {
    if (activeKey === "2") {
      setQuery((oldval) => ({
        ...oldval,
        toId: userId,
        iRead: "false",
      }));
    } else {
      setQuery((oldval) => ({
        ...oldval,
        toId: userId,
        iRead: undefined,
      }));
    }
    fetchList();
  }, [activeKey]);
  return (
    <div>
      <Card style={{ marginInline: 20, marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TitleBold>Notification</TitleBold>
          {data?.find((item) => item?.iRead === false) && (
            <TitleBlue onClick={() => markAllAsRead()}>
              Mark All as Read
            </TitleBlue>
          )}
        </div>
      </Card>
      <Card style={{ marginInline: 20, marginBlock: 10 }}>
        <Tabs
          onChange={(val) => setActiveKey(val)}
          defaultActiveKey={activeKey}
        >
          <TabPane tab={<Title>All Notifications</Title>} key="1">
            <div style={{ paddingTop: 10 }}>
              {data?.length > 0 ? (
                data.map((item) => (
                  <div
                    onClick={() => {
                      markAsRead(
                        item.iNotification.toString(),
                        item.iTo.toString()
                      );
                      navigate("/my-task/" + item.jMeta?.ipTask);
                    }}
                  >
                    <NotificationItem
                      vTitle={item.vTitle}
                      vMessage={item.vMessage}
                      iRead={item.iRead}
                      dCreatedServer={item.dCreatedServer}
                    />
                  </div>
                ))
              ) : (
                <NoNotification />
              )}
            </div>
          </TabPane>
          <TabPane tab={<Title>Unread</Title>} key="2">
            <div style={{ paddingTop: 10 }}>
              {data?.length > 0 ? (
                data.map((item) => (
                  <div
                    onClick={() =>
                      markAsRead(
                        item.iNotification.toString(),
                        item.iTo.toString()
                      )
                    }
                  >
                    <NotificationItem
                      vTitle={item.vTitle}
                      vMessage={item.vMessage}
                      iRead={item.iRead}
                      dCreatedServer={item.dCreatedServer}
                    />
                  </div>
                ))
              ) : (
                <NoNotification />
              )}
            </div>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default NotificationScreen;

const TitleBlue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: #045aff;
  cursor: pointer;
`;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
