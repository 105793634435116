import { BaseResponseProps, getErrorMessage } from "@qlibs/react-components";
import { httpRequest } from "../helpers/api";

export async function apiSignOut() {
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/auth/signout`
    );
    if (res) {
      return res;
    }
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}
