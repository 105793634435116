import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { theme } from "../../assets/theme";
import { Circle } from "react-feather";

const SLALevelIcon = (props: { level?: string }) => {
  if (props.level === "CRITICAL") {
    return <WarningOutlined style={{ fontSize: "18px", color: "red" }} />;
  } else if (props.level === "HIGH") {
    return (
      <ExclamationCircleOutlined
        style={{ fontSize: "18px", color: theme.colors.warning }}
      />
    );
  } else if (props.level === "NORMAL") {
    return (
      <Circle
        fill={theme.colors.warning}
        size={16}
        stroke={theme.colors.warning}
      />
    );
  } else if (props.level === "LOW") {
    return (
      <Circle
        fill={theme.colors.green500}
        stroke={theme.colors.green500}
        size={16}
      />
    );
  } else {
    return <Circle fill="grey" stroke="grey" size={16} />;
  }
};

export default SLALevelIcon;
