import styled from "styled-components";

const StatusTask = (props: { iStatus?: number; vName?: string }) => {
  if (props.iStatus === 1) {
    return (
      <Status backgroundColor="#FEF0C9" color="#A97C09">
        Waiting Approval
      </Status>
    );
  } else if (props.iStatus === 2) {
    return (
      <Status backgroundColor="#D8EDFF" color="#3D98E7">
        In Progress
      </Status>
    );
  } else if (props.iStatus === 3) {
    return (
      <Status backgroundColor="#EB2222" color="white">
        Rejected
      </Status>
    );
  } else if (props.iStatus === 8) {
    return (
      <Status backgroundColor="#D8EDFF" color="#3D98E7">
        Fowarded
      </Status>
    );
  } else if (props.iStatus === 9) {
    return (
      <Status backgroundColor="#D2F8C8" color="#238D0B">
        Done
      </Status>
    );
  } else if (props.iStatus === 21) {
    return (
      <Status backgroundColor="#D2F8C8" color="#238D0B">
        {"Done (Yes)"}
      </Status>
    );
  } else if (props.iStatus === 22) {
    return (
      <Status backgroundColor="#D2F8C8" color="#238D0B">
        {"Done (No)"}
      </Status>
    );
  } else if (props.iStatus === 10) {
    return (
      <Status backgroundColor="#FFE3E3" color="#EB2222">
        Missed Schedule
      </Status>
    );
  } else if (
    props.iStatus === 621 ||
    props.iStatus === 622 ||
    props.iStatus === 69
  ) {
    return (
      <Status backgroundColor="#F0D8FF" color="#A14DD6">
        In Review
      </Status>
    );
  } else if (props.iStatus === 7) {
    return (
      <Status backgroundColor="#FFE3E3" color="#EB2222">
        Returned
      </Status>
    );
  }
  return <Status backgroundColor="#E6E9EF">{props.vName || ""}</Status>;
};

export default StatusTask;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 5px;
  padding: 0px 10px;
  display: inline-block;
  margin: 0 10px;
  text-align: center;
`;
