import React, { useState } from "react";
import { Button, Rate, Divider, Row, Col, Radio, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Edit } from "react-feather";
import styled from "styled-components";
import { apiSendFeedback } from "../../api/feedback";

enum vType {
  ALL_SERVICE = "ALL_SERVICE",
  APP_ISSUE = "APP_ISSUE",
  IMPROVEMENT = "IMPROVEMENT",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  OTHER = "OTHER",
}

const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [type, setType] = useState("");
  const [vMessage, setVMessage] = useState("");

  const handleSendFeedback = async () => {
    if (rating) {
      if (type) {
        if (vMessage) {
          const res = await apiSendFeedback(rating, type, vMessage);
          if (res) {
            setRating(0);
            setType("");
            setVMessage("");
          }
        } else {
          message.error("Message is required");
        }
      } else {
        message.error("Type is required");
      }
    } else {
      message.error("Rate is required");
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          backgroundColor: "white",
          margin: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          gap: "20px", // Gap between form fields
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Edit style={{ fontSize: 24 }} />
            <TitleBold>Feedback</TitleBold>
          </div>
          <Button type="primary" onClick={handleSendFeedback}>
            Send Feedback
          </Button>
        </div>
        <Divider style={{ marginBlock: 0 }} />
        {/* Rating */}
        <Row>
          <Col span={3}>
            <Text>Rating</Text>
          </Col>

          <Rate
            value={rating}
            onChange={(e) => {
              setRating(e);
            }}
          />
        </Row>

        {/* Feedback Type */}
        <Row>
          <Col span={3}>
            <Text>Type</Text>
          </Col>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Radio.Group
              value={type}
              onChange={(e) => setType(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value={vType.ALL_SERVICE}>All Service</Radio.Button>
              <Radio.Button value={vType.APP_ISSUE}>App Issue</Radio.Button>
              <Radio.Button value={vType.IMPROVEMENT}>Improvement</Radio.Button>
              <Radio.Button value={vType.CUSTOMER_SUPPORT}>
                Customer Support
              </Radio.Button>
              <Radio.Button value={vType.OTHER}>Other</Radio.Button>
            </Radio.Group>
          </div>
        </Row>
        {/* Message Box */}
        <Row>
          <Col span={3}>
            <Text>Message</Text>
          </Col>
          <Col span={21}>
            <TextArea
              rows={4}
              value={vMessage}
              placeholder="Enter your feedback here ..."
              onChange={(e) => {
                setVMessage(e.target.value);
              }}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Feedback;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: justify;
  line-height: 2;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 15px;
`;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
