import React, { useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Input,
  Select,
  DatePicker,
  message,
  Button,
} from "antd";
import { theme } from "../../assets/theme";
import styled from "styled-components";
import {
  InfoCircleOutlined,
  SettingOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import en from "antd/es/date-picker/locale/en_US";
import { ChevronLeft, Circle } from "react-feather";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import { httpRequest } from "../../helpers/api";
import buddhistEra from "dayjs/plugin/buddhistEra";
import TaskList from "./taskList";
import { useNavigate } from "react-router-dom";
import { CreateTaskProps, initialCreateTask } from "../../types/task.type";
import {
  CreateTicketProps,
  ISLAProps,
  initialCreateTicket,
  initialSLA,
} from "../../types/ticket.type";
import useTaskList from "../../hooks/useTaskList";
import useFetchList from "../../hooks/useFetchList";
import UseActionQueue from "../../hooks/useActionQueue";
import useExecutionTime from "../../hooks/useExecutionTime";

dayjs.extend(buddhistEra);

const Create = () => {
  const {
    taskItems,
    updateTask,
    handleAddTask,
    handleEmptyTask,
    updateTicket,
    setTicketDate,
    setResolveDate,
    getTaskList,
    getTaskReady,
    ticketDate,
    resolveDate,
  } = useTaskList();
  const { Title, Text } = Typography;

  const {stopTimer, startTimer, updateExecutionLabel} = useExecutionTime();
  const auth = useAuthUser();
  const [selectResponse, setSelectResponse] = React.useState<string>("");
  const [selectResolve, setSelectResolve] = React.useState<string>("");
  const [isResolveEnable, setIsResolveEnable] = React.useState<boolean>(false);
  const [taskListAmount, setTaskListAmount] = React.useState<number>(1);
  const [isDataReady, setIsDataReady] = React.useState(false);
  const { pushAction, createTicketTask } = UseActionQueue();
  const [datePickerResponseValue, setDatePickerResponseValue] =
    React.useState<any>(null);
  const [datePickerResolveValue, setDatePickerResolveValue] =
    React.useState<any>(null);
  const [ticketSubmit, setTicketSubmit] =
    React.useState<CreateTicketProps>(initialCreateTicket);
  const [taskSubmit, setTaskSubmit] = React.useState<CreateTaskProps[]>([
    initialCreateTask,
  ]);
  const format = "HH:mm";
  const navigate = useNavigate();

  const { data: slaData } = useFetchList<ISLAProps>({
    pageData: "sla_levels",
    endpoint: "sla_levels",
  });

  useEffect(() => {
    setTaskListAmount(parseInt(localStorage.getItem("taskAmmount") || "1"));
  }, [localStorage.getItem("taskAmmount")]);
  useEffect(() => {
    checkDataReady();
  }, [ticketSubmit, taskSubmit, getTaskReady(), isDataReady]);

  const checkDataReady = () => {
    if (
      ticketSubmit.vName !== "" &&
      ticketSubmit.vDescription !== "" &&
      ticketSubmit.dLimitResponse !== "" &&
      ticketSubmit.dLimitResolve !== "" &&
      ticketSubmit.jSLA?.level &&
      getTaskReady() === true
    ) {
      setIsDataReady(true);
    } else {
      console.log("data ready", ticketSubmit);
      console.log("gettask ready", getTaskReady());
      setIsDataReady(false);
    }
  };
  const disabledDate = (current: dayjs.Dayjs | null): boolean => {
    if (!current) return false;

    const today = dayjs().startOf("day");
    return current.isBefore(today);
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledResolveDate = (current: dayjs.Dayjs | null): boolean => {
    const stringResponse = ticketDate.toString();
    if (!current) return false;

    const response = ticketDate
      ? dayjs(stringResponse).startOf("day")
      : dayjs().startOf("day");

    return current.startOf("day").isBefore(response);
  };

  const disabledRangeTime = () => {
    const resolveDate = new Date(ticketDate);
    const hour = resolveDate.getHours();
    const minute = resolveDate.getMinutes();
    return {
      disabledHours: () => range(0, 24).splice(0, hour),
      disabledMinutes: () => range(0, 60).splice(0, minute),
    };
  };
  const handleSaveTicket = async () => {
    startTimer(`Creating Tasks and Ticket`);
    const strTicket = localStorage.getItem("ticketSubmit");
    const ticket = strTicket ? JSON.parse(strTicket) : ticketSubmit;
    try {
      // Save ticket
      const submitTicket = await httpRequest.post("/tickets", ticket);
      const dataTicket = submitTicket.data.payload;
      const ticketId = dataTicket.iTicket;

      // Save tasks sequentially
      const arrayTaskId: number[] = [];
      for (let index = 0; index < taskItems.length; index++) {
        const task = taskItems[index];
        const submitTask = await httpRequest.post("/tasks", {
          ...task,
          iTicket: ticketId,
        });
        arrayTaskId.push(submitTask.data.payload.iTask);
        // Add attachment for the current task
        if (task.jAttachment && task.jAttachment.length > 0) {
          const taskId = submitTask.data.payload.iTask;
          const formData = new FormData();
          task.jAttachment.forEach((file: any) => {
            formData.append("files", file.originFileObj);
          });
          const submitAttachment = await httpRequest.put(
            `/tasks/${taskId}/attachments`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
      }
      message.success("Ticket saved successfully");
      handleEmptyTask();
      setIsDataReady(false);
      stopTimer();
      navigate("/my-ticket");
    } catch (error) {
      if(!navigator.onLine){
        createTicketTask(ticket, taskItems);
        pushAction("CREATE", "/tickets", ticket);
        navigate("/my-ticket");
      }
      // message.error("Ticket will be posted after");
    }
  };

  const handleTimeChange = (
    e: dayjs.Dayjs | null,
    timeString: string,
    isResponse: boolean
  ) => {
    const timesubmit = e?.toISOString();
    if (isResponse) {
      setSelectResponse(timeString);
      setIsResolveEnable(true);
      updateTicket({
        ...ticketSubmit,
        dLimitResponse: timesubmit,
      });
      setTicketDate(timesubmit ? timesubmit : new Date());
      setTicketSubmit({
        ...ticketSubmit,
        dLimitResponse: timesubmit ? timesubmit : "",
      });
    } else {
      if (timeString < selectResponse) {
        message.error("Resolve time must be greater than response time");
        setSelectResolve(timeString);
        return;
      } else {
        setSelectResolve(timeString);
        updateTicket({
          ...ticketSubmit,
          dLimitResolve: timesubmit,
        });
        setResolveDate(timesubmit ? timesubmit : new Date());
        setTicketSubmit({
          ...ticketSubmit,
          dLimitResolve: timesubmit ? timesubmit : "",
        });
      }
    }
  };
  const buddhistLocale: typeof en = {
    ...en,
    lang: {
      ...en.lang,
      dateFormat: "YYYY-MM-DD",
      dateTimeFormat: "YYYY-MM-DD HH:mm",
      yearFormat: "YYYY",
    },
  };

  useEffect(() => {
    updateTask(0, initialCreateTask);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: 10,
          position: "sticky",
          zIndex: 999,
          top: 0,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} />
          <Title style={{ fontSize: 16 }}>Create Ticket and Task</Title>
        </div>
        {
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            <Button type="default" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              type={isDataReady ? "primary" : "default"}
              disabled={!isDataReady}
              onClick={handleSaveTicket}
            >
              Save
            </Button>
          </div>
        }
      </div>
      <div style={{ padding: 25, marginTop: 10, marginBottom: 10 }}>
        <Row style={{ gap: 10, alignItems: "start" }}>
          <Col span={24}>
            <HeaderTitle
              style={{ marginBottom: 10, fontSize: 26, fontWeight: "w700" }}
            >
              Ticket
            </HeaderTitle>
            <Row
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "24px",
                gap: 40,
              }}
              justify={"space-between"}
            >
              <Col span={11}>
                <Row style={{ marginBottom: 10 }}>
                  <Text style={{ color: "black", fontSize: 20 }}>
                    <InfoCircleOutlined
                      style={{ fontSize: 20, color: "#B8BFCD", margin: 3 }}
                    />{" "}
                    Ticket Information
                  </Text>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Text style={{ color: "gray" }}>
                    Ticket Name <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <Input
                    style={{ marginTop: 10 }}
                    placeholder="Ticket Name"
                    onChange={(e) => {
                      updateTicket({
                        ...ticketSubmit,
                        vName: e.target.value,
                      });
                      setTicketSubmit({
                        ...ticketSubmit,
                        vName: e.target.value,
                      });
                    }}
                  />
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Text style={{ color: "gray" }}>
                    Ticket Description <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <TextArea
                    style={{ marginTop: 10 }}
                    placeholder="Ticket Description"
                    onChange={(e) => {
                      updateTicket({
                        ...ticketSubmit,
                        vDescription: e.target.value,
                      });
                      setTicketSubmit({
                        ...ticketSubmit,
                        vDescription: e.target.value,
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col span={11}>
                <Row style={{ marginBottom: 10 }}>
                  <Text style={{ color: "black", fontSize: 20 }}>
                    <SettingOutlined
                      style={{ fontSize: 20, color: "#B8BFCD", margin: 3 }}
                    />{" "}
                    Ticket Setting
                  </Text>
                </Row>
                <Row>
                  <Text style={{ color: "gray" }}>
                    SLA Level <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <Select
                    style={{ marginTop: 10 }}
                    allowClear
                    placeholder="Status"
                    onChange={(e) => {
                      const slaSelect = (slaData === undefined ? initialSLA:slaData).find(
                        (item) => item.iSLA === Number(e)
                      );
                      updateTicket({
                        ...ticketSubmit,
                        jSLA: slaSelect
                          ? {
                              name: slaSelect.vName,
                              level: slaSelect.vLevel,
                              description: slaSelect.vDescription,
                              maxResolveTimeInHours:
                                slaSelect.iMaxResolveTimeinHours,
                              maxResponseTimeInHours:
                                slaSelect.iMaxResponseTimeinHours,
                            }
                          : undefined,
                      });
                      setTicketSubmit({
                        ...ticketSubmit,
                        jSLA: slaSelect
                          ? {
                              name: slaSelect.vName,
                              level: slaSelect.vLevel,
                              description: slaSelect.vDescription,
                              maxResolveTimeInHours:
                                slaSelect.iMaxResolveTimeinHours,
                              maxResponseTimeInHours:
                                slaSelect.iMaxResponseTimeinHours,
                            }
                          : undefined,
                      });
                    }}
                  >
                    <Select.Option value={1}>
                      <WarningOutlined
                        style={{ color: "red", marginRight: 8 }}
                      />
                      Critical
                    </Select.Option>
                    <Select.Option value={2}>
                      <ExclamationCircleOutlined
                        style={{ color: theme.colors.warning, marginRight: 8 }}
                      />
                      High
                    </Select.Option>
                    <Select.Option value={3}>
                      <Circle
                        color={theme.colors.warning}
                        fill={theme.colors.warning}
                        size={10}
                        style={{ marginRight: 8 }}
                      />
                      Normal
                    </Select.Option>
                    <Select.Option value={4}>
                      <Circle
                        color={theme.colors.green500}
                        fill={theme.colors.green500}
                        size={10}
                        style={{ marginRight: 8 }}
                      />
                      Low
                    </Select.Option>
                  </Select>
                </Row>
                <Row style={{ marginTop: 10 }} justify={"space-between"}>
                  <Col span={11}>
                    <Row style={{ marginTop: 5 }}>
                      <Text style={{ color: "gray", fontSize: 12 }}>
                        Response Time <Text style={{ color: "red" }}>*</Text>
                      </Text>
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                      <DatePicker
                        placeholder="Pick Response time"
                        showTime
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD   |   HH:mm"}
                        locale={buddhistLocale}
                        value={datePickerResponseValue}
                        onChange={(e, timeString) => {
                          const isoString =
                            e?.toISOString() || dayjs().toISOString();
                          if (isoString < dayjs().toISOString()) {
                            message.error(
                              "Response time must be greater than current time"
                            );
                            setSelectResponse("");
                            setDatePickerResponseValue(null);
                            return;
                          } else {
                            handleTimeChange(e, timeString, true);
                            setDatePickerResponseValue(e);
                          }
                        }}
                      />
                      {/* <TimePicker style={{width: '100%'}} defaultValue={dayjs('12:08', format)} format={format} onChange={(e, timeString) => handleTimeChange(e, timeString, true)}/> */}
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                      <Text style={{ color: "gray", fontSize: 12 }}>
                        Response time limit{" "}
                        <b>{selectResponse ? selectResponse : ""}</b>
                      </Text>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row style={{ marginTop: 5 }}>
                      <Text style={{ color: "gray", fontSize: 12 }}>
                        Resolve Time <Text style={{ color: "red" }}>*</Text>
                      </Text>
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                      <DatePicker
                        disabled={!isResolveEnable}
                        placeholder="Pick Resolve time"
                        showTime
                        disabledDate={disabledResolveDate}
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD   |   HH:mm"}
                        locale={buddhistLocale}
                        value={datePickerResolveValue}
                        onChange={(e, timeString) => {
                          const isoString =
                            e?.toISOString() || dayjs().toISOString();
                          if (isoString < ticketDate) {
                            message.error(
                              "Response time must be greater than response time"
                            );
                            setSelectResolve("");
                            setDatePickerResolveValue(null);
                            return;
                          } else {
                            handleTimeChange(e, timeString, false);
                            setDatePickerResolveValue(e);
                          }
                        }}
                      />
                      {/* <TimePicker style={{width: '100%'}} defaultValue={dayjs('12:08', format)} format={format} onChange={(e, timeString) => handleTimeChange(e, timeString, true)}/> */}
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                      <Text style={{ color: "gray", fontSize: 12 }}>
                        Resolve time limit{" "}
                        <b>{selectResolve ? selectResolve : ""}</b>
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            gap: 10,
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <Col span={16} style={{ alignItems: "center" }}>
            <HeaderTitle
              style={{ marginBottom: 10, fontSize: 26, fontWeight: "w700" }}
            >
              Task List
            </HeaderTitle>
          </Col>
          <Col span={5} style={{ alignItems: "center" }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                handleAddTask(initialCreateTask);
              }}
            >
              + Add New Task
            </Button>
          </Col>
        </Row>
        {taskItems.map((task, index) => (
          <TaskList
            isEdit={false}
            key={index}
            dataNumber={index}
            resolveTaskLimit={resolveDate}
            responseTaskLimit={ticketDate}
            iUser={auth()?.iUser}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default Create;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;
