import TnC from "../../components/More/TnC";

const TermAndConditions = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBlock: 40,
        paddingInline: 30,
      }}
    >
      <TnC />
    </div>
  );
};

export default TermAndConditions;
