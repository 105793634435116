import { message } from "antd";
import { RefObject, useEffect, useState } from "react";
import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  isVisible: false,
};

const { useGlobalState: useGlobalIntersectionState } = createGlobalState(initialState);

export const useIntersection = (
  element: RefObject<Element> | null | undefined,
  rootMargin: string
): boolean => {
  const [localIsVisible, setLocalIsVisible] = useState(false);
  const [, setIsVisible] = useGlobalIntersectionState("isVisible");

  useEffect(() => {
    console.log("useIntersection");
    const current = element?.current;
    if (!current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        const isVisible = entry.isIntersecting;
        setLocalIsVisible(isVisible);
        setIsVisible(isVisible);
      },
      { rootMargin }
    );

    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [element, rootMargin, setIsVisible]);

  return localIsVisible;
};
