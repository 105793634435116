import { InboxOutlined } from "@ant-design/icons";
import styled from "styled-components";

const NoNotification = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
      }}
    >
      <InboxOutlined style={{ fontSize: 50, marginBottom: 10 }} />
      <TextBold>No Notification Yet</TextBold>
      <Text>{"You haven't received any updates at this time."}</Text>
    </div>
  );
};

export default NoNotification;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 5px;
  text-align: center;
`;
