import { useEffect, useState } from "react";
import { apiGetNameById } from "../../api/user";
import { TaskProps } from "../../types/task.type";
import styled from "styled-components";
import { apiGetNameRelayById } from "../../api/relay";
import StatusTask from "../MyTask/StatusTask";

const NameRelay = (props: { iUser?: number; iRelay?: number, vName?:string, isRelay: boolean }) => {
  const [targetName, setTargetName] = useState<string>();
  // useEffect(() => {
  //   fetchTargetName();
  // }, []);
  useEffect(() => {}, [targetName]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      {props.isRelay ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ whiteSpace: "nowrap", marginLeft: -10 }}>
            <StatusTask vName="Contact Center" />
          </div>
          <Text>{`${props.vName? props.vName : ""}`}</Text>
        </div>
      ) : (
        <Text>{`${props.vName ? props.vName : ""}`}</Text>
      )}
    </div>
  );
};

export default NameRelay;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
