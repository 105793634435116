import React, { useEffect, useState } from "react";
import { Table, Row, Select, Col, Input, message, Button } from "antd";
import type { TableColumnsType } from "antd";
import styled from "styled-components";
import useFetchList from "../../hooks/useFetchList";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import { TaskProps } from "../../types/task.type";
import { useAuthUser } from "react-auth-kit";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { ChevronLeft, Circle } from "react-feather";
import { theme } from "../../assets/theme";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  getStatusColor,
  getStatusDisplayString,
  getStatusString,
} from "../../helpers/getDisplayTags";
import SLALevel from "../../components/MyTask/SLALevel";
import useDraft from "../../hooks/useDraft";
import AttachmentRelay from "../../components/Relay/Attachments";
import { parse } from "path";
import { TicketProps } from "../../types/ticket.type";
import { httpRequest } from "../../helpers/api";
import MyTask from "../myTask";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const Relay = () => {
  const navigate = useNavigate();
  const columns: TableColumnsType<TicketProps> = [
    {
      title: "Ticket ID",
      dataIndex: "ticketName",
      key: "ticketName",
      width: "150px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>{`${record.ipTicket.toUpperCase()}`}</Text>
        </div>
      ),
    },
    {
      title: "Ticket",
      dataIndex: "ticketName",
      key: "ticketName",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>{`${record.vName}`}</Text>
        </div>
      ),
    },
    {
      title: "SLA Level",
      dataIndex: "ticketName",
      key: "ticketName",
      width: "100px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <SLALevel level={record.jSLA?.level} />
        </div>
      ),
    },
    {
      title: "Response Time Limit",
      dataIndex: "address",
      key: "6",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>
            {dayjs(record.dLimitResponse).format("HH:mm, DD MMM YYYY")}
          </Text>
        </div>
      ),
    },
    {
      title: "Resolve Time Limit",
      dataIndex: "address",
      key: "7",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>
            {dayjs(record.dLimitResolve).format("HH:mm, DD MMM YYYY")}
          </Text>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "address",
      key: "5",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>{`${record.iUserOwner}`}</Text>
        </div>
      ),
    },
  ];

  const auth = useAuthUser();
  const [slaLevel, setSlaLevel] = useState([
    "CRITICAL",
    "HIGH",
    "NORMAL",
    "LOW",
  ]);
  const {attachTicket, setAttachTicket} = useDraft();
  const [organization, setOrganization] = useState(0);
  const [sortedBy, setSortedBy] = useState(1);
  const [indexSelected, setIndexSelected] = useState(-1);
  const [myTask, setMyTask] = useState<TaskProps>();
  const taskSelected = window.location.pathname.split("/").pop();
  const [selectedTicket, setSelectedTicket] = useState(0);

  const { data: userOrganizations } = useFetchList<OrganizationUserProps>({
    endpoint: "organization-users",
    initialQuery: { iUser: auth()?.iUser },
  });
  console.log("userOrg", userOrganizations);

  const { data: myTasks } = useFetchList<TaskProps>({
    endpoint: "tasks/custom/my-tasks",
  });

  useEffect(() => {
    const selectedTask = myTasks?.find((item) => item.ipTask === taskSelected);
    setMyTask(selectedTask);
    console.log("selectedTask", selectedTask);
  }, [taskSelected, myTasks]);

  const {
    data: ticketData,
    fetchList: fetchTask,
    setQuery: taskQuery,
  } = useFetchList<TicketProps>({
    endpoint: "tickets/custom/my-tickets",
    initialQuery: {
      jSLA: slaLevel,
      sortBy: sortedBy,
      iOrganization: organization,
    },
  });
  console.log("taskData", ticketData);
  const getOrgName = (id: number) => {
    const org = userOrganizations.find(
      (item) => item.organization.iOrganization === id
    );
    return org?.organization.vName;
  };
  useEffect(() => {
    console.log("org", organization);
    taskQuery((oldval) => ({
      ...oldval,
      jSLA: slaLevel,
      sortBy: sortedBy,
      iOrganization: organization,
    }));
    fetchTask();
    // eslint-disable-next-line
  }, [slaLevel, sortedBy, organization, indexSelected]);

  const handleAttachTicket = async () => {
    try {
      if (selectedTicket === 0) {
        message.error("Please select a ticket");
        return;
      }
      navigate(-1);
    } catch {
      message.error("Failed to attach ticket");
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} />
          <Title>Attach Ticket</Title>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={indexSelected === -1}
            onClick={() => {
              handleAttachTicket();
            }}
          >
            Attach Ticket ID
          </Button>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        <Row
          style={{ gap: 20, marginBlock: 40, justifyContent: "space-evenly" }}
        >
          <Col span={15}>
            <Text style={{ color: "gray", marginBottom: 10 }}>Search</Text>
            <Input placeholder="Search by Ticket ID" />
          </Col>
          <Col span={8}>
            <Text style={{ color: "gray" }}>SLA level</Text>
            <Select
              mode="tags"
              style={{ marginTop: 10 }}
              defaultValue={slaLevel}
              placeholder="Status"
              onChange={(value) => setSlaLevel(value)}
            >
              <Select.Option value="CRITICAL">
                <WarningOutlined style={{ color: "red", marginRight: 8 }} />
                Critical
              </Select.Option>
              <Select.Option value="HIGH">
                <ExclamationCircleOutlined
                  style={{ color: theme.colors.warning, marginRight: 8 }}
                />
                High
              </Select.Option>
              <Select.Option value="NORMAL">
                <Circle
                  color={theme.colors.warning}
                  fill={theme.colors.warning}
                  size={10}
                  style={{ marginRight: 8 }}
                />
                Normal
              </Select.Option>
              <Select.Option value="LOW">
                <Circle
                  color={theme.colors.green500}
                  fill={theme.colors.green500}
                  size={10}
                  style={{ marginRight: 8 }}
                />
                Low
              </Select.Option>
            </Select>
          </Col>
        </Row>
      </div>
      <div style={{ marginInline: 20 }}>
        <Table
          columns={columns}
          dataSource={ticketData}
          scroll={{ x: "max-content" }}
          rowClassName={(record, index) =>
            index === indexSelected ? "table-row-selected" : "table-row-light"
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                setIndexSelected(rowIndex ? rowIndex : 0);
                setSelectedTicket(record.iTicket);
                setAttachTicket([...attachTicket, record]);
                message.success(`Ticket ${record.vName} selected`);
              },
            };
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default Relay;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
