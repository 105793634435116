import React from "react";
import styled from "styled-components";
import SLALevelIcon from "../MyTask/SLALevelIcon";
import {
  CalendarOutlined,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { TaskProps } from "../../types/task.type";
import { useNavigate } from "react-router-dom";

const TaskList = (props: {
  task?: TaskProps;
  resolveTimeLimit?: Date | string;
}) => {
  const navigate = useNavigate();
  const status = props.task?.iResult;
  const formatDate = (date: Date | string): string => {
    const formattedDate = dayjs(date).format("HH:mm, MMM D");
    return formattedDate;
  };
  return (
    <div
      onClick={() => navigate("/my-task/" + props.task?.ipTask)}
      style={{ marginBlock: 30 }}
    >
      <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
        <Status color="#404852" backgroundColor="#E6E9EF">
          {props.task?.metaUser?.vNameOrganizationTarget?.vName || "Personal"}
        </Status>
        {status === 3 ? (
          <Status color="#FF3A3A" backgroundColor="#FFE3E3">
            Rejected
          </Status>
        ) : status === 7 ? (
          <Status color="#FF3A3A" backgroundColor="#FFE3E3">
            Returned
          </Status>
        ) : status === 8 ? (
          <Status backgroundColor="#D8EDFF" color="#3D98E7">
            Fowarded
          </Status>
        ) : (
          (status === 621 || status === 622 || status === 69) && (
            <Status backgroundColor="#F0D8FF" color="#A14DD6">
              In Review
            </Status>
          )
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <SLALevelIcon level={props.task?.jSLA?.level} />
        <TextBold>{props.task?.vName}</TextBold>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <CalendarOutlined style={{ fontSize: "18px" }} />
        <Text>
          {props.resolveTimeLimit
            ? `Due ${formatDate(props.resolveTimeLimit)}`
            : "No due date"}
        </Text>
        <LinkOutlined style={{ marginLeft: 5, fontSize: "18px" }} />
        <Text>{props.task?.jAttachment?.files?.length | 0}</Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <UserOutlined style={{ fontSize: "18px" }} />
        <Text>{`From ${props.task?.metaUser?.vNameUserCreate}`}</Text>
      </div>
    </div>
  );
};

export default TaskList;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0px 10px;
  display: inline-block;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
