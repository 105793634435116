import Dexie, { Table } from "dexie";
import { useLiveQuery as UseLiveQuery } from "dexie-react-hooks";
import { TicketProps } from "../types/ticket.type";
import { TaskProps } from "../types/task.type";
import { OrganizationUserProps } from "../types/organizationUser.type";
import { message } from "antd";
import Contact from "../screens/contact";
import TaskHistory from "../components/MyTask/TaskHistory";
import { TaskHistoryProps } from "../types/taskHistory.type";
import { ContactProps } from "../types/contact.type";
import { equal } from "assert";
import {
  ContactArray,
  UserArrayOffline,
  MyContactProps,
  RelayArrayOffline,
  RelayContact,
} from "../types/myContact.type";
import { clear } from "console";
import { ca } from "date-fns/locale";
import { apiSyncMyTask, apiSyncRelayTasks } from "../api/task";
import { apiSyncMyTicket } from "../api/ticket";
import { apiSyncMyOrganization } from "../api/organization";
import { useAuthUser } from "react-auth-kit";
import { apiSyncMyContact } from "../api/contact";



export class OfflineData extends Dexie {
  myTickets!: Table<TicketProps>;
  myTasks!: Table<TaskProps>;
  organizationUsers!: Table<OrganizationUserProps>;
  relayTasks!: Table<TaskProps>;
  taskHistory!: Table<TaskHistoryProps>;
  myContactUser!: Table<ContactArray>;
  myContactRelay!: Table<RelayContact>;
  constructor() {
    super("Task-Management");
    this.version(1).stores({
      myTickets:
        "++id, iTicket, ipTicket, vName, dLimitResponse, dLimitResolve, vDescription, jSLA, iStatus, tasks, histories, userOwner",
      myTasks:
        "++id, iTask, ipTask, vName, iTicket, dCreatedLocal, dCreatedServer, iUserCreate, iRelayCreate, vDescription, jAttachment, jSLA, dLimitResolve, dLimitResponse, iResult, isMissSchedule, iOrganizationTarget, userCreate, userTarget, relayCreate, relayTarget, ticket, histories",
      organizationUsers:
        "iUser, iOrganization, jDetails, dUpdatedServer, dCreatedServer, organization",
      relayTasks:
        "++id, iTask, ipTask, vName, iTicket, dCreatedLocal, dCreatedServer, iUserCreate, iRelayCreate, vDescription, jAttachment, jSLA, dLimitResolve, dLimitResponse, iResult, isMissSchedule, iOrganizationTarget, userCreate, userTarget, relayCreate, relayTarget, ticket, histories",
      taskHistory:
        "++id, iHistory, iRelayCreate, iRelayTarget, iTask, iTicket, iUserCreate, iUserTarget, jData, parentTask, relayCreate, relayTarget, userCreate, userTarget, vAction, vFullName, vNotedCreated",
      myContactUser: "++id, iUserContact, iOrganization, user, organization",
      myContactRelay: "++id, iRelayContact, relay",
    });
  }
}

export const offlineDB = new OfflineData();

export async function syncOfflineData(iUser?: number) {
  try {
    clearOfflineData();
    apiSyncMyTicket();
    apiSyncMyTask();
    apiSyncMyOrganization(iUser ? iUser : 0);
    apiSyncRelayTasks();
    apiSyncMyContact();
  } catch (err) {
    console.error(err);
  }
}

export function clearOfflineData() {
  Dexie.delete("Task-Management");
}

export function useMyTickets() {
  return UseLiveQuery(() => offlineDB.myTickets.toArray());
}
export function getIpTask(ipTask: string){
  return UseLiveQuery(() =>
    offlineDB.myTasks.where("ipTask").equals(ipTask).first()
  );
}
export function useMyTaskHistory() {
  return UseLiveQuery(() => offlineDB.taskHistory.toArray());
}

export function getAllRelayTasks() {
  return UseLiveQuery(() => offlineDB.relayTasks.toArray());
}

export function getMyTicket(ipTicket: string) {
  return UseLiveQuery(() =>offlineDB.myTickets.where("ipTicket").equals(ipTicket));
}

export function getMyTask(ipTask: string) {
  return UseLiveQuery(() => offlineDB.myTasks.where("ipTask").equals(ipTask));
}
export function getTaskHistory(iTask: number) {
  if (iTask === -2) {
    return [];
  } else {
    return UseLiveQuery(() =>
      offlineDB.taskHistory.where("iTask").equals(iTask).toArray()
    );
  }
}

export function useUsersOrganization(iUser: number) {
  return UseLiveQuery(() =>
    offlineDB.organizationUsers.where("iUser").equals(iUser).toArray()
  );
}

export function useOrganizationUsers() {
  return UseLiveQuery(() => offlineDB.organizationUsers.toArray());
}

export function getOrganizationUser(iUser: number) {
  return UseLiveQuery(() =>
    offlineDB.organizationUsers.where("iUser").equals(iUser).toArray()
  );
}
export function useMyTasks() {
  return UseLiveQuery(() => offlineDB.myTasks.toArray());
}

export function getContactUser() {
  return UseLiveQuery(() => offlineDB.myContactUser.toArray());
}

export function getContactRelay() {
  return UseLiveQuery(() => offlineDB.myContactRelay.toArray());
}

export function getContacts() {
  return {
    users: getContactUser(),
    relay: getContactRelay(),
  };
}

export function addMyTicket(myTickets: TicketProps) {
  try {
    // Check if the myTicket already exists in the offlineDB
    let myTicketsExists = false;
    // const datas = UseLiveQuery(() => offlineDB.myTickets.toArray());
    // for (const existingmyTicket of datas ? datas : []) {
    //     if (existingmyTicket.categoryId === myTicket.categoryId) {
    //         myTicketExists = true;
    //         break;
    //     }
    // }

    // If the myTicket already exists, log an error and return
    if (myTicketsExists) {
      console.log("myTicket already exists in offlineDB");
      return;
    }

    // If the myTicket doesn't exist, add it to the offlineDB
    offlineDB.myTickets.add(myTickets);
  } catch (err) {
    console.error(err);
  }
}

export function addMyTask(myTasks: TaskProps) {
  try {
    let myTasksExists = false;
    if (myTasksExists) {
      console.log("myTask already exists in offlineDB");
      return;
    }
    offlineDB.myTasks.add(myTasks);
  } catch (err) {
    console.error(err);
  }
}

export function addRelayTask(myTasks: TaskProps) {
  try {
    let myTasksExists = false;
    if (myTasksExists) {
      console.log("myTask already exists in offlineDB");
      return;
    }
    offlineDB.relayTasks.add(myTasks);
  } catch (err) {
    console.error(err);
  }
}

export function addOrganizationUsers(organizationUsers: OrganizationUserProps) {
  try {
    let myUserOrganizationExists = false;
    if (myUserOrganizationExists) {
      console.log("myUserOrganization already exists in offlineDB");
      return;
    }
    offlineDB.organizationUsers.add(organizationUsers);
  } catch (err) {
    console.error(err);
  }
}

export function addTaskHistory(history: TaskHistoryProps) {
  try {
    let historyExist = false;
    if (historyExist) {
      console.log("History Exists in offlineDB");
      return;
    }
    offlineDB.taskHistory.add(history);
  } catch (err) {
    console.error(err);
  }
}

export function addContact(contact: MyContactProps) {
  try {
    console.log("contact adding", contact);
    let contactExist = false;
    if (contactExist) {
      console.log("Contact exist in offlineDB");
      return;
    }
    contact.users.forEach((element: ContactArray) => {
      offlineDB.myContactUser.add({
        iOrganization: element.iOrganization,
        iUserContact: element.iUserContact,
        user: element.user,
        organization: element.organization,
      });
    });
    contact.relay.forEach((element: RelayContact) => {
      offlineDB.myContactRelay.add({
        iRelayContact: element.iRelayContact,
        relay: element.relay,
      });
      console.log("adding ...", element);
    });
  } catch (err) {
    message.error("Error adding contact");
    console.error(err);
  }
}
