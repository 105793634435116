import styled from "styled-components";
import {
  apiNotificationMarkAllAsRead,
  apiNotificationMarkAsRead,
} from "../../api/notification";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NotificationItem from "./NotificationItem";
import NoNotification from "./NoNotification";

type Props = {
  data: {
    iNotification: number;
    iTo: number;
    vTitle: string;
    vMessage: string;
    dCreatedServer: Date | string;
    iRead: boolean;
    jMeta: { ipTask: string };
  }[];
  fetchList: () => void;
  setIsNotificationOpen: (status: boolean) => void;
};

const NotificationList = (props: Props) => {
  const navigate = useNavigate();

  const markAllAsRead = async () => {
    await apiNotificationMarkAllAsRead();
    props.fetchList();
  };

  const markAsRead = async (notificationId: string, toId: string) => {
    await apiNotificationMarkAsRead(notificationId, toId);
    props.fetchList();
  };

  useEffect(() => {}, [props.data, props.fetchList]);

  return (
    <div
      style={{
        position: "relative",
        width: 375,
        maxHeight: "60vh",
        overflow: "scroll",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingInline: 20,
          paddingTop: 20,
          paddingBottom: 5,
        }}
      >
        <TextBold
          onClick={() => {
            props.setIsNotificationOpen(false);
            navigate("/notification");
          }}
        >
          Notification
        </TextBold>
        <TextBold
          onClick={() => markAllAsRead()}
          style={{
            color: props?.data?.find((item) => item.iRead === false)
              ? "#045AFF"
              : "grey",
            opacity: props?.data?.find((item) => item.iRead === false)
              ? 1
              : 0.5,
            cursor: props?.data?.find((item) => item.iRead === false)
              ? "pointer"
              : "not-allowed",
          }}
        >
          Mark All as Read
        </TextBold>
      </div>
      <div style={{ paddingTop: 10, paddingInline: 10 }}>
        {props?.data?.length > 0 ? (
          props.data.map((item) => (
            <div
              onClick={() => {
                props.setIsNotificationOpen(false);
                markAsRead(item.iNotification.toString(), item.iTo.toString());
                navigate("/my-task/" + item.jMeta.ipTask);
              }}
            >
              <NotificationItem
                vTitle={item.vTitle}
                vMessage={item.vMessage}
                iRead={item.iRead}
                dCreatedServer={item.dCreatedServer}
              />
            </div>
          ))
        ) : (
          <NoNotification />
        )}
      </div>
      {props?.data?.length > 0 && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 20,
            paddingTop: 20,
          }}
        >
          <TextBold
            onClick={() => {
              props.setIsNotificationOpen(false);
              navigate("/notification");
            }}
            style={{
              color: "#045AFF",
            }}
          >
            ViewAll
          </TextBold>
        </div>
      )}
    </div>
  );
};

export default NotificationList;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 5px;
`;
