import { Avatar, Divider, Menu } from "antd";
import React, { useEffect, useState } from "react";
import {
  CheckCircle,
  ChevronDown,
  ChevronRight,
  Layers,
  Clipboard,
  Eye,
} from "react-feather";
import styled from "styled-components";
import { TaskProps } from "../../types/task.type";
import { isDoneStatus } from "../../helpers/isDoneStatus";
import TaskList from "./TaskList";

type Props = {
  tasks?: TaskProps[];
  resolveTimeLimit?: Date | string;
};

const { Item } = Menu;

const TicketSummaryList = (props: Props) => {
  const [submenuOpen1, setSubmenuOpen1] = useState(false);
  const [submenuOpen2, setSubmenuOpen2] = useState(false);
  const [submenuOpen3, setSubmenuOpen3] = useState(false);
  const [submenuOpen4, setSubmenuOpen4] = useState(false);
  const [taskInProgress, setTaskInProgress] = useState<TaskProps[]>(
    props.tasks?.filter((item) => item.iResult === 2 || item.iResult === 7) ||
      []
  );
  const [taskNeedApproval, setTaskNeedApproval] = useState<TaskProps[]>(
    props.tasks?.filter((item) => item.iResult === 1) || []
  );
  const [taskInReview, setTaskInReview] = useState<TaskProps[]>(
    props.tasks?.filter(
      (item) =>
        item.iResult === 621 || item.iResult === 622 || item.iResult === 69
    ) || []
  );
  const [taskDone, setTaskDone] = useState<TaskProps[]>(
    props.tasks?.filter((item) => isDoneStatus(item.iResult)) || []
  );

  const handleMenuClick = (key: number) => {
    if (key === 1) {
      setSubmenuOpen1(!submenuOpen1);
    } else if (key === 2) {
      setSubmenuOpen2(!submenuOpen2);
    } else if (key === 3) {
      setSubmenuOpen3(!submenuOpen3);
    } else if (key === 4) {
      setSubmenuOpen4(!submenuOpen4);
    }
  };

  useEffect(() => {
    setTaskInProgress(
      props.tasks?.filter((item) => item.iResult === 2 || item.iResult === 7) ||
        []
    );
    setTaskNeedApproval(
      props.tasks?.filter((item) => item.iResult === 1) || []
    );
    setTaskInReview(
      props.tasks?.filter(
        (item) =>
          item.iResult === 621 || item.iResult === 622 || item.iResult === 69
      ) || []
    );
    setTaskDone(
      props.tasks?.filter((item) => isDoneStatus(item.iResult)) || []
    );
  }, [props.tasks]);

  return (
    <React.Fragment>
      <StyledMenu style={{ backgroundColor: "white" }}>
        <TicketSummaryDivider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
          onClick={() => handleMenuClick(2)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#C8930B",
                backgroundColor: "#FFF5D8",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<Clipboard size={25} />}
            />
            <TextBold style={{ marginInline: 15 }}>Need Approval</TextBold>
            <Text>
              {taskNeedApproval
                ? `${taskNeedApproval.length} Tasks`
                : "No Task"}
            </Text>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {submenuOpen2 ? (
              <ChevronDown size={25} style={{ marginLeft: 10 }} />
            ) : (
              <ChevronRight size={25} style={{ marginLeft: 10 }} />
            )}
          </div>
        </div>
        <TicketSummaryDivider />
        {submenuOpen2 &&
          taskNeedApproval.map((item) => (
            <div>
              <StyledSubMenu>
                <TaskList
                  task={item}
                  resolveTimeLimit={
                    item.dLimitResolve || props.resolveTimeLimit
                  }
                />
              </StyledSubMenu>
              <TicketSummaryDivider />
            </div>
          ))}
      </StyledMenu>

      <StyledMenu style={{ backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
          onClick={() => handleMenuClick(1)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#045AFF",
                backgroundColor: "#E9F5FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<Layers size={25} />}
            />
            <TextBold style={{ marginInline: 15 }}>In Progress</TextBold>
            <Text>
              {taskInProgress ? `${taskInProgress.length} Tasks` : "No Task"}
            </Text>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {submenuOpen1 ? (
              <ChevronDown size={25} style={{ marginLeft: 10 }} />
            ) : (
              <ChevronRight size={25} style={{ marginLeft: 10 }} />
            )}
          </div>
        </div>
        <TicketSummaryDivider />
        {submenuOpen1 &&
          taskInProgress.map((item) => (
            <div>
              <StyledSubMenu>
                <TaskList
                  task={item}
                  resolveTimeLimit={
                    item.dLimitResolve || props.resolveTimeLimit
                  }
                />
              </StyledSubMenu>
              <TicketSummaryDivider />
            </div>
          ))}
      </StyledMenu>

      <StyledMenu style={{ backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
          onClick={() => handleMenuClick(3)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#A14DD6",
                backgroundColor: "#F0D8FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<Eye size={25} />}
            />
            <TextBold style={{ marginInline: 15 }}>In Review</TextBold>
            <Text>
              {taskInReview ? `${taskInReview.length} Tasks` : "No Task"}
            </Text>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {submenuOpen3 ? (
              <ChevronDown size={25} style={{ marginLeft: 10 }} />
            ) : (
              <ChevronRight size={25} style={{ marginLeft: 10 }} />
            )}
          </div>
        </div>
        <TicketSummaryDivider />
        {submenuOpen3 &&
          taskInReview.map((item) => (
            <div>
              <StyledSubMenu>
                <TaskList
                  task={item}
                  resolveTimeLimit={
                    item.dLimitResolve || props.resolveTimeLimit
                  }
                />
              </StyledSubMenu>
              <TicketSummaryDivider />
            </div>
          ))}
      </StyledMenu>

      <StyledMenu style={{ backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
          onClick={() => handleMenuClick(4)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              shape="square"
              size={40}
              style={{
                color: "#50D831",
                backgroundColor: "#E3FDDD",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<CheckCircle size={25} />}
            />
            <TextBold style={{ marginInline: 15 }}>Done</TextBold>
            <Text>{taskDone ? `${taskDone.length} Tasks` : "No Task"}</Text>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {submenuOpen4 ? (
              <ChevronDown size={25} style={{ marginLeft: 10 }} />
            ) : (
              <ChevronRight size={25} style={{ marginLeft: 10 }} />
            )}
          </div>
        </div>
        <TicketSummaryDivider />
        {submenuOpen4 &&
          taskDone.map((item) => (
            <div>
              <StyledSubMenu>
                <TaskList
                  task={item}
                  resolveTimeLimit={
                    item.dLimitResolve || props.resolveTimeLimit
                  }
                />
              </StyledSubMenu>
              <TicketSummaryDivider />
            </div>
          ))}
      </StyledMenu>
    </React.Fragment>
  );
};

export default TicketSummaryList;

const StyledMenu = styled(Menu)`
  background-color: white !important;
  color: black !important;
  border: none !important;
`;

const StyledSubMenu = styled(Item)`
  height: auto !important;
  margin: 0px !important;
  padding: 0px !important;
  background-color: white !important;
  color: black !important;

  &:hover {
    background-color: white !important;
    color: black !important;
  }

  &:active {
    background-color: white !important;
    color: black !important;
  }

  &:focus {
    background-color: white !important;
    color: black !important;
  }
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TicketSummaryDivider = styled(Divider)`
  padding: 0px;
  margin: 10px 0px;
`;
