import React from "react";
import { Avatar, Card, Col, Divider, Row, Select } from "antd";
import styled from "styled-components";
import { AppstoreOutlined } from "@ant-design/icons";
import { Layers } from "react-feather";
import ListDashboard from "../../components/Dashboard/ListDashboard";
import { useAuthUser } from "react-auth-kit";
import useFetchList from "../../hooks/useFetchList";
import { useNavigate } from "react-router-dom";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import { httpRequest } from "../../helpers/api";
import CalendarComponent from "../../components/Dashboard/Calendar";

type OrganizationData = {
  iOrganization: number;
  ipOrganization: string;
  organization: string;
  myTickets?: { [key: number]: number };
  myTasks?: { [key: number]: number };
};

const DashboardDetail: React.FC = () => {
  const navigate = useNavigate();
  const organizationSelected = window.location.pathname.split("/").pop();
  // const [navigateQuery, setNavigateQuery] = React.useState("");
  const auth = useAuthUser();
  const [organizations, setOrganizations] = React.useState<OrganizationData[]>(
    []
  );

  const [organizationData, setOrganizationData] =
    React.useState<OrganizationData>();

  const { data: userOrganizations } = useFetchList<OrganizationUserProps>({
    endpoint: "organization-users",
    initialQuery: { iUser: auth()?.iUser },
  });

  const { data: myTickets } = useFetchList<any>({
    apiRequest: httpRequest,
    endpoint: "tickets/custom/my-tickets",
    initialQuery: {
      limit: 0,
      offset: 0,
    },
  });

  React.useEffect(() => {
    let myTicketCount: { [key: number]: { [key: number]: number } } = {};
    let myTaskCount: { [key: number]: { [key: number]: number } } = {};
    let orgTemp: OrganizationData[] = [];
    let personalTicketCount: { [key: number]: number } = {};
    let personalTaskCount: { [key: number]: number } = {};

    myTickets.forEach((ticket) => {
      const allTargets = ticket.tasks.map(
        (task: { iOrganizationTarget: number }) =>
          task.iOrganizationTarget ? task.iOrganizationTarget : 0
      );

      const uniqueTargetsSet = new Set<number>(allTargets);
      const uniqueTargetsArray = Array.from(uniqueTargetsSet);
      for (let i = 0; i < uniqueTargetsArray.length; i++) {
        const organizationId = uniqueTargetsArray[i];
        const taskStatus = ticket?.iStatus;

        if (!organizationId) {
          if (!personalTicketCount[taskStatus]) {
            personalTicketCount[taskStatus] = 1;
          } else {
            personalTicketCount[taskStatus]++;
          }
        }

        if (!myTicketCount[organizationId]) {
          myTicketCount[organizationId] = {};
        }

        if (!myTicketCount[organizationId][taskStatus]) {
          myTicketCount[organizationId][taskStatus] = 1;
        } else {
          myTicketCount[organizationId][taskStatus]++;
        }
      }

      ticket.tasks.forEach(
        (task: { iOrganizationTarget: number; iResult: number }) => {
          const organizationId = task.iOrganizationTarget;
          const taskResult = task.iResult;

          if (!organizationId) {
            if (!personalTaskCount[taskResult]) {
              personalTaskCount[taskResult] = 1;
            } else {
              personalTaskCount[taskResult]++;
            }
          }

          if (!myTaskCount[organizationId]) {
            myTaskCount[organizationId] = {};
          }

          if (!myTaskCount[organizationId][taskResult]) {
            myTaskCount[organizationId][taskResult] = 1;
          } else {
            myTaskCount[organizationId][taskResult]++;
          }
        }
      );
    });

    orgTemp.push({
      iOrganization: 0,
      ipOrganization: "personal",
      organization: "Personal",
      myTickets: personalTicketCount,
      myTasks: personalTaskCount,
    });

    userOrganizations.map((item) =>
      orgTemp.push({
        iOrganization: item.iOrganization,
        ipOrganization: item.organization.ipOrganization,
        organization: item.organization.vName,
        myTickets: myTicketCount[item.iOrganization],
        myTasks: myTaskCount[item.iOrganization],
      })
    );
    setOrganizations(orgTemp);
  }, [userOrganizations, myTickets]);

  React.useEffect(() => {
    setOrganizationData(
      organizations.find((org) => org.ipOrganization === organizationSelected)
    );
  }, [organizationSelected, organizations]);

  // const handleNavigationQuery = (input?: string) => {
  //   if (navigateQuery === "") {
  //     setNavigateQuery("?organization=" + organizationSelected);
  //   }
  //   const newNavigateQuery = input
  //     ? navigateQuery + "&" + input
  //     : navigateQuery;
  //   setNavigateQuery(newNavigateQuery);
  // };
  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div>
            <Title>Project Summary</Title>
          </div>
          <div>
            <SubText style={{ color: "gray" }}>Organization</SubText>
            <Select
              style={{ width: 250, marginTop: 10 }}
              defaultValue={organizationSelected}
              allowClear
              placeholder="Status"
              onChange={(value) => navigate(`/dashboard/${value}`)}
            >
              {organizations.map((item) => (
                <Select.Option value={item.ipOrganization}>
                  {item.organization}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <Row>
          <Col span={12}>
            <Card
              style={{ marginRight: 10, cursor: "pointer" }}
              bordered={true}
              // onClick={() => {navigate("/my-task" + navigateQuery)}}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                }}
                onClick={() =>
                  navigate(
                    `/my-task?organization=${organizationData?.iOrganization}`
                  )
                }
              >
                <Avatar
                  shape="square"
                  size={35}
                  style={{
                    color: "#045AFF",
                    backgroundColor: "#E9F5FF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={<Layers size={20} />}
                />
                <Title style={{ marginLeft: 10 }}>My Task</Title>
              </div>
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#FFC115"
                text="Need Approval"
                totalTasks={organizationData?.myTasks?.[1]}
                organizationSelected={organizationSelected}
                navQuery={`/my-task?status=1&organization=${organizationData?.iOrganization}`}
                onClick={() => {}}
              />
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#3D98E7"
                text="In Progress"
                navQuery={`/my-task?status=2&organization=${organizationData?.iOrganization}`}
                totalTasks={organizationData?.myTasks?.[2]}
              />
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#50D831"
                text="Done"
                navQuery={`/my-task?status=9&organization=${organizationData?.iOrganization}`}
                totalTasks={
                  organizationData?.myTasks
                    ? Object.values(organizationData.myTasks).reduce(
                        (total, count) => total + count,
                        0
                      ) -
                      ((organizationData?.myTasks?.[1] || 0) +
                        (organizationData?.myTasks?.[2] || 0) +
                        (organizationData?.myTasks?.[10] || 0))
                    : 0
                }
              />
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#EB2222"
                text="Missed Schedule"
                navQuery={`/my-task?status=10&organization=${organizationData?.iOrganization}`}
                totalTasks={organizationData?.myTasks?.[10]}
              />
              <Divider style={{ marginBlock: 15 }} />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              style={{ cursor: "pointer" }}
              bordered={true}
              onClick={() => navigate("/my-ticket")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar
                  shape="square"
                  size={35}
                  style={{
                    color: "#FFC72D",
                    backgroundColor: "#FEF0C9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={<AppstoreOutlined size={20} />}
                />
                <Title style={{ marginLeft: 10 }}>My Ticket</Title>
              </div>
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#FFC115"
                text="Open"
                totalTickets={organizationData?.myTickets?.[1]}
                isTicket={true}
                navQuery="/my-ticket?status=1"
              />
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#3D98E7"
                text="Need Approval Complete"
                totalTickets={organizationData?.myTickets?.[2]}
                isTicket={true}
                navQuery="/my-ticket?status=2"
              />
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#50D831"
                text="Closed Open"
                totalTickets={organizationData?.myTickets?.[3]}
                isTicket={true}
                navQuery="/my-ticket?status=3"
              />
              <Divider style={{ marginBlock: 15 }} />
              <ListDashboard
                color="#EB2222"
                text="Closed"
                totalTickets={organizationData?.myTickets?.[4]}
                isTicket={true}
                navQuery="/my-ticket?status=4"
              />
              <Divider style={{ marginBlock: 15 }} />
            </Card>
          </Col>
        </Row>
      </div>
      <CalendarComponent iOrganization={organizationData?.iOrganization || 0} />
    </React.Fragment>
  );
};
export default DashboardDetail;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const SubText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
