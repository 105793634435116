import { Avatar, Button, Col, Divider, Row, message } from "antd";
import { ChevronRight } from "react-feather";
import styled from "styled-components";
import dayjs from "dayjs";
import { TaskProps } from "../../types/task.type";
import copy from "copy-to-clipboard";
import { httpRequest } from "../../helpers/api";
import {
  BaseResponseProps,
  generateInitialFromName,
} from "@qlibs/react-components";
import { useEffect, useState } from "react";
import SLALevel from "./SLALevel";
import { useNavigate } from "react-router-dom";
import { apiGetOrganization } from "../../api/organization";
import { apiGetNameById } from "../../api/user";
import { apiGetNameRelayById } from "../../api/relay";
import { generateColor } from "../../helpers/constant";
import { set } from "date-fns";

type Props = {
  myTask?: TaskProps;
};

const TaskInformation = (props: Props) => {
  const navigate = useNavigate();
  const [ticketName, setTicketName] = useState("");
  const [ipTicket, setIpTicket] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [responseTimeLimit, setResponseTimeLimit] = useState<Date | string>();
  const [resolveTimeLimit, setResolveTimeLimit] = useState<Date | string>();
  const [assignerName, setAssignerName] = useState("");
  const [assignerStatus, setAssignerStatus] = useState(false);
  const [assigneeName, setAssigneeName] = useState("");
  const [assigneeStatus, setAssigneeStatus] = useState(false);
  const fetchTicket = async () => {
    try {
      const data = await httpRequest.get<
        BaseResponseProps<{
          vName: string;
          ipTicket: string;
          dLimitResolve: Date | string;
          dLimitResponse: Date | string;
        }>
      >(process.env.REACT_APP_BASE_URL + "/tickets/" + props.myTask?.iTicket);
      if (data) {
        setTicketName(data.data.payload.vName);
        setIpTicket(data.data.payload.ipTicket);
        setResolveTimeLimit(data.data.payload.dLimitResolve);
        setResponseTimeLimit(data.data.payload.dLimitResponse);
      } else {
        console.log("props offline ", props.myTask);
        setTicketName(props.myTask?.ticket.vName);
        setIpTicket(props.myTask?.ticket.ipTicket);
      }
      return;
    } catch (err) {
      setTicketName(props.myTask?.ticket.vName);
      setIpTicket(props.myTask?.ticket.ipTicket || "");
      console.log(err);
    }
  };

  const fetchOrganization = async () => {
    const org = await apiGetOrganization(props.myTask?.iOrganizationTarget!);
    if (org) {
      setOrganizationName(org);
    }
  };

  const getName = async (iUserCreate?: number, iUserTarget?: number) => {
    if (props.myTask?.relayCreate && props.myTask?.userTarget) {
      setAssignerStatus(true);
      setAssignerName(props.myTask?.relayCreate.vName? props.myTask?.relayCreate.vName : props.myTask.metaUser.vNameUserCreate);
      setAssigneeName(props.myTask?.userTarget.vFullName ? props.myTask?.userTarget.vFullName : props.myTask.metaUser.vNameUserTarget);
    } else if (props.myTask?.userCreate && props.myTask?.relayTarget) {
      setAssignerName(props.myTask?.userCreate.vFullName ? props.myTask?.userCreate.vFullName : props.myTask.metaUser.vNameUserCreate);
      setAssigneeStatus(true);
      setAssigneeName(props.myTask?.relayTarget.vName ? props.myTask?.relayTarget.vName : props.myTask.metaUser.vNameUserTarget);
    } else if (props.myTask?.userCreate && props.myTask?.userTarget) {
      setAssignerName(props.myTask?.userCreate.vFullName ? props.myTask?.userCreate.vFullName : props.myTask?.metaUser.vNameUserCreate);
      setAssigneeName(props.myTask?.userTarget.vFullName ? props.myTask?.userTarget.vFullName : props.myTask?.metaUser.vNameUserTarget);
    } else if (props.myTask?.relayCreate && props.myTask?.relayTarget) {
      setAssignerStatus(true);
      setAssigneeStatus(true);
      setAssignerName(props.myTask?.relayCreate.vName ? props.myTask?.relayCreate.vName : props.myTask?.metaUser.vNameUserCreate);
      setAssigneeName(props.myTask?.relayTarget.vName ? props.myTask?.relayTarget.vName : props.myTask?.metaUser.vNameUserTarget);
    }
  };
  useEffect(() => {
  }, [props.myTask]);

  useEffect(() => {
    fetchTicket();
    fetchOrganization();
    getName(props.myTask?.iUserCreate, props.myTask?.iUserTarget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.myTask]);

  const formatDate = (date: Date | string): string => {
    const formattedDate = dayjs(date).format("DD MMM YYYY, HH:mm");
    return formattedDate;
  };

  const initialName = (name: string) => {
    if (name) {
      return generateInitialFromName(name)?.toUpperCase();
    }
  };
  return (
    <div style={{ marginRight: 20 }}>
      <Row>
        <Col span={8}>
          <Text>Response Time Limit</Text>
        </Col>
        <Col>
          <TextBold>
            {props.myTask?.dLimitResponse
              ? formatDate(props.myTask?.dLimitResponse)
              : responseTimeLimit
              ? formatDate(responseTimeLimit)
              : "Not Set"}
          </TextBold>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>Resolve Time Limit</Text>
        </Col>
        <Col>
          <TextBold>
            {props.myTask?.dLimitResolve
              ? formatDate(props.myTask?.dLimitResolve)
              : resolveTimeLimit
              ? formatDate(resolveTimeLimit)
              : "Not Set"}
          </TextBold>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>Created by</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            style={{ backgroundColor: generateColor(assignerName) }}
          >
            {initialName(assignerName)}
          </Avatar>
          {assignerStatus && (
            <Status backgroundColor="#E6E9EF" color="black">
              Contact Center
            </Status>
          )}
          <TextBold>{assignerName}</TextBold>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>Assignee</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            style={{ backgroundColor: generateColor(assigneeName) }}
          >
            {initialName(assigneeName)}
          </Avatar>
          {assigneeStatus && (
            <Status backgroundColor="#E6E9EF" color="black">
              Contact Center
            </Status>
          )}
          <TextBold>{assigneeName}</TextBold>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
          <Text>Ticket ID</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <TextBold>{`#${ipTicket.toUpperCase()}`}</TextBold>
          <Button
            type="primary"
            onClick={() => {
              if (ipTicket) {
                copy(ipTicket);
                message.success("Success copy Ticket ID");
              }
            }}
          >
            Copy
          </Button>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>Ticket Name</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 10,
          }}
          onClick={() => navigate(`/my-ticket/${ipTicket}`)}
        >
          <TextBold>{ticketName}</TextBold>
          <ChevronRight />
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>Organization</Text>
        </Col>
        <Col>
          <TextBold>{organizationName}</TextBold>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>SLA</Text>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <SLALevel level={props.myTask?.jSLA?.level} />
        </Col>
      </Row>
      <TaskDivider />
    </div>
  );
};

export default TaskInformation;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TaskDivider = styled(Divider)`
  padding: 0px;
  margin: 10px 0px;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 5px;
  padding: 0px 10px;
  display: inline-block;
  text-align: center;
`;
