import { Form, Input, Card, Button, Select, DatePicker, Avatar } from "antd";
import AuthLayout from "../layout/AuthLayout";
import AppVersion from "../../components/AppVersion";
import useAuthApp from "../../hooks/useAuthApp";
import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

type Props = {
  vEmail?: string;
  vMobile?: string;
  vPassword?: string;
  sessionId?: string;
  onSubmit?: (
    data: {
      vEmail: string;
      vMobile: string;
      vPassword: string;
      vFirstName: string;
      vLastName: string;
      vNickName: string;
      iGender: number;
      birthdate: string;
    },
    callback?: () => void
  ) => void;
};

const CreateProfile = (props: Props) => {
  const { isAuthLoading } = useAuthApp();
  const [form] = Form.useForm();
  const options = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
  ];

  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (date: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
  };

  useEffect(() => {
    form.setFieldsValue({
      birthdate: selectedDate,
    });
  }, [form, selectedDate]);

  return (
    <AuthLayout formPosition="left">
      <Card style={{ width: 500, marginTop: 30 }} bordered={false}>
        <Form
          layout="vertical"
          name="basic"
          onFinish={props.onSubmit}
          autoComplete="off"
          initialValues={{
            vEmail: props.vEmail,
            vPassword: props.vPassword,
            vMobile: props.vMobile,
            verifiedEmailSessionId: props.sessionId,
            verifiedPhoneSessionId: props.sessionId,
          }}
          form={form}
        >
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar size={90} icon={<UserOutlined />} />
            </div>
          </Form.Item>

          {props.vEmail && <Form.Item name="vEmail" hidden />}
          <Form.Item name="vPassword" hidden />
          <Form.Item name="vMobile" hidden />

          <Form.Item name="vFirstName" label="First Name">
            <Input placeholder="Enter first name" />
          </Form.Item>

          <Form.Item name="vLastName" label="Last Name">
            <Input placeholder="Enter last name" />
          </Form.Item>

          <Form.Item name="vNickName" label="Nickname">
            <Input placeholder="Enter nickname" />
          </Form.Item>

          <Form.Item name="iGender" label="Gender">
            <Select options={options} />
          </Form.Item>

          <Form.Item label="Birthday">
            <DatePicker
              format="D MMMM YYYY"
              style={{ width: "100%" }}
              onChange={handleDateChange}
            />
          </Form.Item>

          <Form.Item name="birthdate" hidden />
          {props.vEmail && <Form.Item name="verifiedEmailSessionId" hidden />}
          <Form.Item name="verifiedPhoneSessionId" hidden />

          <Form.Item>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                loading={isAuthLoading}
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  marginTop: 20,
                  paddingBlock: 20,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Save Profile
              </Button>
            </div>
          </Form.Item>
        </Form>

        <AppVersion />
      </Card>
    </AuthLayout>
  );
};

export default CreateProfile;
