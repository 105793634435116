import { message } from "antd";
import { CreateTaskProps, iResponseType } from "../types/task.type";
import { initialCreateTask } from "../types/task.type";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";

const initialState: {
  ticketItem: {
    vName: string;
    vDescription: string;
    dLimitResponse: Date | string;
    dLimitResolve: Date | string;
    autoCreateTask?: boolean;
  };
  cartItems: CreateTaskProps[];
  fileList: {
    index: number;
    fileArray: any[];
  }[];
  TicketDateResponse: Date | string;
  TicketDateResolve: Date | string;
  SelectedHour: number;
  IsEdited: boolean;
  deletedAttachment: {
    iTask?: number;
    iAttachment?: number;
  }[];
} = {
  ticketItem: {
    vName: "",
    vDescription: "",
    dLimitResponse: new Date(),
    dLimitResolve: new Date(),
    autoCreateTask: false,
  },
  cartItems: [initialCreateTask],
  fileList: [
    {
      index: 0,
      fileArray: [],
    },
  ],
  TicketDateResponse: new Date(),
  TicketDateResolve: new Date(),
  SelectedHour: 0,
  IsEdited: false,
  deletedAttachment: [],
};

const { useGlobalState } = createGlobalState(initialState);

export default function useTaskList() {
  const [taskItems, setTaskItems] = useGlobalState("cartItems");
  const [fileList, setFileList] = useGlobalState("fileList");
  const [ticketItem, setTicketItem] = useGlobalState("ticketItem");
  const [ticketDate, setTicketDate] = useGlobalState("TicketDateResponse");
  const [resolveDate, setResolveDate] = useGlobalState("TicketDateResolve");
  const [selectedHour, setSelectedHour] = useGlobalState("SelectedHour");
  const [isEdited, setIsEdited] = useGlobalState("IsEdited");
  const [deletedAttachment, setDeletedAttachment] =
    useGlobalState("deletedAttachment");

  useEffect(() => {}, [taskItems]);

  const handleAddTask = (task: CreateTaskProps) => {
    const newCartItems = [...taskItems, task];
    setTaskItems(newCartItems);
    // localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };
  const getTaskList = () => {
    return taskItems;
  };

  const handleRemoveTask = (index: number) => {
    const newCartItems = taskItems.filter((_, i) => i !== index);
    if (newCartItems) {
      message.success("Task removed");
    } else {
      message.error("Failed to remove task");
    }
    setTaskItems(newCartItems);
    // localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };
  const updateTask = (index: number, task: CreateTaskProps) => {
    const newCartItems = taskItems.map((item, i) => {
      if (i === index) {
        return task;
      }
      return item;
    });
    setTaskItems(newCartItems);
    // localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };
  const updateTicket = (ticket: any) => {
    setTicketItem(ticket);
    // localStorage.setItem("ticketItem", JSON.stringify(ticket));
  };
  const handleEmptyTask = () => {
    setTaskItems([initialCreateTask]);
    // localStorage.setItem("cartItems", JSON.stringify([initialCreateTask]));
  };
  const getTaskReady = () => {
    if (
      taskItems[0] &&
      taskItems[0].vName !== "" &&
      taskItems[0].vDescription !== "" &&
      taskItems[0].dLimitResponse !== "" &&
      taskItems[0].dLimitResolve !== "" &&
      (taskItems[0].iUserTarget !== 0 || taskItems[0].iRelayTarget !== 0) &&
      taskItems[0].iResponseType !== undefined
      // && taskItems[0].jSLA
    ) {
      if (taskItems[0].iUserTarget === 0) {
        taskItems[0].iUserTarget = undefined;
      }
      if (taskItems[0].iRelayTarget === 0) {
        taskItems[0].iRelayTarget = undefined;
      }
      if (taskItems[0].iOrganizationTarget === 0) {
        taskItems[0].iOrganizationTarget = undefined;
      }
      if (taskItems[0].iRelayCreate === 0) {
        taskItems[0].iRelayCreate = undefined;
      }
      return true;
    } else {
      console.log("taskItems", taskItems);
      return false;
    }
  };

  return {
    taskItems,
    ticketDate,
    resolveDate,
    selectedHour,
    deletedAttachment,
    setDeletedAttachment,
    getTaskReady,
    setSelectedHour,
    getTaskList,
    setTaskItems,
    setTicketDate,
    setResolveDate,
    handleAddTask,
    handleRemoveTask,
    updateTask,
    handleEmptyTask,
    updateTicket,
    setIsEdited,
    isEdited,
  };
}
