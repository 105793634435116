import { BaseResponseProps } from "@qlibs/react-components";
import { httpRequest } from "../helpers/api";

export const apiNotificationMarkAllAsRead = async () => {
  try {
    await httpRequest.patch<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/notifications/mark-all-as-read`
    );
  } catch (err) {
    console.error(err);
  }
};

export const apiNotificationMarkAsRead = async (
  notificationId: string,
  toId: string
) => {
  try {
    await httpRequest.patch<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/notifications/mark-as-read`,
      {
        notificationIds: [notificationId],
        toId: toId,
      }
    );
  } catch (err) {
    console.error(err);
  }
};
