import React from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  PictureOutlined,
  TagOutlined,
  FileTextOutlined,
  SettingOutlined,
  AppstoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { LuClipboardList } from "react-icons/lu";
import { PiHeadsetBold } from "react-icons/pi";
import { BsShieldLock, BsShieldExclamation } from "react-icons/bs";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { EUserRole } from "../types/user.type";
import { FEATURE_PERMISSIONS } from "../helpers/featureAndPermission.constant";
import { hasPermissionFromRoles } from "../helpers/featureAndPermissionHelper";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { Clipboard, Edit, Headphones, Info, Layers } from "react-feather";
import useExecutionTime from "../hooks/useExecutionTime";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuthUser();
  const { stopTimer, startTimer, updateExecutionLabel } = useExecutionTime();
  // const header = useAuthHeader();
  const user = auth();

  // console.log(header())
  // const hasPermission = (finds: EUserRole[] = []) => {
  //   const user = auth()

  //   if(user?.role) {
  //     return finds.includes(user.role.roleName.toLowerCase())
  //   }

  //   return false
  // 	// return finds.includes(role);
  // 	// return true;
  // };

  // const hasPermission = (feature: string, permission: string) => {
  //   const user = auth()

  //   if(user?.role) {
  //     return finds.includes(user.role.roleName.toLowerCase())
  //   }

  //   return false
  // 	// return finds.includes(role);
  // 	// return true;
  // };

  const filterPermission = (items: any[]) => {
    return items
      .map((item) => {
        if (item?.permission === undefined || item.permission === true) {
          let newObject = item;
          delete item.permission;
          if (newObject.children) {
            newObject.children = filterPermission(newObject.children);
          }
          return newObject;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const MENUS = [
    {
      key: "/dashboard",
      label: "Home",
      icon: <HomeOutlined style={{ fontSize: 18 }} />,
    },
    {
      type: "group",
      label: "ASSIGNMENTS",
      children: [
        {
          key: "/my-task",
          label: "My Task",
          icon: <Layers size={18} />,
        },
        {
          key: "/my-ticket",
          label: "My Ticket",
          icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
        },
        user?.relayControllers.length > 0 && {
          key: "/contact-center",
          label: "Contact Center",
          icon: <PiHeadsetBold size={18} />,
        },
      ],
    },
    {
      type: "group",
      label: "CONFIGURATION",
      children: [
        {
          key: "/contact",
          label: "Contact",
          icon: <LuClipboardList size={18} />,
        },
        {
          label: "More",
          icon: <SettingOutlined style={{ fontSize: 18 }} />,
          children: [
            {
              key: "/my-profile",
              label: "My Profile",
              icon: <UserOutlined style={{ fontSize: 18 }} />,
            },
            {
              key: "/account",
              label: "Account",
              icon: <BsShieldLock style={{ fontSize: 18 }} />,
            },
            {
              key: "/feedback",
              label: "Feedback",
              icon: <Edit size={18} />,
            },
            {
              key: "/privacy-policy",
              label: "Privacy Policy",
              icon: <BsShieldExclamation style={{ fontSize: 18 }} />,
            },
            {
              key: "/tnc",
              label: "Terms & Conditions",
              icon: <BsShieldExclamation style={{ fontSize: 18 }} />,
            },
            {
              key: "/aboutus",
              label: "About Us",
              icon: <Info size={18} />,
            },
          ],
        },
      ],
    },
  ];

  const items = filterPermission(MENUS) as MenuProps["items"];

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && "children" in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : "");
            if (submenu && "children" in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  "path" in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <Menu
      mode="inline"
      style={{ paddingBottom: 40 }}
      defaultSelectedKeys={[convertPathName()]}
      defaultOpenKeys={getSelectedParent()}
      selectedKeys={[convertPathName()]}
      items={items}
      onClick={({ key }) => {
        startTimer(`Page ${key}`);
        navigate(key);
      }}
    />
  );
};
export default Sidebar;
