import { Col, Divider, Row } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";

type Props = {
  responseTimeLimit?: Date | string;
  resolveTimeLimit?: Date | string;
};

const TimeLimit = (props: Props) => {
  const formatDate = (date: Date | string): string => {
    const formattedDate = dayjs(date).format("DD MMM YYYY, HH:mm");
    return formattedDate;
  };
  return (
    <div style={{ marginRight: 20 }}>
      <Row>
        <Col span={8}>
          <Text>Response Time Limit</Text>
        </Col>
        <Col>
          <TextBold>
            {props.responseTimeLimit
              ? formatDate(props.responseTimeLimit)
              : "Not Set"}
          </TextBold>
        </Col>
      </Row>
      <TaskDivider />
      <Row>
        <Col span={8}>
          <Text>Resolve Time Limit</Text>
        </Col>
        <Col>
          <TextBold>
            {props.resolveTimeLimit
              ? formatDate(props.resolveTimeLimit)
              : "Not Set"}
          </TextBold>
        </Col>
      </Row>
      <TaskDivider />
    </div>
  );
};

export default TimeLimit;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TaskDivider = styled(Divider)`
  padding: 0px;
  margin: 10px 0px;
`;

const TextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
