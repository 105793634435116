import PrivacyPolicy from "../../components/More/PrivacyPolicy";

const PrivacyAndPolicy = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBlock: 40,
        paddingInline: 30,
      }}
    >
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyAndPolicy;
