import { Avatar, Button, Divider, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  apiAddContact,
  apiSearchMyContactByOrganization,
} from "../../api/contact";
import { generateColor, getInitialName } from "../../helpers/constant";
import { Info } from "react-feather";
import { Organization } from "../../types/contact.type";
import SearchEmptyOrganization from "../../components/Contact/SearchEmptyOrganization";
import { apiQueryGetOrganization } from "../../api/organization";
import SearchEmptyContact from "../../components/Contact/SearchEmptyContact";

const SearchByOrganization = () => {
  const [search, setSearch] = useState("");
  const [searchTmp, setSearchTmp] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [iRelay, setIRelay] = useState<number>();
  const [iUser, setIUser] = useState<number>();
  const [name, setName] = useState("Not Set");
  const [description, setDescription] = useState("Not Set");
  const [organization, setOrganization] = useState("");
  const [iOrganization, setIOrganization] = useState<number>();
  const [data, setData] = useState<any[]>();
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [selectedContact, setSelectedContact] = useState<any>();

  const fetchOrganization = async () => {
    const res = await apiQueryGetOrganization(search);
    if (res) {
      setOrganizations(res);
    }
  };
  const fetchData = async () => {
    const res = await apiSearchMyContactByOrganization(search, iOrganization);
    if (res) {
      const relaysBulk: any[] = res.data.payload?.results?.relays || [];
      const usersBulk: any[] = res.data.payload?.results?.users || [];
      const contactBulk = [...relaysBulk, ...usersBulk];
      setData(contactBulk);
    }
  };

  const handleAddContact = async () => {
    await apiAddContact({
      iRelay: iRelay,
      iUser: iUser,
      iOrganization: iOrganization,
    });
    fetchData();
  };

  const isMyContact = () => {
    return selectedContact?.contact || selectedContact?.user?.contact;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iOrganization, search]);
  return (
    <div style={{ margin: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          marginBottom: 20,
        }}
      >
        <Input
          onChange={(value) => setSearchTmp(value.target.value)}
          onPressEnter={(e) => setSearch(e.currentTarget.value)}
          allowClear
          placeholder="Search"
          style={{ padding: 10 }}
        />
        <Button
          type="primary"
          onClick={() => setSearch(searchTmp)}
          style={{ padding: 20, display: "flex", alignItems: "center" }}
        >
          Search
        </Button>
      </div>
      {organization && (
        <Text style={{ marginBottom: 20 }}>
          <span
            style={{ color: "#045AFF" }}
          >{`Organization : ${organization}`}</span>{" "}
          <span style={{ color: "grey", opacity: 0.8 }}>/ Find Contact</span>
        </Text>
      )}
      {organization ? (
        search ? (
          <div style={{ backgroundColor: "white", padding: 20 }}>
            {data?.map((item) => (
              <div
                onClick={() => {
                  setName(
                    item.vName ||
                      `${item.user?.vFirstName} ${item.user?.vLastName}` ||
                      "Not Set"
                  );
                  setDescription(item.vDescription || "Not Set");
                  if (item.iRelay) {
                    setIRelay(item.iRelay);
                    setIUser(undefined);
                  } else {
                    setIUser(item.user?.iUser);
                    setIRelay(undefined);
                  }
                  setSelectedContact(item);
                  setShowModal(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Avatar
                    shape="square"
                    size={50}
                    style={{
                      backgroundColor: generateColor(
                        item.vName ||
                          `${item.user?.vFirstName} ${item.user?.vLastName}`
                      ),
                    }}
                  >
                    {getInitialName(
                      item.vName ||
                        `${item.user?.vFirstName} ${item.user?.vLastName}`
                    )}
                  </Avatar>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      {item.vName && (
                        <Status color="#404852" backgroundColor="#E6E9EF">
                          Contact Center
                        </Status>
                      )}
                      <TextBold>
                        {item.vName ||
                          `${item.user?.vFirstName} ${item.user?.vLastName}`}
                      </TextBold>
                    </div>
                    <Text>{item.vDescription}</Text>
                  </div>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        ) : (
          <SearchEmptyContact />
        )
      ) : search ? (
        <div style={{ backgroundColor: "white", padding: 20 }}>
          {organizations?.map((item) => (
            <div
              onClick={() => {
                setOrganization(item.vName);
                setIOrganization(item.iOrganization);
                setSearch("");
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Avatar
                  shape="square"
                  size={50}
                  style={{ backgroundColor: generateColor(item.vName) }}
                >
                  {getInitialName(item.vName)}
                </Avatar>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextBold>{item.vName}</TextBold>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <SearchEmptyOrganization />
      )}
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <SubTextBold>Detail Contact</SubTextBold>
        <Divider style={{ marginTop: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            size={50}
            style={{ backgroundColor: generateColor(name) }}
          >
            {getInitialName(name)}
          </Avatar>
          {iRelay && (
            <Status color="#404852" backgroundColor="#E6E9EF">
              Contact Center
            </Status>
          )}
          <TextBolder>{name}</TextBolder>
        </div>
        {iRelay && (
          <div>
            <Divider />
            <div style={{ marginBlock: 20 }}>
              <SubTextBold>Description</SubTextBold>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div style={{ display: "flex" }}>
                  <Info style={{ opacity: 0.5 }} />
                </div>
                <Text>{description}</Text>
              </div>
            </div>
          </div>
        )}
        <Divider />

        {!isMyContact() && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button onClick={handleAddContact} type="primary">
              Add Contact
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SearchByOrganization;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBold = styled.div`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const SubTextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const TextBolder = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 8px;
  padding: 0px 10px;
  display: inline-block;
`;
