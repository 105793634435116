const doneStatusValue = [3, 8, 9, 21, 22];
export const isDoneStatus = (value: number | undefined) => {
  if (!value) {
    return false;
  }
  const isDone = doneStatusValue.find((item) => item === value);
  if (isDone) {
    return true;
  } else {
    return false;
  }
};
