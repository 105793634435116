import { Form, Card, Button, Input } from "antd";
import { InputOTP } from "antd-input-otp";
import AuthLayout from "../layout/AuthLayout";
import AuthHeaderCard, {
  AuthSubTitleCard,
} from "../../components/AuthHeaderCard";
import AppVersion from "../../components/AppVersion";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Link from "antd/es/typography/Link";

type Props = {
  vEmail?: string;
  vMobile?: string;
  otp?: string;
  vSession?: string;
  isAuthLoading?: boolean;
  onResendOtp: () => void;
  onSubmit: (
    data: {
      vEmail?: string;
      vMobile?: string;
      otp?: string;
      vSession?: string;
    },
    callback?: () => void
  ) => void;
};

const Otp = (props: Props) => {
  const [form] = Form.useForm();

  const [countdown, setCountdown] = useState(59);
  const [otpVal, setOtpVal] = useState(props?.otp?.split(""));

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);

  useEffect(() => {
    form.setFieldValue("otp", otpVal?.join("") || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpVal]);

  useEffect(() => {
    if (props?.vSession) {
      form.setFieldValue("vSession", props.vSession);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.vSession]);

  return (
    <AuthLayout formPosition="left">
      <Card style={{ width: 500 }} bordered={false}>
        <AuthHeaderCard
          title={props.vEmail ? "Email\nVerification" : "Phone\nVerification"}
          subtitle={`Check your ${
            props.vEmail ? "email" : "WhatsApp"
          } messages. We've sent you the OTP verification code to ${
            props.vEmail ? props.vEmail : props.vMobile
          }`}
        />

        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={props.onSubmit}
          autoComplete="off"
          initialValues={{
            vEmail: props.vEmail,
            vMobile: props.vMobile,
            vSession: props.vSession,
            otp: otpVal?.join("") || "",
            otpInput: otpVal,
          }}
        >
          <Form.Item name="vEmail" hidden />
          <Form.Item name="vMobile" hidden />
          <Form.Item name="vSession" hidden />
          <Form.Item name="otp" hidden>
            <Input value={otpVal?.join("") || ""} />
          </Form.Item>

          <Form.Item name="otpInput">
            <InputOTP inputType="numeric" onChange={(val) => setOtpVal(val)} />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                loading={props.isAuthLoading}
                type="primary"
                htmlType="submit"
                style={{
                  width: "70%",
                  marginTop: 20,
                  paddingBlock: 20,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Submit OTP
              </Button>
            </div>
          </Form.Item>
        </Form>

        <div
          style={{
            display: "block",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          {countdown > 0 ? (
            <AuthSubTitleCard>
              Please wait{" "}
              <Subtitle>{`00:${
                countdown < 10 ? "0" + countdown : countdown
              }`}</Subtitle>{" "}
              before resend another OTP
            </AuthSubTitleCard>
          ) : (
            <Link
              onClick={() => {
                props.onResendOtp();
                setCountdown(59);
              }}
            >
              Resend OTP
            </Link>
          )}
        </div>
        <AppVersion />
      </Card>
    </AuthLayout>
  );
};

export default Otp;

const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
