import PrivacyPolicy from "../../components/More/PrivacyPolicy";

const PrivacyPolicyScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 40,
        backgroundColor: "white",
        margin: 20, // Center the container
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        overflowY: "auto", // Allows scrolling
        width: "60vw", // Set to half of the viewport width
        maxHeight: "80vh", // Adjusts height to allow scrolling within viewport
      }}
    >
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyScreen;
