import { BaseResponseProps, getErrorMessage } from "@qlibs/react-components";
import { httpRequest } from "../helpers/api";
import { message } from "antd";

export async function apiSendFeedback(
  iStar: number,
  vType: string,
  vMessage: string
) {
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      `${process.env.REACT_APP_BASE_URL}/feedbacks`,
      { iStar, vType, vMessage }
    );
    if (res) {
      message.success("Success send feedback");
      return res;
    }
  } catch (err) {
    console.error(getErrorMessage(err));
  }
}
