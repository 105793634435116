import styled from "styled-components";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  apiGetTicketResolveTimeLimitById,
  apiGetTicketResponseTimeLimitById,
} from "../../api/ticket";

const TimeLimitRelay = (props: {
  iTicketResponse?: number;
  iTicketResolve?: number;
}) => {
  const [timeLimit, setTimeLimit] = useState<Date | string>();
  const fetchTime = async () => {
    if (props.iTicketResponse) {
      const res = await apiGetTicketResponseTimeLimitById(
        props.iTicketResponse
      );
      if (res) {
        setTimeLimit(res);
      }
    } else if (props.iTicketResolve) {
      const res = await apiGetTicketResolveTimeLimitById(props.iTicketResolve);
      if (res) {
        setTimeLimit(res);
      }
    }
  };

  useEffect(() => {
    fetchTime();
  }, []);

  useEffect(() => {}, [timeLimit]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Text>{dayjs(timeLimit).format("HH:mm, DD MMM YYYY")}</Text>
    </div>
  );
};

export default TimeLimitRelay;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
