import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const AuthContentRight: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Container onClick={handleClick}>
      <Wrapper>
        <img height="70" src="/logo.png" alt="Logo" />
        <Title>{"Welcome to " + process.env.REACT_APP_WEBSITE_NAME}</Title>
        <SubTitle>
          {
            "Where task management meets simplicity and efficiency. Flick adapts seamlessly to the unique needs and workflows of its users, enabling our users to focus on what truly matters: productivity and success."
          }
        </SubTitle>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  & {
    background-image: url("/images/circle.svg");
    background-position: top right;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 150px 50px;
    color: ${({ theme }) => theme.colors.white};
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  width: 80%;
`;
const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: 20px;
`;

const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.5;
  margin-top: 20px;
`;

export default AuthContentRight;
