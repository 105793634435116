import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import TaskInformation from "./TaskInformation";
import TaskAttachment from "./TaskAttachment";
import { TaskProps } from "../../types/task.type";
import NoAttachment from "./NoAttachment";

type Props = {
  myTask?: TaskProps;
};

const DetailTask = (props: Props) => {

  useEffect(() => {}, [props.myTask]);
  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBlock: 20,
              gap: 10,
            }}
          >
            <div>
              <InfoCircleOutlined style={{ fontSize: "24px" }} />
            </div>
            <div>
              <TitleBold>Task Information</TitleBold>
            </div>
          </div>
          <TaskInformation myTask={props.myTask} />
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBlock: 20,
              gap: 10,
            }}
          >
            <div>
              <LinkOutlined style={{ fontSize: "24px" }} />
            </div>
            <div>
              <TitleBold>Task Attachment</TitleBold>
            </div>
          </div>
          {props.myTask?.jAttachment?.files ? (
            props.myTask.jAttachment?.files?.map((item: any) => (
              <TaskAttachment
                fileName={
                  item.vRawData.fileLinkCache?.split("/").pop() ||
                  item.ipAttachment +
                    "." +
                    item.vRawData.fileLinkCache
                      .split(".")
                      .pop()
                      ?.toLocaleLowerCase()
                }
                attachmentId={item.ipAttachment}
                url={item.vRawData.fileLinkCache}
                iTask={props.myTask?.iTask}
              />
            ))
          ) : (
            <NoAttachment />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailTask;

const TitleBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
