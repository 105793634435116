import React, { useEffect, useState } from "react";
import { Table, Row, Select, Col, Input } from "antd";
import type { TableColumnsType } from "antd";
import styled from "styled-components";
import useFetchList from "../../hooks/useFetchList";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import { TaskProps } from "../../types/task.type";
import { useAuthUser } from "react-auth-kit";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Circle } from "react-feather";
import { theme } from "../../assets/theme";
import dayjs from "dayjs";
import SLALevel from "../../components/MyTask/SLALevel";
import StatusTask from "../../components/MyTask/StatusTask";
import AttachmentRelay from "../../components/Relay/Attachments";
import { useNavigate } from "react-router-dom";
import NameRelay from "../../components/Relay/NameRelay";
import TimeLimitRelay from "../../components/Relay/TimeLimitRelay";
import { getAllRelayTasks } from "../../hooks/useOfflineData";
import useExecutionTime from "../../hooks/useExecutionTime";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const Relay = () => {
  const navigate = useNavigate();
  const { stopTimer } = useExecutionTime();

  const columns: TableColumnsType<TaskProps> = [
    {
      title: "Ticket Name",
      dataIndex: "ticketName",
      key: "ticketName",
      fixed: "left",
      width: "400px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>{`${record.vName}`}</Text>
        </div>
      ),
    },
    {
      title: "Assign/Forward To",
      dataIndex: "address",
      key: "1",
      width: "200px",
      render: (_, record) => (
        <NameRelay
          isRelay={true}
          vName={record.relayTarget?.vName || record.relayCreate?.vName}
          iUser={record.iUserTarget}
          iRelay={record.iRelayTarget}
        />
      ),
    },
    {
      title: "Status Task",
      dataIndex: "address",
      key: "2",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: 200,
            marginInline: -10,
          }}
        >
          <StatusTask iStatus={record.iResult} />
        </div>
      ),
    },
    {
      title: "Organization",
      dataIndex: "address",
      key: "3",
      width: "150px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>{`${
            record.iOrganizationTarget
              ? getOrgName(record.iOrganizationTarget)
              : "Personal"
          }`}</Text>
        </div>
      ),
    },
    {
      title: "SLA Level",
      dataIndex: "address",
      key: "4",
      width: "150px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <SLALevel level={record.jSLA?.level} />
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "address",
      key: "5",
      width: "200px",
      render: (_, record) => (
        <NameRelay
          isRelay={false}
          iUser={record.iUserCreate}
          iRelay={record.iRelayCreate}
          vName={record.userCreate.vFullName}
        />
      ),
    },
    {
      title: "Response Time Limit",
      dataIndex: "address",
      key: "6",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          {record.dLimitResponse ? (
            <Text>
              {dayjs(record.dLimitResponse).format("DD MMM YYYY, HH:mm")}
            </Text>
          ) : (
            <TimeLimitRelay iTicketResponse={record.iTicket} />
          )}
        </div>
      ),
    },
    {
      title: "Resolve Time Limit",
      dataIndex: "address",
      key: "7",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          {record.dLimitResolve ? (
            <Text>
              {dayjs(record.dLimitResolve).format("DD MMM YYYY, HH:mm")}
            </Text>
          ) : (
            <TimeLimitRelay iTicketResolve={record.iTicket} />
          )}
        </div>
      ),
    },
    {
      title: "Create Time",
      dataIndex: "address",
      key: "8",
      width: "200px",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Text>
            {dayjs(record.dCreatedServer).format("DD MMM YYYY, HH:mm")}
          </Text>
        </div>
      ),
    },
    // {
    //   title: "Attachment",
    //   dataIndex: "address",
    //   key: "8",
    //   width: "200px",
    //   render: (_, record) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         gap: 10,
    //       }}
    //     >
    //       <AttachmentRelay data={record.jAttachment} />
    //     </div>
    //   ),
    // },
  ];

  const auth = useAuthUser();
  const [ipTicket, setIpTicket] = useState<string>();
  const [once, setOnce] = useState<boolean>(true);
  const [slaLevel, setSlaLevel] = useState([
    "CRITICAL",
    "HIGH",
    "NORMAL",
    "LOW",
  ]);
  const [organization, setOrganization] = useState(0);
  const [sortedBy, setSortedBy] = useState(6);

  const { data: userOrganizations } = useFetchList<OrganizationUserProps>({
    endpoint: "organization-users",
    initialQuery: { iUser: auth()?.iUser },
  });

  const {
    data: taskData,
    fetchList: fetchTask,
    setQuery: taskQuery,
  } = useFetchList<TaskProps>({
    endpoint: "tasks/custom/optimize/relay-tasks",
    pageData: "relay-tasks",
    initialQuery: {
      ipTIcket: ipTicket,
      jSLA: slaLevel,
      sortBy: sortedBy,
      iOrganization: organization,
    },
  });

  useEffect(() => {
    if (taskData.length === 0 && !navigator.onLine) {
      fetchTask();
    }
  }, [taskData]);
  console.log("relay task data", taskData);
  const getOrgName = (id: number) => {
    const org = userOrganizations.find(
      (item) => item.organization.iOrganization === id
    );
    return org?.organization.vName;
  };

  useEffect(() => {
    taskQuery((oldval) => ({
      ...oldval,
      ipTicket: ipTicket,
      jSLA: slaLevel,
      sortBy: sortedBy,
      iOrganization: organization,
    }));
    fetchTask();
    // eslint-disable-next-line
  }, [ipTicket, slaLevel, sortedBy, organization]);
  useEffect(() => {
    if (once && taskData.length > 0) {
      stopTimer();
      setOnce(false);
    }
  }, [taskData]);
  return (
    <React.Fragment>
      <div style={{ margin: 20 }}>
        <Row style={{ gap: 20, marginBlock: 40 }}>
          <Col span={4}>
            <Text style={{ color: "gray", marginBottom: 10 }}>Search</Text>
            <Input.Search
              onSearch={(e) => setIpTicket(e)}
              placeholder="Search by Ticket ID"
            />
          </Col>
          <Col span={8}>
            <Text style={{ color: "gray" }}>SLA level</Text>
            <Select
              mode="tags"
              style={{ marginTop: 10 }}
              defaultValue={slaLevel}
              placeholder="Status"
              onChange={(value) => setSlaLevel(value)}
            >
              <Select.Option value="CRITICAL">
                <WarningOutlined style={{ color: "red", marginRight: 8 }} />
                Critical
              </Select.Option>
              <Select.Option value="HIGH">
                <ExclamationCircleOutlined
                  style={{ color: theme.colors.warning, marginRight: 8 }}
                />
                High
              </Select.Option>
              <Select.Option value="NORMAL">
                <Circle
                  color={theme.colors.warning}
                  fill={theme.colors.warning}
                  size={10}
                  style={{ marginRight: 8 }}
                />
                Normal
              </Select.Option>
              <Select.Option value="LOW">
                <Circle
                  color={theme.colors.green500}
                  fill={theme.colors.green500}
                  size={10}
                  style={{ marginRight: 8 }}
                />
                Low
              </Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <Text style={{ color: "gray" }}>Organization</Text>
            <Select
              style={{ marginTop: 10 }}
              defaultValue="all"
              allowClear
              placeholder="Status"
              onChange={(value) => setOrganization(parseInt(value))}
            >
              <Select.Option value="all">All</Select.Option>
              {userOrganizations.map((item) => (
                <Select.Option value={item.organization.iOrganization}>
                  {item.organization.vName}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Text style={{ color: "gray" }}>Sorted by</Text>
            <Select
              style={{ marginTop: 10 }}
              defaultValue={sortedBy}
              allowClear
              placeholder="Sorted by"
              onChange={(val) => setSortedBy(val)}
            >
              <Select.Option value={1}>{"Due Date (earliest)"}</Select.Option>
              <Select.Option value={2}>{"Due Date (latest)"}</Select.Option>
              <Select.Option value={3}>{"Created At (earliest)"}</Select.Option>
              <Select.Option value={4}>{"Created At (latest)"}</Select.Option>
              <Select.Option value={5}>
                {"Last Modified (earliest)"}
              </Select.Option>
              <Select.Option value={6}>
                {"Last Modified (latest)"}
              </Select.Option>
            </Select>
          </Col>
        </Row>
      </div>
      <div style={{ marginInline: 20 }}>
        <Table
          columns={columns}
          dataSource={taskData}
          scroll={{ x: "max-content" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => navigate("/my-task/" + record.ipTask),
              style: { cursor: "pointer" },
            };
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default Relay;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
