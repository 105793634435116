import {
  Button,
  Card,
  Modal,
  Steps,
  Tabs,
  message,
  Divider,
  Row,
  Col,
  Input,
  Select,
  Table,
  TableProps,
  Avatar,
  List,
  Upload,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft, CornerUpRight } from "react-feather";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StatusTask from "../../components/MyTask/StatusTask";
import {
  ForwardBodyProps,
  TaskProps,
  initialForwardBody,
} from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "@qlibs/react-components";
import {
  AlignLeftOutlined,
  CloudUploadOutlined,
  FileTextOutlined,
  LinkOutlined,
  SearchOutlined,
  InboxOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useAuthUser } from "react-auth-kit";
import {
  MyContactProps,
  MyContactUserProps,
  MyRelayContactProps,
} from "../../types/myContact.type";
import { generateQueryString } from "../../helpers/generateQueryString";
import { ContactProps } from "../../types/contact.type";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import TextArea from "antd/es/input/TextArea";
import { UploadProps } from "antd/lib";
import { TicketProps } from "../../types/ticket.type";
import { generateColor } from "../../helpers/constant";
import { el } from "date-fns/locale";
import { apiGetOneTask } from "../../api/task";
const { Dragger } = Upload;

const Forward = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const taskSelected = window.location.pathname.split("/").pop();
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");
  const [contacts, setContacts] = useState<ContactProps[]>();
  const [relay, setRelay] = useState<MyRelayContactProps[]>();
  const [forwardBody, setForwardBody] =
    useState<ForwardBodyProps>(initialForwardBody);
  const [querySearch, setQuerySearch] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [isSelected, setIsSelected] = useState<Boolean[]>([]);
  const [isListTrue, setIsListTrue] = useState(0);
  const [ticketSelected, setTicketSelected] = useState<TicketProps>();
  const [ticketIdAttach, setTicketIdAttach] = useState("");
  const [myTask, setMyTask] = useState<TaskProps>();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const { data: userOrganizations } = useFetchList<OrganizationUserProps>({
    endpoint: "organization-users",
    initialQuery: { iUser: auth()?.iUser },
  });
  const { data: myTasks } = useFetchList<TaskProps>({
    endpoint: "tasks/custom/my-tasks",
  });
  useEffect(() => {
    const fetchTask = async () => {
      if (taskSelected) {
        const foundTask = await apiGetOneTask(taskSelected);
        setMyTask(foundTask);
      }
    };

    fetchTask();
    fetchContactList();
  }, [taskSelected, myTasks]);

  useEffect(() => {
    fetchContactList();
  }, [querySearch]);
  const handleAttachTicket = async () => {
    try {
      const findTicket = await httpRequest.get(
        "/tickets/custom/my-tickets" + `?ipTicket=${ticketIdAttach}`
      );
      if (findTicket.data.payload.results.length > 0) {
        message.success("Ticket found");
        console.log("findTicket", findTicket);
        setTicketSelected(findTicket?.data.payload.results[0]);
        const attachTicket = await httpRequest.post(
          `/tasks/${myTask?.iTask}/attach-ticket`,
          { iTicket: findTicket?.data.payload.results[0].iTicket }
        );
        if (attachTicket) {
          message.success("Ticket attached successfully");
        }
      } else {
        message.error("Ticket not found");
      }
    } catch (error) {
      console.log("error", error);

      message.error("Failed to attach ticket");
    }
  };

  const fetchContactList = async () => {
    try {
      const response = await httpRequest.get("/my-contacts" + querySearch);
      if (response) {
        setContacts(response.data.payload.users);
        setRelay(response.data.payload.relay);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSelect = (index: number) => {
    setIsSelected(Array(contacts?.length).fill(false));
    let temp = Array(contacts?.length).fill(false);
    const selectedId = contacts?.[index].user[0].iUser;
    setForwardBody((prevForwardBody) => ({
      ...(prevForwardBody as ForwardBodyProps),
      forwardto: {
        ...prevForwardBody.forwardto,
        iUserTarget: selectedId!,
      },
    }));
    temp[index] = true;
    setIsListTrue(index);
    setIsSelected(temp);
  };
  const handleNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForwardBody((prevForwardBody) => ({
      ...(prevForwardBody as ForwardBodyProps),
      vNote: e.target.value,
    }));
    console.log("notes updated", forwardBody);
  };

  const handleModal = () => {
    setShowModal(true);
  };

  const handleForwardTask = async () => {
    try {
      const forwardTask = await httpRequest.post<BaseResponseProps<{}>>(
        `/tasks/${myTask?.iTask}/forward`,
        forwardBody
      );
      if (forwardTask) {
        message.success("Task forwarded successfully");
      }
      if (fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach((file: any) => {
          formData.append("files", file.originFileObj);
        });
        const uploadFile = await httpRequest.put(
          `/tasks/${myTask?.iTask}/attachments`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (uploadFile) {
          message.success("File attached successfully");
        }
      }
      navigate(`/my-task`);
    } catch (error) {
      console.log("error", error);
      message.error("Failed to forward task");
    }
  };

  const handleDeleteFileList = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const props: UploadProps = {
    multiple: true,
    onChange(info) {
      const { file } = info;
      if (file.size && file.size > 2000000) {
        message.config({ maxCount: 1 });
        message.error("File size must be less than 10MB");
      } else {
        // Check if the file is already in the fileList
        const isFileInList = fileList.some((item) => item.uid === file.uid);
        if (!isFileInList) {
          // Add the file to the fileList only if it's not already present
          setFileList([...fileList, file]);
        }
        console.log("info.fileList", fileList);
      }
    },
  };
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(`/my-task/${myTask?.ipTask}`)} />
          <Title>Forward Task</Title>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        <Card style={{ marginBottom: 5 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Text>Status:</Text>
            <StatusTask iStatus={myTask?.iResult} />
          </div>
          <HeaderTitle>{myTask?.vName}</HeaderTitle>
          <Text>{myTask?.vDescription}</Text>
        </Card>
        {currentStep === 0 ? (
          <Card>
            <Steps
              current={currentStep}
              direction="horizontal"
              items={[
                {
                  title: "New Assignee",
                  description: "Choose new assignee from your contact",
                  onClick: () => setCurrentStep(0),
                },
                {
                  title: "Add Information",
                  description: "You can add note or attachment",
                },
              ]}
            />
            <Divider />
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={11}>
                <Text style={{ color: "gray" }}>Search Contact</Text>
                <Input
                  prefix={<SearchOutlined />}
                  style={{ marginTop: 10 }}
                  placeholder="Search"
                  onChange={(e) =>
                    setQuerySearch(
                      generateQueryString({ keyword: e.target.value })
                    )
                  }
                />
              </Col>
              <Col span={11}>
                <Text style={{ color: "gray" }}>Organization</Text>
                <Select
                  style={{ marginTop: 10 }}
                  allowClear
                  maxTagTextLength={8}
                  placeholder="Status"
                  defaultValue={"personal"}
                >
                  <Select.Option value="personal">Personal</Select.Option>
                  {userOrganizations?.map((item) => (
                    <Select.Option value={item.organization.iOrganization}>
                      {item.organization.vName}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Divider style={{ marginBottom: 0 }} />
            <List>
              {contacts?.map((item, index) => {
                const initial =
                  item.user[0].vFirstName.charAt(0) +
                  item.user[0].vLastName.charAt(0);
                return (
                  <CustomListItem
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      backgroundColor: isSelected[index] ? "#D8EDFF" : "white",
                    }}
                    onClick={() => {
                      handleSelect(index);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: 10,
                        marginRight: 10,
                        gap: 10,
                      }}
                    >
                      <Avatar
                        shape="square"
                        style={{ backgroundColor: generateColor(initial) }}
                      >
                        {initial.toUpperCase()}
                      </Avatar>
                      <div>
                        <Text
                          style={{ fontWeight: "bold" }}
                        >{`${item.user[0].vFirstName} ${item.user[0].vLastName}`}</Text>
                        {/* <Text style={{ color: '#6F7887', fontSize: 13 }}>{`Field Engineer`}</Text> */}
                      </div>
                    </div>
                  </CustomListItem>
                );
              })}
            </List>
            <Divider />
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={3}>
                <Button
                  type="default"
                  style={{ width: "100%" }}
                  onClick={() => {
                    currentStep === 0 ? navigate(-1) : setCurrentStep(0);
                  }}
                >
                  {currentStep === 0 ? "Cancel" : "Previous"}
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    currentStep === 0 ? setCurrentStep(1) : setCurrentStep(1);
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Card>
        ) : (
          <div>
            <Card style={{ marginBottom: 5 }}>
              <Steps
                current={currentStep}
                direction="horizontal"
                items={[
                  {
                    title: "New Assignee",
                    description: "Choose new assignee from your contact",
                    onClick: () => setCurrentStep(0),
                  },
                  {
                    title: "Add Information",
                    description: "You can add note or attachment",
                  },
                ]}
              />
              <Divider />
              <div>
                <div style={{ marginBottom: 20 }}>
                  <Text>Forwarded To</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      shape="square"
                      style={{
                        backgroundColor: "#045AFF",
                        width: 40,
                        height: 40,
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      TS
                    </Avatar>
                    <div style={{ marginLeft: 10 }}>
                      <Text>{`${
                        contacts ? contacts[isListTrue].user[0].vFirstName : ""
                      } ${
                        contacts ? contacts[isListTrue].user[0].vLastName : ""
                      }`}</Text>
                      {/* <Text style={{ color: '#6F7887', fontSize: 13 }}>{`Field Engineer`}</Text> */}
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Card style={{ marginBottom: 5 }}>
              <Text>
                <FileTextOutlined style={{ color: "gray" }} /> Note for new
                assignee <span style={{ color: "red" }}>*</span>
              </Text>
              <TextArea
                rows={4}
                style={{ marginTop: 10 }}
                onChange={(e) => {
                  handleNotes(e);
                }}
              />
            </Card>

            <Card>
              <Text>
                <LinkOutlined style={{ color: "gray" }} /> Attachments
              </Text>
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                {fileList.length > 0 ? (
                  <>
                    {fileList.map((item, index) => {
                      return (
                        <Row
                          key={index}
                          style={{
                            marginTop: 10,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%", // Ensure each row takes up full width
                          }}
                        >
                          <Text style={{ color: "grey" }}>{item.name}</Text>
                          <Button onClick={() => handleDeleteFileList(index)}>
                            <DeleteOutlined style={{ color: "red" }} />
                          </Button>
                        </Row>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                {/* {
              ticketSelected ? 
              <Text style={{ color: "gray"}}>{ticketSelected.vName} | {ti}</Text> : ''
            } */}
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 4,
                }}
              >
                <Button type="primary" onClick={() => handleModal()}>
                  <AlignLeftOutlined /> Attach Ticket ID
                </Button>
                <Upload {...props} fileList={fileList} showUploadList={false}>
                  <Button type="primary">
                    <CloudUploadOutlined /> Attach File
                  </Button>
                </Upload>
              </Row>
              <Dragger style={{ height: "50%" }} {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger>
              <Divider />
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={3}>
                  <Button
                    type="default"
                    style={{ width: "100%" }}
                    onClick={() => {
                      currentStep === 0 ? navigate(-1) : setCurrentStep(0);
                    }}
                  >
                    {currentStep === 0 ? "Cancel" : "Previous"}
                  </Button>
                </Col>
                <Col span={3}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      currentStep === 0
                        ? setCurrentStep(1)
                        : handleForwardTask();
                    }}
                  >
                    {currentStep === 0 ? "Next" : "Forward Task"}
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </div>

      <Modal
        open={showModal}
        title="Attach Ticket ID"
        okText="Attach Ticket"
        onCancel={() => setShowModal(false)}
        onOk={() => {
          handleAttachTicket();
          setShowModal(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Input
              placeholder="Paste Ticket ID Here"
              onChange={(e) => {
                setTicketIdAttach(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Forward;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const CustomListItem = styled(List.Item)`
  &:hover {
    background-color: #f0f0f0;
  }
`;
