import { httpRequest } from "../helpers/api";
import { BaseResponseProps } from "@qlibs/react-components";
import { TicketProps } from "../types/ticket.type";
import { addMyTicket } from "../hooks/useOfflineData";
import UseActionQueue from "../hooks/useActionQueue";

export async function apiGetResolveTimeLimit() {
  try {
    let resolveTimeLimit: { [key: number]: Date | string | undefined } = {};
    const res = await httpRequest.get<
      BaseResponseProps<{ results: TicketProps[] }>
    >(process.env.REACT_APP_BASE_URL + "/tickets/custom/my-tickets");
    if (res) {
      res.data.payload.results.map(
        (item) => (resolveTimeLimit[item.iTicket] = item.dLimitResolve)
      );
    }
    return resolveTimeLimit;
  } catch (err) {
    return {};
  }
}

export async function apiGetResponseTimeLimit() {
  try {
    let responseTimeLimit: { [key: number]: Date | string | undefined } = {};
    const res = await httpRequest.get<
      BaseResponseProps<{ results: TicketProps[] }>
    >(process.env.REACT_APP_BASE_URL + "/tickets/custom/my-tickets");
    if (res) {
      res.data.payload.results.map(
        (item) => (responseTimeLimit[item.iTicket] = item.dLimitResponse)
      );
    }
    return responseTimeLimit;
  } catch (err) {
    return {};
  }
}

export async function apiGetTicketData(iTicket: number) {
  try {
    const res = await httpRequest.get<BaseResponseProps<TicketProps>>(
      process.env.REACT_APP_BASE_URL + "/tickets/" + iTicket
    );
    if (res) {
      return res.data.payload;
    }
    return {};
  } catch (err) {
    return {};
  }
}

export async function apiGetTicketResponseTimeLimitById(iTicket: number) {
  try {
    const res = await httpRequest.get<BaseResponseProps<TicketProps>>(
      process.env.REACT_APP_BASE_URL + "/tickets/" + iTicket
    );
    if (res) {
      return res.data.payload.dLimitResponse;
    }
    return null;
  } catch (err) {
    return null;
  }
}

export async function apiGetTicketResolveTimeLimitById(iTicket: number) {
  try {
    const res = await httpRequest.get<BaseResponseProps<TicketProps>>(
      process.env.REACT_APP_BASE_URL + "/tickets/" + iTicket
    );
    if (res) {
      return res.data.payload.dLimitResolve;
    }
    return null;
  } catch (err) {
    return null;
  }
}

export async function apiCloseTicket(iTicket: number) {
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/tickets/" + iTicket + "/closed",
      { dUpdatedLocal: new Date() }
    );
    if (res) {
      return res;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function apiCloseOpenTicket(iTicket: number) {
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/tickets/" + iTicket + "/closed-open",
      { dUpdatedLocal: new Date() }
    );
    if (res) {
      return res;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function apiReOpenTicket(iTicket: number) {
  try {
    const res = await httpRequest.post<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/tickets/" + iTicket + "/re-open",
      { dUpdatedLocal: new Date() }
    );
    if (res) {
      return res;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function apiSyncMyTicket() {
  try {
    const res = await httpRequest.get<
      BaseResponseProps<{ results: TicketProps[] }>
    >(process.env.REACT_APP_BASE_URL + "/tickets/custom/my-tickets");
    if (res) {
      res.data.payload.results.forEach((element: TicketProps) =>
        addMyTicket(element)
      );
    }
  } catch (err) {
    const { pushAction } = UseActionQueue();
    pushAction("GET", "/tickets/custom/my-tickets", {});
  }
}
