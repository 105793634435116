import { httpRequest } from "../helpers/api";
import { BaseResponseProps, getErrorMessage } from "@qlibs/react-components";

export async function apiGetRelayOrganization(iRelay: number) {
  try {
    let org = 0;
    const res = await httpRequest.get<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/relays/" + iRelay
    );
    if (res) {
      org = res.data.payload.iOrganization;
    }
    return org;
  } catch (err) {
    console.error(getErrorMessage(err));
    return 0;
  }
}

export async function apiGetNameRelayById(iRelay: number) {
  try {
    let name = "";
    const res = await httpRequest.get<BaseResponseProps<any>>(
      process.env.REACT_APP_BASE_URL + "/relays/" + iRelay
    );
    if (res) {
      name = res.data.payload.vName;
    }
    return name;
  } catch (err) {
    return null;
  }
}
