
type Props = {
    iResult: number;
};

export const YesNoTag = (props: Props) => {
    const getTag = (iResult: number) => {
        switch (iResult) {
            case 621:
                return <div style={{ backgroundColor:"#E9F5FF", color:'#3D98E7', paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5, borderRadius:6}}>Yes</div>;
            case 21:
                return <div style={{ backgroundColor:"#E9F5FF", color:'#3D98E7', paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5, borderRadius:6}}>Yes</div>;
            case 622:
                return <div style={{ backgroundColor:"#FFE8E8", color:'#EB2222', paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5, borderRadius:6}}>No</div>;
            case 22:
                return <div style={{ backgroundColor:"#FFE8E8", color:'#EB2222', paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5, borderRadius:6}}>No</div>;
            default:
                return <div style={{ color: "#045AFF" }}></div>;
        }
    };

    return getTag(props.iResult);
}