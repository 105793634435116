import {
  Button,
  Card,
  Modal,
  Steps,
  Tabs,
  message,
  Divider,
  Row,
  Col,
  Input,
  Select,
  Table,
  TableProps,
  Avatar,
  List,
  Upload,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { ChevronLeft, CornerUpRight } from "react-feather";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StatusTask from "../../components/MyTask/StatusTask";
import {
  ForwardBodyProps,
  SubmitBodyProps,
  TaskProps,
  initialForwardBody,
  initialSubmitBody,
} from "../../types/task.type";
import useFetchList from "../../hooks/useFetchList";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "@qlibs/react-components";
import {
  AlignLeftOutlined,
  CloudUploadOutlined,
  FileTextOutlined,
  LinkOutlined,
  SearchOutlined,
  InboxOutlined,
  DeleteOutlined,
  FileTwoTone,
  AppstoreTwoTone,
} from "@ant-design/icons";
import { useAuthUser } from "react-auth-kit";
import {
  MyContactProps,
  MyContactUserProps,
  MyRelayContactProps,
} from "../../types/myContact.type";
import { generateQueryString } from "../../helpers/generateQueryString";
import { ContactProps } from "../../types/contact.type";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import TextArea from "antd/es/input/TextArea";
import { UploadProps } from "antd/lib";
import { TicketProps } from "../../types/ticket.type";
import useDraft from "../../hooks/useDraft";
import { generateColor } from "../../helpers/constant";
import { el } from "date-fns/locale";
import { set } from "date-fns";
import { AttachmentSubmit } from "../../components/MyTask/AttachmentSubmit";
import { apiGetOneTask } from "../../api/task";
const { Dragger } = Upload;

const Submit = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const taskSelected = window.location.pathname.split("/").pop();
  const [showModal, setShowModal] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const {
    appendDraft,
    getDraft,
    removeDraft,
    updateDraft,
    attachTicket,
    setAttachTicket,
  } = useDraft();
  const [contacts, setContacts] = useState<ContactProps[]>();
  const [relay, setRelay] = useState<MyRelayContactProps[]>();
  const [forwardBody, setForwardBody] =
    useState<ForwardBodyProps>(initialForwardBody);
  const [submitBody, setSubmitBody] =
    useState<SubmitBodyProps>(initialSubmitBody);
  const [querySearch, setQuerySearch] = useState("");
  const [isSelected, setIsSelected] = useState<Boolean[]>([]);
  const [isListTrue, setIsListTrue] = useState(0);
  const [ticketSelected, setTicketSelected] = useState<TicketProps>();
  const [ticketIdAttach, setTicketIdAttach] = useState("");
  const [defaultNote, setDefaultNote] = useState("");
  const [draftFiles, setDraftFiles] = useState<any[]>([]);
  const [myTask, setMyTask] = useState<TaskProps>();
  const [ticketAttach, setTicketAttach] = useState<TicketProps[]>();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [previousFileList, setPreviousFileList] = React.useState<any[]>([]);
  const fileDraft = getDraft(taskSelected ? taskSelected : "")?.files;
  console.log("fileDraft", fileDraft);

  useEffect(() => {
    if (taskSelected) {
      const getOneTask = async () => {
        const oneTaskSelected = await apiGetOneTask(taskSelected);
        if (oneTaskSelected) {
          setMyTask(oneTaskSelected);
        }
      };
      getOneTask();
    }
  }, []);

  useEffect(() => {
    if (fileList.length > 0 && (submitBody.vNote !== "" || taskSelected)) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [taskSelected, fileList, previousFileList]);

  useEffect(() => {
    if (fileDraft) {
      setFileList(fileDraft);
    }
    if (myTask) {
      fetchContactList();
      fetchPreviousFileList();
    }
  }, [fileDraft, myTask]);

  const fetchPreviousFileList = async () => {
    try {
      const response = await httpRequest.get(
        `tasks/${myTask?.iTask}/submit/attachments`
      );
      console.log("responsepre", response.data.payload.attachment);
      if (response) {
        setPreviousFileList(response.data.payload.attachment.files);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAttachTicket = async () => {
    try {
      const findTicket = await httpRequest.get(
        "/tickets/custom/my-tickets" + `?ipTicket=${ticketIdAttach}`
      );
      console.log("findTicket", findTicket);
      if (findTicket.data.payload.results.length > 0) {
        message.success("Ticket found");
        console.log("findTicket", findTicket);
        setTicketSelected(findTicket?.data.payload.results[0]);
        const attachTicket = await httpRequest.post(
          `/tasks/${myTask?.iTask}/attach-ticket`,
          { iTicket: findTicket?.data.payload.results[0].iTicket }
        );
        if (attachTicket) {
          message.success("Ticket attached successfully");
        }
      } else {
        message.error("Ticket not found");
      }
    } catch (error) {
      console.log("error", error);

      message.error("Failed to attach ticket");
    }
  };

  const handleDeletePrevTicket = async (iTicket: number) => {
    try {
      const submitTicket = await httpRequest.delete<BaseResponseProps<any>>(
        `/tasks/${myTask?.iTask}/attach-ticket`,
        {
          data: {
            iTicket: iTicket,
          },
        }
      );
      if (submitTicket) {
        message.success("Ticket deleted successfully");
        setTicketAttach(
          ticketAttach?.filter((item) => item.iTicket !== iTicket)
        );
        window.location.reload();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchContactList = async () => {
    try {
      const response = await httpRequest.get("/my-contacts" + querySearch);
      if (response) {
        setContacts(response.data.payload.users);
        setRelay(response.data.payload.relay);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelect = (index: number) => {
    setIsSelected(Array(contacts?.length).fill(false));
    let temp = Array(contacts?.length).fill(false);
    const selectedId = contacts?.[index].user[0].iUser;
    setForwardBody((prevForwardBody) => ({
      ...(prevForwardBody as ForwardBodyProps),
      forwardto: {
        ...prevForwardBody.forwardto,
        iUserTarget: selectedId!,
      },
    }));
    temp[index] = true;
    setIsListTrue(index);
    setIsSelected(temp);
  };
  const handleNotes = (e: string) => {
    setSubmitBody((prevSubmitBody) => ({
      ...(prevSubmitBody as SubmitBodyProps),
      vNote: e,
    }));
    console.log("notes updated", forwardBody);
  };

  const handleModal = () => {
    setShowModal(true);
  };

  const handleDeletePreviousFile = async (item: any) => {
    try {
      const deleteFile = await httpRequest.delete(
        `/tasks/${myTask?.iTask}/submit/attachments/${item.iAttachment}`
      );
      if (deleteFile) {
        message.success("File deleted successfully");
        fetchPreviousFileList();
      }
    } catch (error) {
      console.log("error", error);
      message.error("Failed to delete file");
    }
  };
  const handleSubmit = async () => {
    try {
      if (fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach((file: any) => {
          formData.append("files", file.originFileObj);
        });
        const uploadFile = await httpRequest.put(
          `/tasks/${myTask?.iTask}/submit/attachments`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (uploadFile) {
          message.success("File attached successfully");
        }
      }
      if (attachTicket?.length > 0) {
        attachTicket.forEach(async (item) => {
          const attachTicket = await httpRequest.post(
            `/tasks/${myTask?.iTask}/attach-ticket`,
            { iTicket: item.iTicket }
          );
          if (attachTicket) {
            message.success("Ticket attached successfully");
          }
        });
      }
      const submitTask = await httpRequest.post<SubmitBodyProps>(
        `/tasks/${myTask?.iTask}/review`,
        {
          ...submitBody,
          dCreatedLocal: new Date(),
          iReviewTask: 69,
          dResolve: new Date(),
        }
      );
      if (submitTask) {
        message.success("Task submitted successfully");
        navigate("/my-task");
      }
    } catch (error) {
      console.log("error", error);
      message.error("Failed to submit task");
    }
  };
  useEffect(() => {
    console.log("its changed!");
  }, [submitBody, fileList, ticketAttach]);

  const handleDelete = (index: number) => {
    const updatedAttachTicket = [...attachTicket];
    updatedAttachTicket.splice(index, 1); // Remove the item at the specified index
    setAttachTicket(updatedAttachTicket);
  };

  const handleDeleteFileList = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const props: UploadProps = {
    multiple: true,
    onChange(info) {
      const { file } = info;
      if (file.size && file.size > 2000000) {
        message.config({ maxCount: 1 });
        message.error("File size must be less than 10MB");
      } else {
        // Check if the file is already in the fileList
        const isFileInList = fileList.some((item) => item.uid === file.uid);
        if (!isFileInList) {
          // Add the file to the fileList only if it's not already present
          setFileList([...fileList, file]);
        }
        console.log("info.fileList", fileList);
      }
    },
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          justifyContent: "space-between",
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ChevronLeft onClick={() => navigate(`/my-task/${myTask?.ipTask}`)} />
          <Title>Submit Task</Title>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            type={submitReady ? "primary" : "default"}
            disabled={!submitReady}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
          <Button
            style={{ backgroundColor: "green", color: "white" }}
            onClick={() => {
              if (myTask?.iTask) {
                appendDraft(
                  myTask?.ipTask,
                  {
                    ...submitBody,
                  },
                  fileList
                );
              }
              navigate(-1);
            }}
          >
            Save As Draft
          </Button>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        <Card style={{ marginBottom: 5 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Text>Status:</Text>
            <StatusTask iStatus={myTask?.iResult} />
          </div>
          <HeaderTitle>{myTask?.vName}</HeaderTitle>
          <Text>{myTask?.vDescription}</Text>
        </Card>
        <div>
          <Card style={{ marginBottom: 5 }}>
            <Text>
              <FileTextOutlined style={{ color: "gray" }} /> Note{" "}
              <span style={{ color: "red" }}>*</span>
            </Text>
            <TextArea
              rows={4}
              style={{ marginTop: 10 }}
              defaultValue={
                taskSelected ? getDraft(taskSelected)?.draft.vNote : ""
              }
              onChange={(e) => {
                handleNotes(e.target.value);
              }}
              placeholder="Enter your note here..."
            />
          </Card>

          <Card>
            <Text>
              <LinkOutlined style={{ color: "gray" }} /> Attachments
            </Text>
            <Row style={{ marginTop: 10, marginBottom: 10 }}>
              {ticketAttach &&
                ticketAttach?.map((item, index) => {
                  return (
                    <>
                      <Row
                        key={index}
                        style={{
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%", // Ensure each row takes up full width
                        }}
                      >
                        <Text style={{ color: "grey" }}>
                          <AppstoreTwoTone twoToneColor={"#9747FF"} />{" "}
                          {item.ipTicket} ||{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {item.vName}
                          </span>
                        </Text>
                        <Button
                          onClick={() => handleDeletePrevTicket(item.iTicket)}
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Row>
                    </>
                  );
                })}
              {previousFileList.length > 0 ? (
                <>
                  {previousFileList.map((item, index) => {
                    return (
                      <Row
                        key={index}
                        style={{
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%", // Ensure each row takes up full width
                        }}
                      >
                        <FileTwoTone />
                        <Text style={{ color: "grey" }}>
                          {item.vRawData.fileLinkCache?.split("/").pop() ||
                            item.ipAttachment +
                              "." +
                              item.vRawData.fileLinkCache
                                .split(".")
                                .pop()
                                ?.toLocaleLowerCase()}
                        </Text>
                        <Button onClick={() => handleDeletePreviousFile(item)}>
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
              {/* {
                draftFiles.length > 0 ? (
                <>
                {
                    draftFiles.map((item, index) => {
                      return (
                        <Row
                        key={index}
                        style={{
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%", // Ensure each row takes up full width
                        }}
                      >
                        <FileTwoTone/>
                        <Text style={{ color: "grey" }}>{
                          item.vRawData.fileLinkCache?.split("/").pop() ||
                          item.ipAttachment +
                            "." +
                            item.vRawData.fileLinkCache
                              .split(".")
                              .pop()
                              ?.toLocaleLowerCase()
                        }</Text>
                        <Button onClick={() => handleDeletePreviousFile(item)}>
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Row>
                      )
                    })
                  }
                </>):(<></>)
              } */}
              {/* {
                fileDraft && fileDraft.length > 0 ? (
                  <>
                  {
                    fileDraft.map((item, index) => {
                      return (
                        <Row
                        key={index}
                        style={{
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%", // Ensure each row takes up full width
                        }}
                      >
                        <FileTwoTone/>
                        <Text style={{ color: "grey" }}>{
                          item.name
                        }</Text>
                        <Button onClick={() => handleDeletePreviousFile(item)}>
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Row>
                      )
                    })
                  }
                  </>
                ):(<></>)
              } */}
            </Row>
            <Row style={{ marginTop: 10, marginBottom: 10 }}>
              {fileList.length > 0 ? (
                <>
                  {fileList.map((item, index) => {
                    return (
                      <Row
                        key={index}
                        style={{
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%", // Ensure each row takes up full width
                        }}
                      >
                        <Text style={{ color: "grey" }}>
                          <FileTwoTone /> {item.name}
                        </Text>
                        <Button onClick={() => handleDeleteFileList(index)}>
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
              {attachTicket.length > 0 ? (
                <>
                  {attachTicket.map((item, index) => {
                    return (
                      <Row
                        key={index}
                        style={{
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%", // Ensure each row takes up full width
                        }}
                      >
                        <Text style={{ color: "grey" }}>
                          <AppstoreTwoTone twoToneColor={"#9747FF"} />{" "}
                          {item.ipTicket} ||{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {item.vName}
                          </span>
                        </Text>
                        <Button onClick={() => handleDelete(index)}>
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <></>
              )}

              {/* {
          ticketSelected ? 
          <Text style={{ color: "gray"}}>{ticketSelected.vName} | {ti}</Text> : ''
        } */}
            </Row>
            <Row
              style={{
                marginTop: 10,
                marginBottom: 10,
                width: "50%",
                display: "flex",
                flexDirection: "row",
                gap: 4,
              }}
            >
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/my-task/attach-ticket/${myTask?.ipTask}`)
                }
              >
                <AlignLeftOutlined /> Attach Ticket ID
              </Button>
              <Upload {...props} showUploadList={false}>
                <Button type="primary">
                  <CloudUploadOutlined /> Attach File
                </Button>
              </Upload>
            </Row>
            <Dragger
              style={{ height: "50%" }}
              {...props}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
            <Divider />
          </Card>
        </div>
      </div>
      <Modal
        open={showModal}
        title="Attach Ticket ID"
        okText="Attach Ticket"
        onCancel={() => setShowModal(false)}
        onOk={() => {
          handleAttachTicket();
          setShowModal(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Input
              placeholder="Paste Ticket ID Here"
              onChange={(e) => {
                setTicketIdAttach(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Submit;

const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const CustomListItem = styled(List.Item)`
  &:hover {
    background-color: #f0f0f0;
  }
`;
