import { Avatar, Button, Divider, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  apiAddContact,
  apiGetMyContact,
  apiSearchMyContact,
} from "../../api/contact";
import SearchEmptyContact from "../../components/Contact/SearchEmptyContact";
import {
  censoredPhone,
  generateColor,
  getInitialName,
} from "../../helpers/constant";
import { Briefcase, Mail, Phone, User } from "react-feather";
import { ContactProps } from "../../types/contact.type";
import OrganizationContact from "../../components/Contact/OrganizationContact";
import useExecutionTime from "../../hooks/useExecutionTime";
import Search from "antd/es/input/Search";

const SearchContact = () => {
  const [search, setSearch] = useState("");
  const [searchTmp, setSearchTmp] = useState("");
  const { stopTimer, startTimer, updateExecutionLabel } = useExecutionTime();
  const [showModal, setShowModal] = useState(false);
  const [iUser, setIUser] = useState<number>();
  const [name, setName] = useState("Not Set");
  const [organization, setOrganization] = useState("Personal");
  const [email, setEmail] = useState("Not Set");
  const [jobTitle, setJobTitle] = useState("Not Set");
  const [phoneNumber, setPhoneNumber] = useState("Not Set");
  const [userContact, setUserContact] = useState<ContactProps[]>();
  const [data, setData] = useState<any[]>();
  const fetchData = async () => {
    const res = await apiSearchMyContact(search);
    if (res) {
      setData(res.data.payload.user);
    }
  };

  const fetchContact = async () => {
    const res = await apiGetMyContact();
    if (res) {
      setUserContact(res.data.payload.users);
    }
  };
  useEffect(() => {
    fetchContact();
  }, []);

  const handleAddContact = async () => {
    stopTimer();
    startTimer("add contact");
    await apiAddContact({ iUser: iUser });
    fetchContact();
  };

  const isMyContact = () => {
    return userContact?.some((item) => item.iUserContact === iUser);
  };

  useEffect(() => {
    fetchContact();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search?.length > 2 || !search?.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <div style={{ margin: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          marginBottom: 20,
        }}
      >
        <Input
          onChange={(e) => setSearchTmp(e.target.value)}
          onPressEnter={(e) => setSearch(e.currentTarget.value)}
          allowClear
          placeholder="Search"
          style={{ padding: 10 }}
        />
        <Button
          type="primary"
          onClick={() => setSearch(searchTmp)}
          style={{ padding: 20, display: "flex", alignItems: "center" }}
        >
          Search
        </Button>
      </div>
      {search?.length > 2 ? (
        <div style={{ backgroundColor: "white", padding: 20 }}>
          {data?.map((item) => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setName(item.vFullName || "Not Set");
                setEmail(item.vEmail || "Not Set");
                setIUser(item.iUser || "Not Set");
                setOrganization(item.organization?.vName || "Personal");
                setPhoneNumber(
                  item.vMobile ? censoredPhone(item.vMobile) : "Not Set"
                );
                setShowModal(true);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Avatar
                    shape="square"
                    size={50}
                    style={{ backgroundColor: generateColor(item.vFullName) }}
                  >
                    {getInitialName(item.vFullName)}
                  </Avatar>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextBold>{item.vFullName}</TextBold>
                    {/* <OrganizationContact iUser={item.iUser} /> */}
                  </div>
                </div>
                {item?.contact ? (
                  <Status backgroundColor="#D2F8C8" color="#238D0B">
                    Added
                  </Status>
                ) : (
                  <Status backgroundColor="#E6E9EF" color="grey">
                    Not Added
                  </Status>
                )}
              </div>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <SearchEmptyContact />
      )}
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <SubTextBold>Detail Contact</SubTextBold>
        <Divider style={{ marginTop: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Avatar
            shape="square"
            size={50}
            style={{ backgroundColor: generateColor(name) }}
          >
            {getInitialName(name)}
          </Avatar>
          <TextBolder>{name}</TextBolder>
        </div>
        <Divider />
        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Organization</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Briefcase style={{ opacity: 0.5 }} />
            </div>
            <Text>{organization}</Text>
          </div>
        </div>
        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Email Address</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Mail style={{ opacity: 0.5 }} />
            </div>
            <Text>{email}</Text>
          </div>
        </div>

        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Job Title</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <User style={{ opacity: 0.5 }} />
            </div>
            <Text>{jobTitle}</Text>
          </div>
        </div>

        <div style={{ marginBlock: 20 }}>
          <SubTextBold>Phone Number</SubTextBold>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Phone style={{ opacity: 0.5 }} />
            </div>
            <Text>{phoneNumber}</Text>
          </div>
        </div>
        <Divider />

        {!isMyContact() && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button onClick={handleAddContact} type="primary">
              Add Contact
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SearchContact;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const TextBold = styled.div`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const SubTextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const TextBolder = styled.div`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Status = styled.div<{ backgroundColor?: string }>`
  font-size: ${({ theme }) => theme.fontSize.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  width: 150px;
  text-align: center;
  border-radius: 8px;
  padding: 10px 10px;
  display: inline-block;
`;
