import { format } from "date-fns";

// export const PAGE_SIZE_OPTIONS = ['25', '50', '100'];
// export const PAGE_SIZE_OPTIONS = ['3', '5', '10'];
export const PAGE_SIZE_OPTIONS = ["10", "20", "50"];

const date = new Date();

export const now = format(new Date(), "MMMM dd, yyyy");
export const appVersion: string | undefined =
  process.env.REACT_APP_VERSION_NAME;
export const thisYear = date.getFullYear();

export const getInitialName = (name: string) => {
  const split: string[] = name.split(" ");

  let start = 0;
  let initial = [];

  if (split.length > 1) {
    while (start <= 1) {
      initial.push(split[start].charAt(0));
      start++;
    }
  } else {
    initial.push(split[0].substr(0, 2));
  }

  return initial.join("").toUpperCase();
};

export const formatDate = (date: any) => {
  if (date) {
    const formated = format(new Date(date), "MMMM dd, yyyy");
    return formated;
  }
  return "";
};

export const formatTime = (time: any) => {
  if (time) {
    const formated = format(new Date(time), "HH:mm");
    return formated;
  }
  return "";
};

export const formatYearToTime = (date: any) => {
  if (date) {
    const formated = format(new Date(date), "dd MMM yyyy, HH:mm");
    return formated;
  }
  return "";
};

export const formatUrlParams = (value: string) => {
  const param = value.split(" ").join("-").toLowerCase();
  return param;
};

export const formatMonth = (date: any) => {
  if (date) {
    const formated = format(new Date(date), "MMM yyyy");
    return formated;
  }
  return "";
};

export const censoredPhone = (phone: number | string): string => {
  const phoneNumber = String(phone);
  const countryCode = phoneNumber.slice(0, 2);
  const mainDigits = phoneNumber.slice(2, -3);
  const lastDigits = phoneNumber.slice(-3);
  const censoredDigits = mainDigits.replace(/\d/g, "*");

  return `${countryCode}${censoredDigits}${lastDigits}`;
};

export const generateColor = (initial: string) => {
  const colors = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#95a5a6",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#bdc3c7",
    "#7f8c8d",
  ];
  const charCode = initial.charCodeAt(0);
  const colorIndex = charCode % colors.length;
  return colors[colorIndex];
};
