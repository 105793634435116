import React, { useEffect } from "react";
import { Button, Col, Empty, Row, Select, Typography } from "antd";
import MyTicketCard from "../../components/MyTicket/MyTicketCard";
import { LoadingOutlined } from "@ant-design/icons";
import { httpRequest } from "../../helpers/api";
import useFetchList from "../../hooks/useFetchList";
import { TicketProps } from "../../types/ticket.type";
import { useLocation, useNavigate } from "react-router-dom";
import { isDoneStatus } from "../../helpers/isDoneStatus";
import HeaderTitleWithBadge from "../../components/MyTask/HeaderTitleWithBadge";
import { OrganizationUserProps } from "../../types/organizationUser.type";
import { useAuthUser } from "react-auth-kit";
import { TaskProps } from "../../types/task.type";
import useExecutionTime from "../../hooks/useExecutionTime";
import InfiniteScroll from "react-infinite-scroll-component";
import Search from "antd/es/input/Search";

const { Text } = Typography;
const TaskStatus = [
  "All",
  "Open",
  "Need Approval Complete",
  "Closed Open",
  "Closed",
];

const MyTicket = () => {
  const auth = useAuthUser();
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const { hash, pathname, search } = location;
  const navigate = useNavigate();
  const { stopTimer } = useExecutionTime();
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [onSearch, setOnSearch] = React.useState<boolean>(false);
  const [maxPage, setMaxPage] = React.useState<number>(0);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const org = urlParams.get("organization");
  const [iOrganization, setIOrganization] = React.useState<number | null>(
    org === "0" ? 0 : org ? parseInt(org) : null
  );
  const [cardAmmount, setCardAmmount] = React.useState([0, 0, 0, 0]);
  const [countStatus, setCountStatus] = React.useState<
    [
      {
        iStatus: number;
        count: string;
      }
    ]
  >([
    {
      iStatus: 0,
      count: "0",
    },
  ]);
  const [statusFilter, setStatusFilter] = React.useState<number | null>(null);
  const [statusQuery, setStatusQuery] = React.useState<number | null>(null);
  const [filteredPagination, setFilteredPagination] =
    React.useState<number>(18);
  const [totalData, setTotalData] = React.useState<number>(0);
  const [once, setOnce] = React.useState<boolean>(true);

  const [indexedData, setIndexedData] = React.useState<{
    open: TicketProps[];
    needApprovalForComplete: TicketProps[];
    openClosed: TicketProps[];
    closed: TicketProps[];
  }>({
    open: [],
    needApprovalForComplete: [],
    openClosed: [],
    closed: [],
  });
  const [ticketFiltered, setTicketFiltered] = React.useState<TicketProps[]>([]);

  const [filteredData, setFilteredData] = React.useState<{
    open: TicketProps[];
    needApprovalForComplete: TicketProps[];
    openClosed: TicketProps[];
    closed: TicketProps[];
  }>({
    open: [],
    needApprovalForComplete: [],
    openClosed: [],
    closed: [],
  });
  const [searchBar, setSearchBar] = React.useState<string>("");
  const [displayData, setDisplayData] = React.useState<TicketProps[]>([]);
  const [initialData, setInitialData] = React.useState<TicketProps[]>([]);
  const fetchSearched = async () => {
    try {
      const res = await httpRequest.get(`/tickets/custom/optimize/my-tickets`, {
        params: {
          iOrganization: iOrganization,
          search: searchQuery,
          // limit: (iOrganization!==0 || iOrganization !== null)?9:0,
          // offset: (iOrganization!==0 || iOrganization !== null)?filteredPagination:0,
        },
      });
      if (res) {
        if (res.data.payload.results.length === 0) {
          setIsEmpty(true);
        }
        setTicketFiltered(res.data.payload.results);
        setDisplayData(res.data.payload.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { data: userOrganizations, fetchList: fetchOrganization } =
    useFetchList<OrganizationUserProps>({
      endpoint: "organization-users",
      pageData: "my-organization-users",
      iUser: auth()?.iUser,
      initialQuery: {
        iUser: auth()?.iUser,
      },
    });

  const fetchFilteredTicket = async () => {
    const res = await httpRequest.get(
      `/tickets/custom/status/my-tickets?offset=${maxPage}&limit=${6}`
    );
    if (res) {
      setIndexedData(res.data.payload.results);
      setInitialData(
        displayData.concat(
          res.data.payload.results.open,
          res.data.payload.results.needApprovalForComplete,
          res.data.payload.results.openClosed,
          res.data.payload.results.closed
        )
      );
      setFilteredData({
        open: filteredData.open.concat(res.data.payload.results.open),
        needApprovalForComplete: filteredData.needApprovalForComplete.concat(
          res.data.payload.results.needApprovalForComplete
        ),
        openClosed: filteredData.openClosed.concat(
          res.data.payload.results.openClosed
        ),
        closed: filteredData.closed.concat(res.data.payload.results.closed),
      });
    }
  };

  const fetchTicketCount = async () => {
    const resOpen = await httpRequest.get(
      "tickets/custom/optimize/my-tickets?limit=1"
    );
    if (resOpen) {
      setCountStatus(resOpen.data.payload.countStatus);
    }
  };

  useEffect(() => {
    if (displayData.length > 0 && once) {
      stopTimer();
      setOnce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData]);

  useEffect(() => {
    fetchFilteredTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPage]);

  const renderMore = () => {
    setMaxPage(maxPage + 6);
    setFilteredPagination(filteredPagination + 9);
  };

  useEffect(() => {
    if (!statusQuery) {
      checkParams();
    }
    fetchTicketCount();
    // eslint-disable-next-line
  }, [statusQuery, pathname, search, hash, location, iOrganization, totalData]);

  const checkParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    setStatusQuery(parseInt(status ? status : ""));
    if (status) {
      setStatusFilter(parseInt(status));
    }
  };

  function calculateCardStatus() {
    const maxStatus = Math.max(...countStatus.map((item) => item.iStatus), 0);
    const statusArray = Array(maxStatus).fill(0);

    countStatus.forEach((item) => {
      statusArray[item.iStatus - 1] = parseInt(item.count);
    });
    setTotalData(statusArray.reduce((a, b) => a + b, 0));
    setCardAmmount(statusArray);
  }

  useEffect(() => {
    if (statusQuery === null) {
      checkParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, countStatus]);

  useEffect(() => {
    fetchTicketCount();
    fetchFilteredTicket();
    fetchOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateCardStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countStatus]);

  useEffect(() => {
    if (!searchBar) {
      setOnSearch(false);
      setIsEmpty(false);
      setSearchQuery("");
    }
  }, [searchBar]);

  useEffect(() => {
    if (!iOrganization && onSearch) {
      fetchSearched();
      // fetchTicket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, iOrganization]);

  useEffect(() => {
    if (!onSearch) {
      setDisplayData(initialData);
    } else {
      setDisplayData(ticketFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusFilter,
    renderMore,
    indexedData,
    cardAmmount,
    initialData,
    searchQuery,
    ticketFiltered,
    iOrganization,
  ]);

  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        <Row
          style={{
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Col span={19}>
            <Row style={{ gap: 10 }}>
              <Col span={11}>
                <Text style={{ color: "gray" }}>Search</Text>
                <Search
                  style={{ marginTop: 10 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchBar(e.target.value);
                  }}
                  onSearch={(value) => {
                    setSearchQuery(value);
                    if (value) {
                      setOnSearch(true);
                    }
                  }}
                />
              </Col>
              <Col span={7}>
                <Text style={{ color: "gray" }}>Ticket Status</Text>
                <Select
                  style={{ marginTop: 10 }}
                  allowClear
                  maxTagTextLength={8}
                  placeholder="Status"
                  onChange={(val) => {
                    setStatusFilter(val);
                  }}
                >
                  {TaskStatus.map((status, index) => (
                    <Select.Option key={index} value={index}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={5}>
                <Text style={{ color: "gray" }}>Organization</Text>
                <Select
                  style={{ marginTop: 10 }}
                  defaultValue={iOrganization}
                  allowClear
                  placeholder="Status"
                  onChange={(e) => {
                    e === null ? setIOrganization(null) : setIOrganization(e);
                    if (e) {
                      setOnSearch(true);
                    } else {
                      setOnSearch(false);
                    }
                  }}
                >
                  <Select.Option value={null}>All</Select.Option>
                  <Select.Option value={0}>Personal</Select.Option>
                  {userOrganizations.map((item) => (
                    <Select.Option value={item.organization.iOrganization}>
                      {item.organization.vName}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <Button
              type="primary"
              style={{ marginTop: 31, width: "100%" }}
              onClick={() => navigate("/my-ticket/create")}
            >
              + Create Ticket
            </Button>
          </Col>
        </Row>
      </div>
      {!isEmpty ? (
        <InfiniteScroll
          dataLength={displayData.length}
          next={renderMore}
          hasMore={
            statusFilter === 4
              ? displayData.length < totalData && indexedData.closed.length > 0
              : displayData.length < totalData &&
                (indexedData.open.length > 0 ||
                  indexedData.needApprovalForComplete.length > 0 ||
                  indexedData.openClosed.length > 0)
          }
          height={800}
          loader={
            searchQuery ? (
              <div></div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                <LoadingOutlined style={{ fontSize: 24, color: "#1890ff" }} />
              </div>
            )
          }
          endMessage={<div></div>}
        >
          <div style={{ padding: 20 }}>
            {statusFilter === null ||
            statusFilter === 0 ||
            statusFilter === undefined ? (
              <Row style={{ gap: 20 }} wrap={false} className="ticketboard">
                {TaskStatus.slice(1, -1).map((status, index) => (
                  <Col span={7} key={index + 1}>
                    <HeaderTitleWithBadge
                      title={status}
                      badgeCount={cardAmmount[index]}
                    />
                    {/* Show MyTicketCard only if data.iStatus matches the current TaskStatus index */}
                    {displayData
                      .filter((ticket) => ticket.iStatus - 1 === index)
                      .map(
                        (data, dataIndex) =>
                          data.iStatus - 1 === index && (
                            <Col key={dataIndex}>
                              <MyTicketCard
                                iTicket={data.iTicket}
                                ipTicket={data.ipTicket}
                                title={data.vName}
                                description={data.vDescription}
                                inProgress={
                                  data.tasks
                                    ? data.tasks.filter(
                                        (task: TaskProps) =>
                                          task.iResult === 2 ||
                                          task.iResult === 7
                                      ).length
                                    : 0
                                }
                                done={
                                  data.tasks
                                    ? data.tasks.filter((task: TaskProps) =>
                                        isDoneStatus(task.iResult)
                                      ).length
                                    : 0
                                }
                                needApproval={
                                  data.tasks
                                    ? data.tasks.filter(
                                        (task: TaskProps) => task.iResult === 1
                                      ).length
                                    : 0
                                }
                                inReview={
                                  data.tasks
                                    ? data.tasks.filter(
                                        (task: TaskProps) =>
                                          task.iResult === 622 ||
                                          task.iResult === 621 ||
                                          task.iResult === 69
                                      ).length
                                    : 0
                                }
                                iStatus={data.iStatus}
                                iUserOwner={data.iUserOwner}
                              />
                            </Col>
                          )
                      )}
                  </Col>
                ))}
              </Row>
            ) : (
              <Row style={{ gap: 20 }}>
                <Col span={24}>
                  <HeaderTitleWithBadge
                    title={TaskStatus[statusFilter]}
                    badgeCount={cardAmmount[statusFilter - 1]}
                  />
                  <Row gutter={20}>
                    {/* Show MyTicketCard only if data.iStatus matches the current TaskStatus index */}
                    {displayData
                      .filter((dataItem) => dataItem.iStatus === statusFilter)
                      .map((dataItem, dataIndex) => (
                        <Col span={8} key={dataIndex}>
                          <MyTicketCard
                            iTicket={dataItem.iTicket}
                            ipTicket={dataItem.ipTicket}
                            title={dataItem.vName}
                            description={dataItem.vDescription}
                            inProgress={
                              dataItem.tasks
                                ? dataItem.tasks.filter(
                                    (task: TaskProps) =>
                                      task.iResult === 2 || task.iResult === 7
                                  ).length
                                : 0
                            }
                            done={
                              dataItem.tasks
                                ? dataItem.tasks.filter((task: TaskProps) =>
                                    isDoneStatus(task.iResult)
                                  ).length
                                : 0
                            }
                            needApproval={
                              dataItem.tasks
                                ? dataItem.tasks.filter(
                                    (task: TaskProps) => task.iResult === 1
                                  ).length
                                : 0
                            }
                            inReview={
                              dataItem.tasks
                                ? dataItem.tasks.filter(
                                    (task: TaskProps) =>
                                      task.iResult === 622 ||
                                      task.iResult === 621 ||
                                      task.iResult === 69
                                  ).length
                                : 0
                            }
                            iStatus={dataItem.iStatus}
                            iUserOwner={dataItem.iUserOwner}
                          />
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </InfiniteScroll>
      ) : (
        <>
          <Empty></Empty>
        </>
      )}
    </React.Fragment>
  );
};
export default MyTicket;
