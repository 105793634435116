import { message } from "antd";
import React from "react";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { Timer, Time, TimerOptions } from 'timer-node';

const initialState: {
    executionTime: number,
    executionLabel: string,
    isOn: boolean,
    startAt: string,
}={
    executionTime: 0,
    executionLabel: "",
    isOn: false,
    startAt: "",
};

const { useGlobalState } = createGlobalState(initialState);

export default function useExecutionTime() {

    const timer = new Timer();
    const [executionTime, setExecutionTime] = useGlobalState("executionTime");
    const [executionLabel, setExecutionLabel] = useGlobalState("executionLabel");
    const [startAt, setStartAt] = useGlobalState("startAt");
    const [isOn, setIsOn] = useGlobalState("isOn");

    const startTimer = (label: string) => {
        const now = new Date().toISOString();
        setStartAt(now);
        updateExecutionLabel(label);
    }

    const stopTimer = () => {
        const endAt = new Date();
        const startedAt = new Date(startAt);
        const msDiff = endAt.getTime() - startedAt.getTime();
        updateExecutionTime(msDiff);
        timer.stop();
    }

    const updateExecutionTime = (executionTime: number) => {
        setIsOn(true);
        setExecutionTime(executionTime);
    }
    const updateExecutionLabel = (executionLabel: string) => {
        setIsOn(true);
        setExecutionLabel(executionLabel);
    }
    useEffect(() => {}, [executionTime, executionLabel]);
    return { executionTime, updateExecutionLabel, executionLabel, updateExecutionTime, isOn, startTimer, stopTimer};
}